import { determineUserType } from '../utils/helperFunctions'
import getUserInfo from './getUserInfo'

// Structuring the export / references like this
// allow us to unit test this file effectively
// since these functions rely on each other.
const loginLogic = {
    postAuthDataFetch,
    fetchUserData,
    setUserValues
}

export default loginLogic

// When using these wrap in a try/catch
// and you're good to go
async function postAuthDataFetch(dispatch, email) {
    const userData = await loginLogic.fetchUserData(email)
    return loginLogic.setUserValues(userData, dispatch)
}

async function fetchUserData(email) {
    const result = await getUserInfo(email)
    if (!result || result?.error) throw result.error
    return result
}

function setUserValues(userData, dispatch) {
    const userType = determineUserType(userData)

    dispatch({ type: 'SET_USERTYPE', value: userType })
    dispatch({ type: 'SET_USER_ORG', value: userData })
    dispatch({ type: 'SET_ISAUTH', value: true })
    dispatch({ type: 'SET_LOADING', value: false })
}
