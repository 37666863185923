import React from 'react'
import { Button } from 'react-bootstrap'
import styled from 'styled-components'
import {
    buttonBackgroundColor,
    buttonBackgroundHover,
    dangerStatusColor,
    dangerButtonHover,
    buttonBackgroundDisabled
} from '../../../constants'

const PrimaryButton = ({
    testId,
    disabled,
    text,
    width,
    danger,
    margin,
    children,
    ...rest
}) => {
    return (
        <MainContainer
            data-testid={testId || 'primaryButton'}
            disabled={disabled}
            width={width}
            danger={danger.toString()}
            margin={margin}
            {...rest}
        >
            {children || text}
        </MainContainer>
    )
}

PrimaryButton.defaultProps = {
    disabled: false,
    text: 'Button Text',
    danger: false
}

export default PrimaryButton

const MainContainer = styled(Button)`
    margin: ${({ margin }) => (margin ? margin : '10px')};
    padding: 12px 24px;
    width: ${({ width }) => (width ? width : '50%')};
    border: none;
    border-radius: 5px;
    background-color: ${({ danger }) =>
        danger === 'true' ? dangerStatusColor : buttonBackgroundColor};
    color: white;
    font-weight: 500;
    font-size: 16px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    border-color: ${({ danger }) =>
        danger === 'true' ? dangerStatusColor : buttonBackgroundColor};

    :hover,
    :focus,
    :active {
        background-color: ${({ danger }) =>
            danger === 'true'
                ? dangerButtonHover
                : buttonBackgroundHover} !important;
        border-color: ${({ danger }) =>
            danger === 'true'
                ? dangerButtonHover
                : buttonBackgroundHover} !important;
        box-shadow: none !important;
    }
    :disabled {
        background-color: ${({ danger }) =>
            danger === 'true'
                ? dangerStatusColor
                : buttonBackgroundDisabled} !important;
        border-color: ${({ danger }) =>
            danger === 'true'
                ? dangerStatusColor
                : buttonBackgroundDisabled} !important;
        box-shadow: none !important;
        opacity: 50%;
    }
    @media (min-width: 900px) {
        font-size: 14px;
    }
`
