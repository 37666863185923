import axios from 'axios'
import { ALERT_API_URL, ENV } from '../../constants'

export const defaults = {
    Accept: 'application/vnd.adyptation+json;version=1.0',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Env: ENV
}

export const getDefaultHeaders = userEmail => {
    const token = localStorage.getItem('token')
    const requester = {
        Requester: userEmail
    }
    let headers = defaults
    if (userEmail) {
        headers = {
            ...headers,
            ...requester,
            Token: token
        }
    }

    return headers
}

// keyName is normally the name of the API call (must be a string)
export const axiosCall = (
    body,
    options,
    keyName,
    whitelist_string = 'adsfbasdgiu'
) => {
    let error = ''
    return axios
        .post(ALERT_API_URL, body, options)
        .then(result => {
            // Throw the error if there is one
            if (result?.data?.errors?.length > 0) {
                if (result.data.errors[0].message?.includes(whitelist_string))
                    return

                error = result.data.errors[0].message
                throw error
            }

            // If keyName is defined and there's a key with that name on
            //  result.data.data, return its value
            if (keyName)
                for (const [key, value] of Object.entries(result.data.data)) {
                    if (key === keyName) return value
                }

            // If the user reaches this point, there's an issue with the code
            //  (the keyName is incorrect). Have the user contact tech support.
            throw 'An error has occurred. Please contact tech support.'
        })
        .catch(err => {
            if (
                err === error ||
                err === 'An error has occurred. Please contact tech support.'
            )
                throw new Error(err.replace(/Error: /, ''))
            throw new Error('An error has occurred. Please try again.')
        })
}
