import SearchMedsList from './searchMedsList'
import CreateMed from './createMed'
import DeleteMed from './deleteMed'
import UpdateMed from './updateMed'

const Medications = {
    searchMedsList: SearchMedsList,
    createMed: CreateMed,
    deleteMed: DeleteMed,
    updateMed: UpdateMed
}

export default Medications
