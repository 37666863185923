import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const createToken = values => {
    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email
    const newValues = Object.entries(values)
        .map(val => {
            if (val[0] === 'expiresIn' || val[0] === 'expiresAt')
                return `${val[0]}: ${val[1]}`
            return `${val[0]}: "${val[1]}"`
        })
        .join(', ')
    const body = {
        query: print(gql`
        mutation {
            createOAuthToken(${newValues}) {
                id
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'createOAuthToken')
}

export default createToken
