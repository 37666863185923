import { SYSTEM_EMAIL } from '../../constants'
import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const deleteInvitation = value => {
    const body = {
        query: print(gql`
            mutation {
                deleteInvitation(id: "${value}")
            }
        `)
    }
    const options = {
        headers: getDefaultHeaders(SYSTEM_EMAIL)
    }

    return axiosCall(body, options, 'deleteInvitation')
}

export default deleteInvitation
