import React, { useState } from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import {
    ModalCard,
    ModalTitle,
    StyledCol,
    StyledCloseAndDelete,
    FlexRowE
} from '../../utils/styledComponents'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { toast } from 'react-toastify'
import AlertApi from '../../apiCalls'
import { useAppContext } from '../../context/AppContext'
import Loading from '../../components/Loading'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '../../components/atoms/buttons/PrimaryButton'

const GoalsPrompt = ({ close }) => {
    const { state, dispatch } = useAppContext()
    const { user } = state.auth
    const [pending, setPending] = useState(false)
    const history = useHistory()

    const updateUser = addGoals => {
        setPending(true)
        const values = {
            id: user.id,
            currentStatus: 'active'
        }
        AlertApi.updateUser(values, user.role, user.email)
            .then(_ => {
                setPending(false)
                dispatch({ type: 'UPDATE_USER', value: values })
                if (addGoals) {
                    if (!isValidPhoneNumber(user?.phone))
                        history.push('/goals/add_phone_number')
                    else history.push('/goals/create')
                } else close()
            })
            .catch(error => {
                toast.error(error.message)
                setPending(false)
            })
    }

    return (
        <>
            <ModalCard>
                <FlexRowE>
                    <StyledCloseAndDelete
                        icon={faTimesCircle}
                        onClick={() => updateUser(false)}
                        data-testid="no"
                    />
                </FlexRowE>
                <ModalTitle data-testid="title">Set Goals</ModalTitle>
                <StyledCol
                    data-testid="description"
                    style={{ padding: '10px' }}
                >
                    <span style={{ fontWeight: 'bold' }}>
                        Would you like to set goals for your Alerts account?
                    </span>
                    <br />
                    (You can add, remove, or edit goals at any time in the Goals
                    section of your profile.)
                </StyledCol>
                <PrimaryButton
                    data-testid="yes"
                    style={{ marginTop: '10px' }}
                    onClick={() => updateUser(true)}
                >
                    Yes
                </PrimaryButton>
            </ModalCard>
            {pending && <Loading displayText="Saving response..." />}
        </>
    )
}

export default GoalsPrompt
