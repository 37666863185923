import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const deleteAuth0User = (value, deleteAccount) => {
    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email
    const body = {
        query: print(gql`
        mutation {
            deleteAuth0User(id: "${value}", provider: ${deleteAccount || false})
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'deleteAuth0User')
}

export default deleteAuth0User
