import React, { useEffect, useState, lazy } from 'react'
import { useAppContext } from '../../context/AppContext'
import {
    Switch,
    Route,
    useLocation,
    useHistory,
    Redirect
} from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { SYSTEM_EMAIL } from '../../constants'
import loginLogic from '../../logic/loginLogic'
import AlertApi from '../../apiCalls'
import { FFGate } from '@adyptation/feature-flags'
const jwt = require('jsonwebtoken')

// Essential routes loaded at startup
import AuthRoute from './AuthRoute'
import DashboardContainer from '../../containers/Dashboard/DashboardContainer'
import Login from '../../containers/Login/Login'

// Load these components only when needed
const AddOrEditMedication = lazy(() =>
    import('../../containers/Patient/Medications/AddOrEditMedication')
)
const AddPhoneNumber = lazy(() =>
    import('../../containers/Patient/Goals/AddPhoneNumber')
)
const AddUserToStaffContainer = lazy(() =>
    import('../../containers/AddUserToStaff/AddUserToStaffContainer')
)
const BasicRegistrationInfo = lazy(() =>
    import('../../containers/Registration/BasicRegistrationInfo')
)
const ConfirmDataPage = lazy(() =>
    import('../../containers/Confirmation/ConfirmData')
)
const CreateAccount = lazy(() =>
    import('../../containers/Registration/CreateAccount')
)
const CreateGoalPage = lazy(() =>
    import('../../containers/Patient/Goals/CreateGoalPage')
)
const DeleteGoalPage = lazy(() =>
    import('../../containers/Patient/Goals/DeleteGoalPage')
)
const DeletePatientAccountContainer = lazy(() =>
    import(
        '../../containers/DeletePatientAccount/DeletePatientAccountContainer'
    )
)
const DeleteMedication = lazy(() =>
    import('../../containers/Patient/Medications/DeleteMedication')
)
const EditGoalPage = lazy(() =>
    import('../../containers/Patient/Goals/EditGoalPage')
)
const EditProfile = lazy(() =>
    import('../../containers/ProfileInfo/EditProfile')
)
const FitbitOauthReturn = lazy(() =>
    import('../../containers/Oauth/FitbitOauthReturn')
)
const InvitationsCodeForm = lazy(() =>
    import('../../containers/Invitations/InvitationsCodeForm')
)
const InvitationsContainer = lazy(() =>
    import('../../containers/Invitations/InvitationsContainer')
)
const Loading = lazy(() => import('../Loading'))
const MainDevicePage = lazy(() =>
    import('../../containers/Sensors/MainDevicePage')
)
const OuraOauthReturn = lazy(() =>
    import('../../containers/Oauth/OuraOauthReturn')
)
const PatientsContainer = lazy(() =>
    import('../../containers/Patients/PatientsContainer')
)
const PatientProfileInfo = lazy(() =>
    import('../../containers/Patient/PatientProfileInfo')
)
const PrivacyPolicy = lazy(() => import('../Agreements/PrivacyPolicy'))
const Profile = lazy(() => import('../../containers/Profile/Profile'))
const ProviderProfileInfo = lazy(() =>
    import('../../containers/ProfileInfo/ProviderProfileInfo')
)
const SensorsContainer = lazy(() =>
    import('../../containers/Sensors/SensorsContainer')
)
const Settings = lazy(() => import('../../containers/Settings/Settings'))
const SoftwareTestingAgreement = lazy(() =>
    import('../Agreements/SoftwareTestingAgreement')
)
const SoftwareTestingSchedule = lazy(() =>
    import('../Agreements/SoftwareTestingSchedule')
)
const StaffContainer = lazy(() =>
    import('../../containers/Staff/StaffContainer')
)
const SuperUserAudit = lazy(() =>
    import('../../containers/SuperUser/SuperUserAudit')
)
const SuperUserContainer = lazy(() =>
    import('../../containers/SuperUser/SuperUserContainer')
)
const SuperUserImpersonation = lazy(() =>
    import('../../containers/SuperUser/SuperUserImpersonation')
)
const SuperUserRoute = lazy(() => import('./SuperUserRoute'))
const TermsAndConditions = lazy(() =>
    import('../../containers/Registration/TermsAndConditions')
)
const TermsOfService = lazy(() => import('../Agreements/TermsOfService'))
const UpdateCodeForm = lazy(() =>
    import('../../containers/Invitations/UpdateCodeForm')
)

const Routes = () => {
    const location = useLocation()
    const history = useHistory()
    const { state, dispatch } = useAppContext()
    const [loading, setLoading] = useState(false)
    const { isLoading, user, isAuthenticated } = useAuth0()

    useEffect(async () => {
        const salt = await AlertApi.getSetting('JWT_SALT')
        dispatch({ type: 'SET_SALT', value: salt.value })
    }, [])

    useEffect(() => {
        if (isAuthenticated && user?.email) {
            setLoading(true)
            AlertApi.getUser('email', user.email, SYSTEM_EMAIL)
                .then(result => {
                    if (result) {
                        loginLogic.postAuthDataFetch(dispatch, user.email)
                        setLoading(false)
                    } else {
                        // Create temporary jwt with internalsystem email until user
                        // is created
                        const token = jwt.sign(
                            { email: SYSTEM_EMAIL },
                            state.salt
                        )
                        localStorage.setItem('token', token)
                        dispatch({ type: 'SET_TOKEN', value: token })

                        AlertApi.getInvitation('email', user.email)
                            .then(inviteResult => {
                                if (inviteResult) {
                                    const {
                                        first,
                                        last,
                                        email,
                                        roleType,
                                        organizationId
                                    } = inviteResult
                                    const newUser = {
                                        first,
                                        last,
                                        email,
                                        organizationId
                                    }
                                    AlertApi.createUser(
                                        newUser,
                                        roleType,
                                        SYSTEM_EMAIL
                                    )
                                        .then(createdUser => {
                                            if (roleType.includes('provider'))
                                                AlertApi.getOrg(
                                                    'id',
                                                    organizationId,
                                                    createdUser.email
                                                )
                                                    .then(organization => {
                                                        organization.invitations =
                                                            organization
                                                                .invitations
                                                                ?.length > 0
                                                                ? organization.invitations.filter(
                                                                      invite =>
                                                                          invite.email !==
                                                                          createdUser.email
                                                                  )
                                                                : organization.invitations ||
                                                                  []
                                                        dispatch({
                                                            type: 'SET_USER_ORG',
                                                            value: {
                                                                organization
                                                            }
                                                        })
                                                    })
                                                    .catch(err => {
                                                        console.warn({ err })
                                                    })
                                            if (createdUser.id) {
                                                AlertApi.deleteInvitation(
                                                    inviteResult.id
                                                )
                                                dispatch({
                                                    type: 'SET_USERTYPE',
                                                    value: roleType.includes(
                                                        'patient'
                                                    )
                                                        ? 'patient'
                                                        : 'provider'
                                                })
                                                dispatch({
                                                    type: 'CREATE_USER',
                                                    value: createdUser
                                                })
                                            } else
                                                console.warn(
                                                    'ERROR CREATING USER'
                                                )
                                        })

                                        .catch(createUserError => {
                                            console.warn({ createUserError })
                                        })
                                } else {
                                    setLoading(false)
                                    history.push('/login/code')
                                }
                            })
                            .catch(err => {
                                setLoading(false)
                                console.warn({ err })
                            })
                    }
                })
                .catch(error => {
                    setLoading(false)
                    console.warn({ error })
                })
        }
    }, [user, isAuthenticated])

    // Completely unsure about this, just trying to maintain what was kind of here in a semi-coherent way
    useEffect(() => {
        if (isLoading === false && isAuthenticated === false) {
            localStorage.clear()
            setLoading(false)
            // history.push('/login')
        }
    }, [isLoading, isAuthenticated])

    const goBack = () => {
        history.goBack()
    }
    // TODO: This will need to be addressed at some point but the way we redirect,
    // it will require you to put in the ?release=alpha on every page
    // Left this in as an 'override' to manually set alpha release.
    useEffect(() => {
        /**
         * Allow the display of different release states by checking the
         * URL parameter "release"
         *
         * SECURITY: This should be restricted by role in the future.
         */
        const release = new URLSearchParams(location.search).get('release')
        // only call update to dispatch if release is in url and is not equal to current state
        if (release && state.release !== release) {
            dispatch({ type: 'SET_RELEASE', value: release })
            // console.log(`Software release: ${release}`)
        }
    }, [dispatch, location.search, state.release])

    useEffect(() => {
        if (state.auth.user.email) {
            setLoading(false)
        }
    }, [state.auth.user])

    // Checks whether app is loading and if not pushes to correct page
    useEffect(() => {
        if (!isLoading) {
            if (window.location.pathname === '/' && isAuthenticated)
                history.push('/dashboard')
        }
    }, [isAuthenticated])

    // The following gives the component access to history, location, and match
    // out of the box when using <Route />:
    // render={props => <EditProfile {...props} />}

    // <AuthRoute /> and <SuperUserRoute /> by default injects history, location, and match into each component
    // if you use the component={Something} method.
    if (loading || isLoading) {
        return <Loading displayText="Loading app..." />
    }

    return (
        <Switch>
            {/* Non-Private routes */}
            <Route exact path="/" render={props => <Login {...props} />} />
            <Route exact path="/login/code" component={InvitationsCodeForm} />
            <Route exact path="/create_account" component={CreateAccount} />
            <Route exact path="/privacy" component={PrivacyPolicy} />
            <Route
                exact
                path="/update/:messageType/:userId/:messageId"
                render={props => <ConfirmDataPage {...props} />}
            />

            {/* Private Routes */}
            <AuthRoute
                exact
                path="/dashboard"
                component={DashboardContainer}
                data-testid="routedash"
            />
            <AuthRoute
                exact
                path="/staff/add/:id"
                component={AddUserToStaffContainer}
            />
            <AuthRoute path="/staff/add" component={AddUserToStaffContainer} />
            <AuthRoute exact path="/staff/:id" component={StaffContainer} />
            <AuthRoute path="/staff" component={StaffContainer} />
            <AuthRoute
                exact
                path="/patients/:id"
                component={PatientsContainer}
            />
            <AuthRoute path="/patients" component={PatientsContainer} />
            <AuthRoute
                exact
                path="/invitations/:id"
                component={InvitationsContainer}
            />
            <AuthRoute path="/invitations" component={InvitationsContainer} />
            <AuthRoute
                exact
                path="/terms_and_conditions"
                component={TermsAndConditions}
            />
            <AuthRoute
                exact
                path="/sign_up/info"
                component={BasicRegistrationInfo}
            />
            <AuthRoute exact path="/update_code" component={UpdateCodeForm} />
            <AuthRoute
                path="/medications/add"
                render={() => (
                    <FFGate featureName="meds">
                        <AddOrEditMedication goBack={goBack} />
                    </FFGate>
                )}
            />
            <AuthRoute
                path="/medications/edit/:id"
                render={() => (
                    <FFGate featureName="meds">
                        <AddOrEditMedication goBack={goBack} />
                    </FFGate>
                )}
            />
            <AuthRoute
                path="/medications/delete/:id"
                render={() => (
                    <FFGate featureName="meds">
                        <DeleteMedication goBack={goBack} dispatch={dispatch} />
                    </FFGate>
                )}
            />
            <AuthRoute exact path="/profile/:id" component={Profile} />
            <AuthRoute path="/settings" component={Settings} />
            <AuthRoute
                path="/provider_profile_info"
                component={ProviderProfileInfo}
            />
            <AuthRoute exact path="/update_profile" component={EditProfile} />
            <AuthRoute
                exact
                path="/patient_profile_info/devices"
                component={SensorsContainer}
            />
            <AuthRoute
                path="/patient_profile_info/devices/:id"
                component={MainDevicePage}
            />
            <AuthRoute
                path="/patient_profile_info/delete_account"
                component={DeletePatientAccountContainer}
            />
            <AuthRoute exact path="/goals/create" component={CreateGoalPage} />
            <AuthRoute exact path="/goals/edit/:id" component={EditGoalPage} />
            <AuthRoute
                exact
                path="/goals/delete/:id"
                component={DeleteGoalPage}
            />
            <AuthRoute
                exact
                path="/goals/add_phone_number"
                component={AddPhoneNumber}
            />
            <AuthRoute
                path="/patient_profile_info"
                component={PatientProfileInfo}
            />
            <AuthRoute
                exact
                path="/oauth/fitbit/return"
                component={FitbitOauthReturn}
            />
            <AuthRoute
                exact
                path="/oauth/oura/return"
                component={OuraOauthReturn}
            />

            <AuthRoute exact path="/terms" component={TermsOfService} />
            <AuthRoute exact path="/privacy" component={PrivacyPolicy} />
            <AuthRoute
                exact
                path="/software-agreement"
                component={SoftwareTestingAgreement}
            />
            <AuthRoute
                exact
                path="/software-schedule"
                component={SoftwareTestingSchedule}
            />

            {/* Super user only routes */}
            <SuperUserRoute
                exact
                path="/superuser/audit/:id"
                component={SuperUserAudit}
            />
            <SuperUserRoute
                exact
                path="/superuser/impersonation/:id"
                component={SuperUserImpersonation}
            />
            <SuperUserRoute
                exact
                path="/superuser/impersonation/:orgId/profile/:id"
                component={Profile}
            />
            <SuperUserRoute
                exact
                path="/superuser"
                component={SuperUserContainer}
            />
            <Route
                path="/"
                render={() => {
                    return isAuthenticated ? (
                        <Redirect to="/dashboard" />
                    ) : (
                        <Redirect to="/" />
                    )
                }}
            />
        </Switch>
    )
}

export default Routes
