import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const searchMedsList = searchString => {
    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email
    const body = {
        query: print(gql`
            query {
                getMedicationsByName(searchString: "${searchString}") {
                    id
                    dosageForm
                    brandName
                    genericName
                    brandName1
                    activeIngredients {
                        name
                        strength
                    }
                }
            }
        `)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'getMedicationsByName')
}

export default searchMedsList
