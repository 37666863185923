export const checkSize = () => {
    const width = window.innerWidth
    const device = navigator.userAgent.match(
        /iPhone|ip(hone|od)|android|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|iris|lge|maemo|midp|mmp|phone/i
    )
        ? 'mobile'
        : navigator.userAgent.match(/iPad|kindle/i)
        ? 'tablet'
        : 'monitor'
    const newType =
        device === 'mobile' || device === 'tablet'
            ? device
            : width < 900
            ? 'mobile'
            : width <= 1390
            ? 'tablet'
            : 'monitor'
    return newType
}
