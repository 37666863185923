import React, { Suspense, useState, useEffect } from 'react'
import styled from 'styled-components'
import { useAppContext } from '../../context/AppContext'
import { FFProvider, FFGate } from '@adyptation/feature-flags'
import DashboardHeader from '../Dashboard/DashboardHead'
import DashboardNav from '../Dashboard/DashboardNav'
import DisplayMessages from '../../utils/displayMessages'
import Loading from '../Loading'
import { Slide } from 'react-toastify'
import { checkSize } from '../../logic/checkSize'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../msftAuthConfig'
import { callMsGraph } from '../../apiCalls/msftSSO/msftGraph'
import { useAuth0 } from '@auth0/auth0-react'
import { MSFT_WHITELISTED_GROUP_ID, ENV } from '../../constants'
import { StyledToastContainer } from '../../utils/styledComponents'
import { version } from '../../../package.json'

const jwt = require('jsonwebtoken')

const Main = styled.main`
    width: 100%;
    min-height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
    @media (min-width: 900px) {
        flex-direction: row;
    }
`

const Footer = styled.p`
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: #ffffff;
    text-align: center;
    margin-bottom: 0;
    z-index: 100;
`

const Layout = props => {
    const { accounts, instance } = useMsal()
    const { state, dispatch } = useAppContext()
    const [navOpen, setNavOpen] = useState(false)
    const { user, isAuthenticated, isLoading } = useAuth0()
    const { salt } = state
    const { userType } = state.auth
    const { acceptanceDate, email, messages } = state.auth.user

    // Resize is independent of all the user data stuff
    useEffect(async () => {
        dispatch({ type: 'SET_VIEW_TYPE', value: checkSize() })
        window.addEventListener('resize', () => methods.resize(dispatch))
    }, [])

    useEffect(async () => {
        if (user?.email && salt) {
            const token = jwt.sign(user, salt)
            localStorage.setItem('token', token)
            dispatch({ type: 'SET_TOKEN', value: token })
        }
    }, [user])

    // Checks if user is authenticated after auth0 loading is complete
    // If not, clears local storage and resets context
    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            dispatch({ type: 'RESET' })
            localStorage.clear()
        }
    }, [isLoading])

    // Had to make this a separate effect
    // due to msft cached accounts taking too long to populate
    useEffect(() => {
        if (isAuthenticated && user?.email) {
            if (accounts?.length > 0 && accounts[0].username === user.email) {
                methods.superUserCheck(instance, accounts, dispatch)
            }
        }
    }, [accounts, isAuthenticated, user?.email])

    return (
        <Suspense fallback={<Loading displayText="Loading..." />}>
            <FFProvider
                env={ENV}
                product="web"
                userRoles={state.auth.user.role}
            >
                <StyledToastContainer
                    autoClose={4000}
                    pauseOnHover
                    position="top-right"
                    transition={Slide}
                    hideProgressBar
                    draggable
                />
                {email &&
                    user?.email_verified &&
                    acceptanceDate !== 'incomplete' && (
                        <DashboardHeader
                            setNavOpen={setNavOpen}
                            navOpen={navOpen}
                        />
                    )}
                <Main>
                    {userType &&
                        userType !== 'patient' &&
                        user?.email_verified &&
                        acceptanceDate !== 'incomplete' && (
                            <DashboardNav
                                navOpen={navOpen}
                                setNavOpen={setNavOpen}
                            />
                        )}
                    {props.children}
                </Main>
                {messages?.length > 0 && (
                    <FFGate featureName="messages">
                        <DisplayMessages
                            user={state.auth.user}
                            dispatch={dispatch}
                        />
                    </FFGate>
                )}
            </FFProvider>
            <Footer>
                &copy; Adyptation LLC, {new Date().getFullYear()}. {version}
            </Footer>
        </Suspense>
    )
}

export default Layout

export const methods = {
    resize: dispatch => {
        dispatch({ type: 'SET_VIEW_TYPE', value: checkSize() })
    },
    // Checks MSFT SSO for logged in adyptation account
    // Grants superuser access if found, and is part of
    // verified group
    superUserCheck: async (instance, accounts, dispatch) => {
        try {
            let allowed = false
            const response = await instance.acquireTokenSilent({
                ...loginRequest,
                account: accounts[0]
            })

            const graphRes = await callMsGraph(
                response.accessToken,
                '/transitiveMemberOf/microsoft.graph.group?$count=true'
            )
            allowed = graphRes.value?.some(
                item => item.id === MSFT_WHITELISTED_GROUP_ID
            )

            if (allowed) {
                dispatch({ type: 'SET_SUPERUSER' })
            }
        } catch (error) {
            console.log(error)
        }
    }
}
