import React, { useState, lazy, Suspense } from 'react'
import * as Yup from 'yup'
// import { useHistory } from 'react-router-dom'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { Formik } from 'formik'
import { useAppContext } from '../../context/AppContext'
import {
    useOrgRegistrationContext,
    OrgRegistrationProvider
} from '../../context/OrgRegistrationContext'
import Loading from '../../components/Loading'
import AlertApi from '../../apiCalls'
import * as style from '../../utils/styledComponents'
import { toast } from 'react-toastify'
import States from '../../utils/states'
import EntityTypes from '../../utils/entityTypes'
import getUserInfo from '../../logic/getUserInfo'
import normalizeString from '../../utils/normalizeString'
// import AddressConfirmatinModal from '../Address/AddressConfirmationModal'
import SubHeading from '../../components/atoms/headings/SubHeading'
import FormInput from '../../components/atoms/forms/FormInput'
import FormCheckbox from '../../components/atoms/forms/FormCheckbox'
import PrimaryButton from '../../components/atoms/buttons/PrimaryButton'
import CancelButton from '../../components/atoms/buttons/CancelButton'
import TelephoneInput from '../../components/atoms/forms/TelephoneInput'
const AddressConfirmatinModal = lazy(() =>
    import('../Address/AddressConfirmationModal')
)

export const ConfirmCancelProviderRegistration = ({ close, setPending }) => {
    const { state, dispatch } = useAppContext()

    const handleClick = () => {
        setPending('Changing to patient account...')
        AlertApi.updateUser(
            { id: state.auth.user.id },
            ['patient'],
            state.auth.user.email
        )
            .then(_ => {
                dispatch({ type: 'SET_USERTYPE', value: 'patient' })
                dispatch({
                    type: 'UPDATE_USER',
                    value: { role: ['patient'] }
                })
                setPending(undefined)
            })
            .catch(error => {
                toast.error(error.message)
                setPending(undefined)
            })
    }

    return (
        <>
            <style.HeaderLabel>Account Change Confirmation</style.HeaderLabel>
            <style.BasicDiv>
                By canceling the healthcare provider registration, your account
                will be changed to a patient account and you will only have
                access to your own personal data. <br />
                You will be unable to collaborate with others and manage
                patients using this platform.
            </style.BasicDiv>
            <br />
            <style.BasicDiv>
                You may be invited to join an organization as part of their
                healthcare team after you cancel this setup.
                <br />
                Contact your office administrator.
            </style.BasicDiv>
            <br />
            <style.BasicDiv style={{ fontWeight: 'bolder' }}>
                Are you sure you wish to cancel your registration as a
                healthcare provider?
            </style.BasicDiv>
            <style.FlexRowSE mobileColumn centerVertically rowReverse>
                <PrimaryButton
                    text="No, Create My Organization"
                    onClick={close}
                />
                <CancelButton text="Yes, Cancel Setup" onClick={handleClick} />
            </style.FlexRowSE>
        </>
    )
}

const ProviderFirstLoginForm = () => {
    const { state, dispatch } = useAppContext()
    const { user } = state.auth
    const { dispatch: orgDispatch } = useOrgRegistrationContext()
    const [pending, setPending] = useState(undefined)
    const [cancelProviderRegistration, setCancelProviderRegistration] =
        useState(false)
    const [checkAddress, setCheckAddress] = useState(false)
    const [addressValues, setAddressValues] = useState(undefined)
    const [newPhone, setNewPhone] = useState('')
    const [phoneError, setPhoneError] = useState(undefined)
    // const history = useHistory()

    // I left this function in here to call the extracted one
    // and send the user object, as well as setPending and dispatch functions with it.
    // Otherwise I'd have to prop drill these things down to the children
    // Just to send them back up anyways
    const handleSubmit = async (values, validated = false) => {
        methods.handleSubmit(
            values,
            validated,
            user,
            setPending,
            dispatch,
            newPhone
        )
    }

    const checkPhone = thisPhone => {
        if (thisPhone && thisPhone.trim() !== '') {
            const valid = isValidPhoneNumber(thisPhone.trim())
            if (valid) {
                setNewPhone(thisPhone.trim())
                setPhoneError(undefined)
            } else setPhoneError('Phone number must be valid')
        }
    }

    const disabled = values =>
        values.name === '' ||
        values.address === '' ||
        values.line1 === '' ||
        values.city === '' ||
        values.zip.length < 5 ||
        !values.agreeterms

    return (
        <>
            <style.StyledLoginWrapper address>
                {cancelProviderRegistration ? (
                    <ConfirmCancelProviderRegistration
                        close={() => setCancelProviderRegistration(false)}
                        setPending={setPending}
                    />
                ) : (
                    <>
                        <SubHeading text="Setup your Organization." />
                        <Formik
                            initialValues={{
                                name: '',
                                entity: 'Limited Liability Company (LLC)',
                                line1: '',
                                line2: '',
                                city: '',
                                state: 'AL',
                                zip: '',
                                agreeterms: false,
                                email: '',
                                url: ''
                            }}
                            validationSchema={Yup.object().shape({
                                agreeterms: Yup.bool().oneOf(
                                    [true],
                                    'Agreeing to the software agreement and schedule is required.'
                                )
                            })}
                            onSubmit={values => {
                                setTimeout(() => {
                                    methods.checkAddressValues(
                                        values,
                                        setAddressValues,
                                        setCancelProviderRegistration,
                                        setCheckAddress
                                    )
                                }, 400)
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit
                            }) => (
                                <style.StyledForm onSubmit={handleSubmit}>
                                    <style.FlexRowSB
                                        mobileColumn
                                        centerVertically
                                    >
                                        <FormInput
                                            label="Organization Name"
                                            name="name"
                                            value={values.name}
                                            error={touched.name && errors.name}
                                            touched={touched.name}
                                            placeholder="ACME Healthcare"
                                            onChange={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleChange,
                                                    orgDispatch
                                                )
                                            }
                                            onBlur={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleBlur,
                                                    orgDispatch
                                                )
                                            }
                                            width="50%"
                                        />
                                        <FormInput
                                            label="Type of Organization"
                                            name="entity"
                                            onChange={handleChange}
                                            value={values.entity}
                                            size="1"
                                            data-testid="entitySelector"
                                            useDropdown
                                            options={EntityTypes}
                                            width="40%"
                                        />
                                    </style.FlexRowSB>
                                    <style.FlexRowSB
                                        mobileColumn
                                        centerVertically
                                    >
                                        <FormInput
                                            label="Address Line 1"
                                            name="line1"
                                            value={values.line1}
                                            error={
                                                touched.line1 && errors.line1
                                            }
                                            touched={touched.line1}
                                            placeholder="123 Main St"
                                            onChange={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleChange,
                                                    orgDispatch
                                                )
                                            }
                                            onBlur={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleBlur,
                                                    orgDispatch
                                                )
                                            }
                                            width="50%"
                                        />
                                        <FormInput
                                            label="Address Line 2 (Optional)"
                                            name="line2"
                                            value={values.line2}
                                            placeholder="Suite 123"
                                            onChange={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleChange,
                                                    orgDispatch
                                                )
                                            }
                                            onBlur={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleBlur,
                                                    orgDispatch
                                                )
                                            }
                                            width="40%"
                                        />
                                    </style.FlexRowSB>
                                    <style.FlexRowSB
                                        mobileColumn
                                        centerVertically
                                    >
                                        <FormInput
                                            label="City"
                                            name="city"
                                            value={values.city}
                                            error={touched.city && errors.city}
                                            touched={touched.city}
                                            placeholder="Anywhere"
                                            onChange={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleChange,
                                                    orgDispatch
                                                )
                                            }
                                            onBlur={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleBlur,
                                                    orgDispatch
                                                )
                                            }
                                            width="40%"
                                        />
                                        <FormInput
                                            label="State"
                                            name="state"
                                            onChange={handleChange}
                                            value={values.state}
                                            size="1"
                                            data-testid="stateSelector"
                                            useDropdown
                                            options={States}
                                            width="25%"
                                        />
                                        <FormInput
                                            label="Zip Code"
                                            name="zip"
                                            value={values.zip}
                                            error={touched.zip && errors.zip}
                                            touched={touched.zip}
                                            placeholder="12345"
                                            onChange={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleChange,
                                                    orgDispatch
                                                )
                                            }
                                            onBlur={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleBlur,
                                                    orgDispatch
                                                )
                                            }
                                            width="20%"
                                        />
                                    </style.FlexRowSB>
                                    <style.FlexRowSB
                                        mobileColumn
                                        centerVertically
                                    >
                                        <TelephoneInput
                                            value={newPhone}
                                            onChange={checkPhone}
                                            error={phoneError}
                                            placeholder="(615) 867-5309"
                                            data-testid="phoneField"
                                            width="40%"
                                        />
                                        <FormInput
                                            error={
                                                touched.email && errors.email
                                            }
                                            label="Organization Email"
                                            name="email"
                                            value={values.email}
                                            placeholder="info@example.com"
                                            onChange={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleChange,
                                                    orgDispatch
                                                )
                                            }
                                            onBlur={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleBlur,
                                                    orgDispatch
                                                )
                                            }
                                            id="emailInput"
                                            width="50%"
                                        />
                                    </style.FlexRowSB>
                                    <style.FlexRowSB>
                                        <FormInput
                                            label="Organization Website"
                                            name="url"
                                            value={values.url}
                                            error={touched.url && errors.url}
                                            touched={touched.url}
                                            placeholder="www.adyptation.com"
                                            onChange={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleChange,
                                                    orgDispatch
                                                )
                                            }
                                            onBlur={async e =>
                                                methods.handleFormEvent(
                                                    e,
                                                    handleBlur,
                                                    orgDispatch
                                                )
                                            }
                                        />
                                    </style.FlexRowSB>
                                    <FormCheckbox
                                        label={
                                            <span>
                                                I agree to the{' '}
                                                <style.MainLinkText
                                                    to="/software-agreement"
                                                    target="_blank"
                                                >
                                                    Software Testing Agreement
                                                </style.MainLinkText>{' '}
                                                and{' '}
                                                <style.MainLinkText
                                                    to="/software-schedule"
                                                    target="_blank"
                                                >
                                                    Software Testing Schedule
                                                </style.MainLinkText>
                                                .
                                            </span>
                                        }
                                        name="agreeterms"
                                        onChange={async e =>
                                            methods.handleFormEvent(
                                                e,
                                                handleChange,
                                                orgDispatch
                                            )
                                        }
                                        onBlur={async e =>
                                            methods.handleFormEvent(
                                                e,
                                                handleBlur,
                                                orgDispatch
                                            )
                                        }
                                        // value={values.agreeterms}
                                        checked={values.agreeterms}
                                        error={errors.agreeterms}
                                        id="agreeTerms"
                                        testid="agreeTermsCheckbox"
                                    />
                                    <br />
                                    <style.FlexRowSE
                                        mobileColumn
                                        centerVertically
                                        rowReverse
                                    >
                                        <PrimaryButton
                                            type="submit"
                                            text="Create New Organization"
                                            disabled={disabled(values)}
                                        />
                                        <CancelButton
                                            text="Cancel Setup"
                                            danger={true}
                                            onClick={() =>
                                                setCancelProviderRegistration(
                                                    true
                                                )
                                            }
                                        />
                                    </style.FlexRowSE>
                                </style.StyledForm>
                            )}
                        </Formik>
                    </>
                )}
            </style.StyledLoginWrapper>
            {checkAddress && (
                <Suspense
                    fallback={<Loading displayText="Checking address..." />}
                >
                    <AddressConfirmatinModal
                        values={addressValues}
                        cancel={() => setCheckAddress(false)}
                        submit={handleSubmit}
                    />
                </Suspense>
            )}
            {pending && <Loading displayText={pending} />}
        </>
    )
}

const ProviderFirstLogin = () => {
    /**
     * We are using a React context here so if/when a new provider clicks on the
     * software agreement/schedule the information they enter in the actual form
     * can be inserted into the agreement prior to them submitting the form. This
     * is necessary because the forms open up in a new tab (target="_blank") and need
     * access to the registration data.
     *
     * It seems non-standard, but React context must exist outside the form component
     * for the context to work properly. That is the reason we're setting context here
     * and have moved the form into another component.
     */
    return (
        <OrgRegistrationProvider>
            <ProviderFirstLoginForm />
        </OrgRegistrationProvider>
    )
}

export default ProviderFirstLogin

export const methods = {
    checkAddressValues: async (
        values,
        setAddressValues,
        setCancelProviderRegistration,
        setCheckAddress
    ) => {
        setAddressValues(values)
        if (!values.agreeterms) setCancelProviderRegistration(true)
        else if (values.name === '')
            toast.error('You must enter an organization name to continue.')
        else if (
            process.env.NODE_ENV === 'production' &&
            values.name.toLowerCase() === 'adyptation'
        )
            toast.error('That organization name is restricted')
        else setCheckAddress(true)
    },
    handleSubmit: async (
        values,
        validated,
        user,
        setPending,
        dispatch,
        phone
        // history
    ) => {
        try {
            const newOrg = {
                name: normalizeString(values.name),
                entity: normalizeString(values.entity),
                line1: normalizeString(values.line1),
                line2:
                    values.line2 === '' ? 'N/A' : normalizeString(values.line2),
                city: normalizeString(values.city),
                state: values.state,
                zip: values.zip
            }
            if (
                values.email &&
                // eslint-disable-next-line
                /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(
                    values.email.trim()
                )
            )
                newOrg.email = values.email.trim()
            if (phone) newOrg.phone = phone
            if (values.url && values.url.trim() !== '')
                newOrg.url = values.url.trim()
            // Initial create org call
            setPending('Saving your organization...')
            const orgInfo = await AlertApi.createOrg(
                newOrg,
                validated,
                user.email
            )

            const updatedUser = {
                id: user.id,
                organizationId: orgInfo.id,
                currentStatus: 'active',
                agreementInfo: `Accepted By: ${user.first} ${user.last}, Provider. Accepted On: ${window.navigator.userAgent}`,
                acceptanceDate: new Date().toISOString()
            }

            // Update user info
            setPending('Updating your profile...')
            await AlertApi.updateUser(updatedUser, user.role, user.email)

            // Retrieve new user info
            setPending('Loading new settings...')
            const userData = await getUserInfo(user.email)
            if (userData && !userData.error) {
                dispatch({
                    type: 'SET_USER_ORG',
                    value: userData
                })
            }

            // toast and set 'logged in' state
            toast.success('Successfully created organization.')
            setPending(undefined)
            dispatch({ type: 'SET_ISAUTH', value: true })
            // history.push('/dashboard')
        } catch (error) {
            toast.error(error.message)
            setPending(undefined)
        }
    },
    handleFormEvent: async (e, f, orgDispatch) => {
        const fieldName = e.currentTarget.name
        const value = e.currentTarget.value
        const val =
            fieldName === 'entity' || fieldName === 'state'
                ? value
                : normalizeString(value)
        const record = { [fieldName]: val }

        if (
            fieldName === 'line1' ||
            fieldName === 'line2' ||
            fieldName === 'city' ||
            fieldName === 'state' ||
            fieldName === 'zip'
        ) {
            orgDispatch({ type: 'UPDATE_ADDRESS', value: record })
        } else {
            orgDispatch({ type: 'UPDATE_ORG', value: record })
        }

        await f(e)
    }
}
