import React from 'react'
import styled from 'styled-components'
import { VictoryGroup, VictoryBar } from 'victory'
import {
    graphFill,
    alertsPrimary,
    typographyMainColor,
    fontFamily,
    dangerStatusColor,
    warningStatusColor
} from '../../constants'
import { FlexRowSB } from '../../utils/styledComponents'

const MiniBarGraph = ({ type, title, point, max, titleRight }) => {
    const d = [
        { x: 0, y: max },
        { x: 0, y: point }
    ]

    return (
        <GraphContainer
            id={`${type}MiniBarGraph`}
            data-testid={`${type}MiniBarGraph`}
        >
            <FlexRowSB>
                <LabelText>{title}</LabelText>
                <LabelText>{titleRight}</LabelText>
            </FlexRowSB>

            <VictoryGroup
                data={d}
                height={5}
                domain={{ x: [0, 1], y: [0, 100] }}
                padding={{ top: 0, bottom: 0, left: 0, right: 0 }}
            >
                <VictoryBar
                    barWidth={5}
                    horizontal={true}
                    alignment="start"
                    style={{
                        data: {
                            fill: ({ datum }) =>
                                datum.y === max
                                    ? graphFill
                                    : methods.getBarColor(datum.y),
                            fillOpacity: ({ datum }) =>
                                datum.y === max ? 0.4 : 1
                        }
                    }}
                />
            </VictoryGroup>
        </GraphContainer>
    )
}

export default React.memo(MiniBarGraph)

const GraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    height: 8vh;
    padding: 0 25px;

    @media (min-width: 900px) {
        height: 10vh;
    }
`
const LabelText = styled.p`
    margin: 0;
    color: ${typographyMainColor};
    font-size: 14px;
    font-family: ${fontFamily};
`
export const methods = {
    // Returns green, red, yellow color based on min-max range
    getBarColor: (score, min = 0, max = 100) => {
        const v = parseFloat(score)
        const dangerBreakpoint = 40 // min + range * 0.33
        const successBreakpoint = 70 // max - range * 0.33

        switch (true) {
            case v <= dangerBreakpoint:
                return dangerStatusColor
            case v >= successBreakpoint:
                return alertsPrimary
            case isNaN(v):
            default:
                return warningStatusColor
        }
    }
}
