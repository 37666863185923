import React from 'react'
import styled from 'styled-components'
import { Spinner } from 'react-bootstrap'
import { primaryMainColor } from './../constants'

const LoadingWrapper = styled.div`
    position: fixed;
    z-index: 1006;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
`

const SpinnerDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const StyledSpinner = styled(Spinner)`
    color: ${primaryMainColor};
    height: 80px;
    width: 80px;
    margin: auto;
    border: 0.5em solid currentColor;
    border-right-color: transparent;
    animation: spinner-border 1.25s linear infinite;
`

const StyledText = styled.span`
    color: white;
    font-size: 26px;
    padding-top: 15px;
    font-weight: bolder;
    text-align: center;
`

const Loading = ({ displayText }) => (
    <LoadingWrapper>
        <SpinnerDiv>
            <StyledSpinner animation="border" role="status" />
            <StyledText>{displayText}</StyledText>
        </SpinnerDiv>
    </LoadingWrapper>
)

Loading.defaultProps = {
    displayText: 'Loading...'
}
export default Loading
