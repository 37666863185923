import React, { useState } from 'react'
import styled from 'styled-components'
import ModalContainer from '../containers/Modal/ModalContainer'
import ButtonLink from '../components/atoms/links/ButtonLink'
import { primaryMainColor, buttonBackgroundHover } from '../constants'
import AlertApi from '../apiCalls'

export const formatMessaging = message => {
    switch (message.messageType) {
        case 'updateMedications':
            return {
                ...message,
                text:
                    // eslint-disable-next-line
                    "It's been 30 days since you last made any changes to your medications list. That information helps us gauge how your medication is impacting your health, so it's important to keep that list current. Please take a moment to update your medications list in your profile.",
                deferText: 'My medications have not changed.',
                editText: 'Edit medications'
            }
        case 'reconnectDevice':
            return {
                ...message,
                text: message.message,
                editText: 'Reconnect Now'
            }
        case 'updateProfile':
            return {
                ...message,
                text: message.message,
                editText: 'Update Profile'
            }
        default:
            return {
                ...message,
                text: 'Please contact tech support.',
                editText: 'View My Profile'
            }
    }
}

export const setNewShowDate = (message, user, update, dispatch) => {
    update(false)
    AlertApi.updateMessage(user, message.messageType, false).then(messages => {
        dispatch({ type: 'UPDATE_USER', value: { messages } })
    })
}

const DisplayMessages = ({ user, dispatch }) => {
    const [showMessage, setShowMessage] = useState(true)

    const messagesToShow = user.messages.filter(
        message => message.displayMessage
    )

    if (messagesToShow.length > 0) {
        const message = formatMessaging(messagesToShow[0])

        return (
            <>
                {showMessage && (
                    <ModalContainer>
                        <Card data-testid={message.messageType}>
                            <Title>Welcome back, {user.first}!</Title>
                            <Message>{message.text}</Message>
                            <ButtonDiv>
                                {message.deferText && (
                                    <CancelButton
                                        onClick={() =>
                                            setNewShowDate(
                                                message,
                                                user,
                                                setShowMessage,
                                                dispatch
                                            )
                                        }
                                    >
                                        {message.deferText}
                                    </CancelButton>
                                )}
                                <ButtonLink
                                    nounderline="true"
                                    to={message.linkUrl}
                                    onClick={() => setShowMessage(false)}
                                >
                                    {message.editText}
                                </ButtonLink>
                            </ButtonDiv>
                        </Card>
                    </ModalContainer>
                )}
            </>
        )
    }
    return null
}

export const Card = styled.div`
    width: 100%;
    padding: 15px;
    border-radius: 15px;
    background-color: white;
    border: 1px solid grey;
    @media (min-width: 900px) {
        width: 50%;
        min-width: 630px;
        padding: 75px 50px;
    }
`

export const Title = styled.h2`
    font-size: 30px;
    font-weight: bolder;
    text-align: center;
    margin-bottom: 20px;
`

export const Message = styled.p`
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
`

export const ButtonDiv = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`

export const CancelButton = styled.p`
    color: ${primaryMainColor};
    cursor: pointer;
    text-decoration: underline;
    display: inline-flex;
    :hover {
        color: ${buttonBackgroundHover} !important;
    }
`

export default DisplayMessages
