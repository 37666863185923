import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const createInvitation = (values, role, email) => {
    const userEmail =
        email || JSON.parse(localStorage.getItem('state')).auth.user.email
    const roles = role.map(r => `"${r}"`).join(', ')
    const newValues =
        Object.entries(values)
            .map(val => `${val[0]}: "${val[1]}"`)
            .join(', ') + `, roleType: [${roles}]`
    const body = {
        query: print(gql`
        mutation {
            createInvitation(${newValues}) {
                id
                first
                last
                email
                organizationId
                organizationName
                roleType
                emailSent
                lastEmailSent
                updatedAt
                createdAt
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'createInvitation')
}

export default createInvitation
