import React from 'react'
import styled from 'styled-components'
import { modalTransBackground } from '../../constants'

const StyledWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 2000;
    top: 0;
    left: 0;
    background-color: ${modalTransBackground};
`

const InnerWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 80%;

    @media (min-width: 768px) {
        min-width: 60%;
    }
`

const ModalContainer = ({ component, children }) => {
    return (
        <StyledWrapper>
            <InnerWrapper>{component || children}</InnerWrapper>
        </StyledWrapper>
    )
}

export default ModalContainer
