import React from 'react'
import * as comp from '../../utils/styledComponents'
import TextLink from '../atoms/links/TextLink'

export const LoginFooter = () => (
    <comp.FlexRowSE style={{ minHeight: '50px' }} centerVertically>
        <TextLink text="Terms of Use" to="/terms" />
        <comp.StyledBulletPoint />
        <TextLink text="Privacy Policy" to="/privacy" />
    </comp.FlexRowSE>
)
