import moment from 'moment'
moment.suppressDeprecationWarnings = true

export const fakeGoalProgressGraphData = [
    { createdAt: moment().subtract(28, 'days'), currentValue: 11 },
    { createdAt: moment().subtract(27, 'days'), currentValue: 12 },
    { createdAt: moment().subtract(26, 'days'), currentValue: 15 },
    { createdAt: moment().subtract(25, 'days'), currentValue: 19 },
    { createdAt: moment().subtract(24, 'days'), currentValue: 25 },
    { createdAt: moment().subtract(23, 'days'), currentValue: 38 },
    { createdAt: moment().subtract(22, 'days'), currentValue: 50 },
    { createdAt: moment().subtract(21, 'days'), currentValue: 52 },
    { createdAt: moment().subtract(20, 'days'), currentValue: 55 },
    { createdAt: moment().subtract(19, 'days'), currentValue: 52 },
    { createdAt: moment().subtract(18, 'days'), currentValue: 60 },
    { createdAt: moment().subtract(17, 'days'), currentValue: 55 },
    { createdAt: moment().subtract(16, 'days'), currentValue: 43 },
    { createdAt: moment().subtract(15, 'days'), currentValue: 43 },
    { createdAt: moment().subtract(14, 'days'), currentValue: 41 },
    { createdAt: moment().subtract(13, 'days'), currentValue: 37 },
    { createdAt: moment().subtract(12, 'days'), currentValue: 35 },
    { createdAt: moment().subtract(11, 'days'), currentValue: 46 },
    { createdAt: moment().subtract(10, 'days'), currentValue: 52 },
    { createdAt: moment().subtract(9, 'days'), currentValue: 65 },
    { createdAt: moment().subtract(8, 'days'), currentValue: 72 },
    { createdAt: moment().subtract(7, 'days'), currentValue: 80 },
    { createdAt: moment().subtract(6, 'days'), currentValue: 87 },
    { createdAt: moment().subtract(5, 'days'), currentValue: 81 },
    { createdAt: moment().subtract(4, 'days'), currentValue: 71 },
    { createdAt: moment().subtract(3, 'days'), currentValue: 73 },
    { createdAt: moment().subtract(2, 'days'), currentValue: 66 },
    { createdAt: moment().subtract(1, 'days'), currentValue: 63 },
    { createdAt: moment(), currentValue: 53 }
]
