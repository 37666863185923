import React from 'react'
// import React, { useState } from 'react'
import PhoneInput from 'react-phone-number-input/input'
// import { isValidPhoneNumber } from 'react-phone-number-input'
import { Input, MainContainer } from './FormInput'
import FormLabel from './FormLabel'
import { Error } from '../../../utils/styledComponents'

const TelephoneInput = ({
    width,
    onChange,
    value,
    label,
    placeholder,
    error,
    ...rest
}) => {
    // const [error, setError] = useState(undefined)

    return (
        <MainContainer width={width}>
            <FormLabel>{label}</FormLabel>
            <PhoneInput
                country="US"
                // international={false}
                // defaultCountry="US"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                inputComponent={Input}
                error={error}
                {...rest}
            />
            <Error>{error}</Error>
        </MainContainer>
    )
}

TelephoneInput.defaultProps = {
    label: 'PHONE',
    placeholder: 'Enter phone number',
    onChange: () => {}
}
export default TelephoneInput
