const entityTypes = [
    {
        key: 'Limited Liability Company (LLC)',
        value: 'Limited Liability Company (LLC)'
    },
    {
        key: 'Limited Liability Partnership (LLP)',
        value: 'Limited Liability Partnership (LLP)'
    },
    { key: 'Sole Proprietorship', value: 'Sole Proprietorship' },
    { key: 'General Partnership', value: 'General Partnership' },
    { key: 'Limited Partnership', value: 'Limited Partnership' },
    { key: 'Corporation (C-Corp)', value: 'Corporation (C-Corp)' },
    { key: 'Corporation (S-Corp)', value: 'Corporation (S-Corp)' },
    { key: 'Other', value: 'Other' }
]

export default entityTypes
