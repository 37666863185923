import React from 'react'
import * as utils from './insightsUtils'

const InsightsBox = attrs => {
    return (
        <utils.Div data-id="insight" {...attrs}>
            <utils.StyledInsightsWrapper view={attrs.view}>
                {attrs.title ? (
                    <utils.TitleMessage>{attrs.title}</utils.TitleMessage>
                ) : null}
                <utils.Div
                    style={{
                        flexDirection:
                            attrs.view === 'mobile' ? 'column' : 'row',
                        display: 'flex'
                    }}
                >
                    <utils.MainNumber
                        value={attrs.current}
                        subtitle={attrs.mainsubtitle}
                        view={attrs.view}
                        bioType={attrs.bioType}
                    />
                    {!attrs.singlewide ? (
                        <utils.InsightRightSide
                            value={
                                attrs.previous ? attrs.previous : attrs.average
                            }
                            view={attrs.view}
                            bioType={attrs.bioType}
                            direction={attrs.direction}
                            subtitle={attrs.rightsubtitle}
                        />
                    ) : null}
                </utils.Div>
                {attrs.message ? (
                    <utils.LowerMessage>{attrs.message}</utils.LowerMessage>
                ) : null}
            </utils.StyledInsightsWrapper>
        </utils.Div>
    )
}

export default InsightsBox
