import React, { useEffect, useState } from 'react'
import { StyledDiv } from '../../utils/styledComponents'
import ModalContainer from '../Modal/ModalContainer'
import GoalsPrompt from '../Registration/GoalsPrompt'
import DashScoreSummary from '../../components/organisms/DashScoreSummary'
import FakeScoreSummary from '../../components/Dashboard/FakeScoreSummary'
import FakeDeviceGraph from '../../components/Dashboard/FakeDeviceGraph'
import DeviceDayGraph from '../../components/Graphs/DeviceDayGraph'
import { useAppContext } from '../../context/AppContext'
import {
    formatBioMarkers,
    getSleepFromBioMarkers,
    getStepsFromBioMarkers
} from '../../logic/bioMarkerLogic'
import { toast } from 'react-toastify'
import AlertApi from '../../apiCalls'
import { FFGate } from '@adyptation/feature-flags'
import { formatPearsonEntries } from '../../logic/scoreLogic'
import { BASE_ORG_ID } from '../../constants'

const requestChangeValues = [
    'patientInvitationAccepted',
    'patientInvitationDenied'
]

// // length of graph
const graphDays = 30

// Patient Overview container for dash / provider viewing
// Pass in user object, receive dash with graphs for said user
const OverviewContainer = ({ patient, isViewingAsProvider }) => {
    const { state, dispatch } = useAppContext()
    const { user, organization } = state.auth
    const [loadingInsights, setLoadingInsights] = useState(
        !user.goalDefinitions || !user.insightsSnapshot
    )
    const [loadingMarkers, setLoadingMarkers] = useState(
        !user.steps || !user.sleep
    )
    const [patientInsights, setPatientInsights] = useState(undefined)
    const [patientGoals, setPatientGoals] = useState(undefined)
    const [patientJournals, setPatientJournals] = useState([])
    const [newUser, setNewUser] = useState(false)
    const [markers, setMarkers] = useState(undefined)
    const [pearsonInsights, setPearsonInsights] = useState([])
    const showCorrelation = organization?.id && organization.id === BASE_ORG_ID

    useEffect(() => {
        if (user?.email) {
            if (isViewingAsProvider) {
                AlertApi.getInsights(patient.email).then(result => {
                    const { goalDefinitions, insightsSnapshot, goalsJournals } =
                        result
                    setLoadingInsights(false)
                    setPatientGoals(goalDefinitions)
                    setPatientInsights(insightsSnapshot)
                    setPatientJournals(goalsJournals)
                })
                AlertApi.getMarkers(patient.email)
                    .then(result => {
                        processData(result.bioMarkers)
                        setLoadingMarkers(false)
                    })
                    .catch(err => {
                        console.warn({ err })
                    })
            } else {
                if (user?.steps?.length > 0 || user?.sleep?.length > 0) {
                    processData()
                }
                AlertApi.getInsights(user.email)
                    .then(result => {
                        const {
                            goalDefinitions,
                            insightsSnapshot,
                            goalsJournals
                        } = result
                        dispatch({
                            type: 'UPDATE_USER',
                            value: {
                                goalDefinitions,
                                insightsSnapshot,
                                goalsJournals,
                                role: result.role
                            }
                        })
                        setLoadingInsights(false)
                    })
                    .catch(err => {
                        console.warn({ err })
                    })
                AlertApi.getMarkers(user.email)
                    .then(result => {
                        processData(result.bioMarkers)
                        dispatch({
                            type: 'UPDATE_USER',
                            value: {
                                bioMarkers: result.bioMarkers,
                                role: result.role
                            }
                        })
                        setLoadingMarkers(false)
                    })
                    .catch(err => {
                        console.warn({ err })
                    })
            }
        }
    }, [user?.email])

    const processData = newData => {
        const userSleep = newData ? getSleepFromBioMarkers(newData) : user.sleep
        const userSteps = newData ? getStepsFromBioMarkers(newData) : user.steps
        if (userSleep || userSteps) {
            setNewUser(
                user.currentStatus === 'firstLogin' ||
                    user.currentStatus === 'pendingInvite'
            )
            // grabs step markers and formats into array of objects in {x: '', y: ''} pattern
            const stepsData = userSteps
                ? formatBioMarkers(graphDays, userSteps)
                : null
            // grabs maxStep value for use to set y domain of graph
            const maxSteps =
                stepsData?.length > 0
                    ? Array.from(stepsData).sort((a, b) => b.y - a.y)[0].y
                    : null
            // grabs sleep markers and formats into array of objects in {x: '', y: ''} pattern
            const sleepData = userSleep
                ? formatBioMarkers(graphDays, userSleep)
                : null
            // grabs maxSleep value for use to set y domain of graph
            const maxSleep =
                sleepData?.length > 0
                    ? Array.from(sleepData).sort((a, b) => b.y - a.y)[0].y
                    : null
            setMarkers({ stepsData, sleepData, maxSteps, maxSleep })
        }
    }

    // Check for approved/rejected org requests
    useEffect(() => {
        if (isViewingAsProvider) return

        if (requestChangeValues.includes(user.currentStatus)) {
            methods.handleRequestResponse(user)
        }
    }, [])

    // Format goalsJournals entries into usable pearson data array
    useEffect(() => {
        const goalsJournals =
            patientJournals?.length > 0 ? patientJournals : user.goalsJournals
        if (goalsJournals?.length > 0) {
            methods.makePearsonInsights(goalsJournals, setPearsonInsights)
        }
    }, [patientJournals, user.goalsJournals])

    return (
        <StyledDiv data-testid="DashboardPatientContainer">
            {loadingInsights ? (
                <RenderScoreSummary placeholder={true} />
            ) : (
                <RenderScoreSummary
                    goalDefinitions={patientGoals || user.goalDefinitions}
                    insightsSnapshot={patientInsights || user.insightsSnapshot}
                    isViewingAsProvider={isViewingAsProvider}
                    textMessageStatus={user.textMessageStatus}
                />
            )}
            {loadingMarkers ? (
                <>
                    <FakeDeviceGraph type="steps" />
                    <FakeDeviceGraph type="sleep" />
                </>
            ) : (
                <>
                    {(markers?.stepsData || markers?.sleepData) && (
                        <>
                            {markers?.stepsData && markers.maxSteps && (
                                <DeviceDayGraph
                                    markers={markers.stepsData}
                                    insights={pearsonInsights}
                                    type="STEPS"
                                    title="Steps per Day"
                                    max={markers.maxSteps}
                                    showCorrelation={showCorrelation}
                                />
                            )}
                            {markers.sleepData && markers.maxSleep && (
                                <DeviceDayGraph
                                    markers={markers.sleepData}
                                    insights={pearsonInsights}
                                    type="HOURS"
                                    title="Hours of Sleep"
                                    max={markers.maxSleep}
                                    showCorrelation={showCorrelation}
                                />
                            )}
                        </>
                    )}
                </>
            )}
            {!isViewingAsProvider && newUser && (
                <FFGate featureName="new_patient_goals_prompt">
                    <ModalContainer
                        component={
                            <GoalsPrompt close={() => setNewUser(false)} />
                        }
                    />
                </FFGate>
            )}
        </StyledDiv>
    )
}

export default OverviewContainer

// This had to be a proper Component
// so that circleci testing didn't fail.
export const RenderScoreSummary = ({
    goalDefinitions = [],
    insightsSnapshot = [],
    isViewingAsProvider = false,
    placeholder = false,
    textMessageStatus
}) => {
    if (placeholder) return <FakeScoreSummary isPlaceholder={true} />
    else if (goalDefinitions?.length === 0) {
        return (
            <FakeScoreSummary
                isViewingAsProvider={isViewingAsProvider}
                isPlaceholder={false}
            />
        )
    }
    return insightsSnapshot?.length === 0 ? (
        <FakeScoreSummary
            noData
            startDate={goalDefinitions[0].updatedAt}
            isViewingAsProvider={isViewingAsProvider}
        />
    ) : (
        <DashScoreSummary
            goalDefinitions={goalDefinitions}
            insightsSnapshot={insightsSnapshot}
            textMessageStatus={textMessageStatus}
        />
    )
}

export const methods = {
    handleRequestResponse: async user => {
        try {
            const updatedValues = { id: user.id, currentStatus: 'active' }
            await AlertApi.updateUser(updatedValues, user.role)
            if (user.currentStatus === requestChangeValues[0]) {
                toast.success(
                    'The organization you requested to join has accepted your request!'
                )
            } else {
                toast.error(
                    'The organization you requested to join denied your request.'
                )
            }
        } catch (error) {
            toast.error(error.message)
        }
    },
    makePearsonInsights: (insights, setPearsonInsights) => {
        const pearsonInsights = formatPearsonEntries(graphDays, insights)
        setPearsonInsights(pearsonInsights)
    }
}
