import { graphConfig } from '../../msftAuthConfig'

export async function callMsGraph(accessToken, query = '') {
    const headers = new Headers()
    const bearer = `Bearer ${accessToken}`
    const url = graphConfig.graphMeEndpoint + query
    headers.append('Authorization', bearer)

    const options = {
        method: 'GET',
        headers: headers
    }

    return fetch(url, options)
        .then(response => response.json())
        .catch(error => console.log(error))
}
