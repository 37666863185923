import moment from 'moment'

export const TWENTY_EIGHT_DAY_SCORE_DATA = [
    { createdAt: moment().subtract(28, 'days'), currentValue: 21 },
    { createdAt: moment().subtract(27, 'days'), currentValue: 24 },
    { createdAt: moment().subtract(26, 'days'), currentValue: 30 },
    { createdAt: moment().subtract(25, 'days'), currentValue: 28 },
    { createdAt: moment().subtract(24, 'days'), currentValue: 32 },
    { createdAt: moment().subtract(23, 'days'), currentValue: 33 },
    { createdAt: moment().subtract(22, 'days'), currentValue: 36 },
    { createdAt: moment().subtract(21, 'days'), currentValue: 39 },
    { createdAt: moment().subtract(20, 'days'), currentValue: 42 },
    { createdAt: moment().subtract(19, 'days'), currentValue: 44 },
    { createdAt: moment().subtract(18, 'days'), currentValue: 46 },
    { createdAt: moment().subtract(17, 'days'), currentValue: 43 },
    { createdAt: moment().subtract(16, 'days'), currentValue: 47 },
    { createdAt: moment().subtract(15, 'days'), currentValue: 42 },
    { createdAt: moment().subtract(14, 'days'), currentValue: 40 },
    { createdAt: moment().subtract(13, 'days'), currentValue: 36 },
    { createdAt: moment().subtract(12, 'days'), currentValue: 34 },
    { createdAt: moment().subtract(11, 'days'), currentValue: 42 },
    { createdAt: moment().subtract(10, 'days'), currentValue: 51 },
    { createdAt: moment().subtract(9, 'days'), currentValue: 64 },
    { createdAt: moment().subtract(8, 'days'), currentValue: 71 },
    { createdAt: moment().subtract(7, 'days'), currentValue: 79 },
    { createdAt: moment().subtract(6, 'days'), currentValue: 86 },
    { createdAt: moment().subtract(5, 'days'), currentValue: 82 },
    { createdAt: moment().subtract(4, 'days'), currentValue: 78 },
    { createdAt: moment().subtract(3, 'days'), currentValue: 74 },
    { createdAt: moment().subtract(2, 'days'), currentValue: 65 },
    { createdAt: moment().subtract(1, 'days'), currentValue: 61 },
    { createdAt: moment(), currentValue: 56 }
]
export const THIRTY_DAY_JOURNAL_DATA = [
    { date: moment().subtract(30, 'days'), value: 2 },
    { date: moment().subtract(29, 'days'), value: 4 },
    { date: moment().subtract(28, 'days'), value: 4 },
    { date: moment().subtract(27, 'days'), value: 3 },
    { date: moment().subtract(26, 'days'), value: 5 },
    { date: moment().subtract(25, 'days'), value: 4 },
    { date: moment().subtract(24, 'days'), value: 5 },
    { date: moment().subtract(23, 'days'), value: 3 },
    { date: moment().subtract(22, 'days'), value: 4 },
    { date: moment().subtract(21, 'days'), value: 2 },
    { date: moment().subtract(20, 'days'), value: 1 },
    { date: moment().subtract(19, 'days'), value: 1 },
    { date: moment().subtract(18, 'days'), value: 2 },
    { date: moment().subtract(17, 'days'), value: 4 },
    { date: moment().subtract(16, 'days'), value: 3 },
    { date: moment().subtract(15, 'days'), value: 3 },
    { date: moment().subtract(14, 'days'), value: 2 },
    { date: moment().subtract(13, 'days'), value: 4 },
    { date: moment().subtract(12, 'days'), value: 5 },
    { date: moment().subtract(11, 'days'), value: 5 },
    { date: moment().subtract(10, 'days'), value: 4 },
    { date: moment().subtract(9, 'days'), value: 3 },
    { date: moment().subtract(8, 'days'), value: 2 },
    { date: moment().subtract(7, 'days'), value: 2 },
    { date: moment().subtract(6, 'days'), value: 1 },
    { date: moment().subtract(5, 'days'), value: 2 },
    { date: moment().subtract(4, 'days'), value: 3 },
    { date: moment().subtract(3, 'days'), value: 3 },
    { date: moment().subtract(2, 'days'), value: 2 },
    { date: moment().subtract(1, 'days'), value: 3 },
    { date: moment(), value: 5 }
]

export const fakeBioMarkers = [
    { bioType: 'steps', value: 4830, date: moment().subtract(1, 'days') },
    {
        bioType: 'totalminutesasleep',
        value: 600,
        date: moment().subtract(10, 'days')
    },
    { bioType: 'steps', value: 3249, date: moment().subtract(3, 'days') }
]
