import React from 'react'
import ReactDOM from 'react-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min'
import './index.css'
import App from './containers/App'
import { BrowserRouter } from 'react-router-dom'
import { MsalProvider } from '@azure/msal-react'
import { PublicClientApplication } from '@azure/msal-browser'
import { configuration } from './msftAuthConfig'
import Auth0ProviderWithHistory from './components/Auth/Auth0ProviderWithHistory'

const pca = new PublicClientApplication(configuration)

const AppProvider = () => (
    <MsalProvider instance={pca}>
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <App />
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    </MsalProvider>
)

ReactDOM.render(<AppProvider />, document.getElementById('root'))
