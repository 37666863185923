import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

// values must include userId and id (the ID of the goal being updated)
// updatedScore must be separate from the other values because the call requires it to be a number, not a string
const updateGoal = (values, email) => {
    const userEmail =
        email || JSON.parse(localStorage.getItem('state')).auth.user.email
    const newGoal = {
        userId: values.userId,
        id: values.id,
        name: values.name.toLowerCase(),
        direction: values.direction,
        targetDate: values.targetDate
    }
    const deliveryTime = values.deliveryTime.map(time => `"${time}"`).join(', ')
    const goalScale = values.goalScale
        .map(goal => `"${goal.toLowerCase()}"`)
        .join(', ')
    const basicEntries = Object.entries(newGoal)
        .map(val => `${val[0]}: "${val[1]}"`)
        .join(', ')
    const newValues =
        basicEntries +
        `, targetScore: ${values.targetScore}` +
        `, goalScale: [${goalScale}]` +
        `, deliveryTime: [${deliveryTime}]`
    const body = {
        query: print(gql`
        mutation {
            updateGoal(${newValues}) {
                id
                first
                last
                email
                role
                cognitoId
                acceptanceDate
                agreementInfo
                currentStatus
                currentStatusDate
                createdAt
                updatedAt
                textMessageStatus
                contactType
                phone
                invitation
                invitationDate
                organizationId
                goalDefinitions {
                    id
                    name
                    goalType
                    direction
                    goalScale
                    deliveryMethod
                    deliveryTime
                    targetDate
                    targetScore
                    createdAt
                    updatedAt
                    timeRemaining
                }
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'updateGoal')
}

export default updateGoal
