import React from 'react'
import styled from 'styled-components'
import { typographyMainColor, fontFamily } from '../../../constants'

const HeaderText = ({ children }) => {
    return <Header data-testid="headerText">{children}</Header>
}

HeaderText.defaultProps = {
    children: 'Default Text'
}
export default HeaderText

const Header = styled.p`
    font-size: 24px;
    font-family: ${fontFamily};
    font-weight: 600;
    margin: 0;
    color: ${typographyMainColor};
`
