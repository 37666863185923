import React, { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useAppContext } from '../../context/AppContext'
import * as style from './navUtils'
import { navRoutes } from '../../logic/navLogic'

const DashboardNav = ({ navOpen, setNavOpen }) => {
    const { state } = useAppContext()
    const { userType, user } = state.auth
    const { currentStatus, role } = user
    const history = useHistory()
    const location = useLocation()
    const [activePage, setActivePage] = useState(undefined)
    const [routes, setRoutes] = useState([])

    // Sets initial active page after component mounts
    useEffect(() => {
        !activePage && updatePage()
    }, [activePage])

    // This should catch any navigation routing done outside
    // of these nav links
    useEffect(() => {
        if (!window.location.href.includes(activePage)) updatePage()
    }, [location.pathname, activePage])

    // This will allow changing of displayed nav depending on release param in url
    // as well as filtering by superuser/patient/provider
    useEffect(() => {
        let filteredRoutes = navRoutes
        // filter by release state
        if (state.release !== 'alpha') {
            filteredRoutes = filteredRoutes.filter(item => !item.alphaOnly)
        }
        // For non-super users, filter further by userType (provider/patient)
        if (!role?.includes('superuser')) {
            filteredRoutes = filteredRoutes.filter(item =>
                item.roles?.includes(userType)
            )
        }

        setRoutes(filteredRoutes)
    }, [state.release, role, userType])

    const changePage = (routeName, activeRoute) => {
        setNavOpen(false)
        setActivePage(activeRoute)
        history.push(routeName)
    }

    const updatePage = () => {
        const url = window.location.href
        const route = navRoutes.filter(item => url.includes(item.url))[0]
        if (!route) return setActivePage('/dashboard')
        setActivePage(route.activePageRoute)
    }

    /*
    This function determines what items will appear in the navigation bar.
    Superusers will receive all items for easier management. This may be revised
    to be limited to developers or other administrative users where viewing of PHI
    must be limited.

    UPDATE - Refactored function to use an Array for routes
    Along with the specifics of which nav items are rendered
    No change in behavior
    12/29/20 RMC
     */
    const renderNavMenu = () => {
        return (
            <div>
                {routes.map((route, index) => {
                    return (
                        <style.StyledDiv
                            key={index}
                            onClick={() => {
                                changePage(route.url, route.activePageRoute)
                            }}
                            activePage={activePage === route.activePageRoute}
                            data-testid={'navLink'}
                        >
                            <style.StyledH6>{route.name}</style.StyledH6>
                        </style.StyledDiv>
                    )
                })}
            </div>
        )
    }

    if (
        !currentStatus ||
        currentStatus === 'firstLogin' ||
        currentStatus === 'pendingInvite'
    ) {
        return null
    }
    return (
        <style.Container open={navOpen} data-testid="DashboardNav">
            {renderNavMenu()}
        </style.Container>
    )
}

export default DashboardNav
