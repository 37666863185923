import React from 'react'
import { NoPatientList, TableBody } from '../Dashboard/dashboardUtils'
import {
    TableWrapper,
    StyledTable,
    StyledThead,
    StyledTr,
    StyledTh,
    StyledTbody
} from '../../utils/styledComponents'

export const PatientList = ({
    data,
    impersonation,
    org,
    setDeletePatient,
    disabled = false
}) => (
    <TableWrapper>
        <StyledTable>
            <StyledThead>
                <StyledTr>
                    <StyledTh>Name</StyledTh>
                    <StyledTh>Status</StyledTh>
                    <StyledTh>Role</StyledTh>
                    <StyledTh />
                </StyledTr>
            </StyledThead>
            <StyledTbody>
                {!data || data?.length === 0 ? (
                    <NoPatientList />
                ) : (
                    <TableBody
                        data={data}
                        impersonation={impersonation}
                        org={org}
                        setDeletePatient={setDeletePatient}
                        disabled={disabled}
                    />
                )}
            </StyledTbody>
        </StyledTable>
    </TableWrapper>
)
