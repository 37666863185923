import React from 'react'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faArrowUp,
    faArrowDown,
    faArrowRight
} from '@fortawesome/free-solid-svg-icons'
import {
    typographyMainColor,
    grayedOutMainColor,
    successStatusColor,
    dangerStatusColor,
    warningStatusColor
} from '../../constants'
import * as insights from '../../logic/insightsLogic'

export const Div = styled.div``

export const StyledInsightsWrapper = styled.div`
    ${({ view, width }) =>
        view === 'mobile'
            ? `
    margin: 0px;
    width: 100%;
    font-size: 16px;
    font-weight: 100;
    border-bottom: 1px solid ${grayedOutMainColor};
`
            : `
    width: ${width ? width : '100%'};
    border: 1px solid ${grayedOutMainColor};
    border-radius: 5px;
    padding: 20px;
    min-height: 100%;
`}
`

const StyledMainNumber = styled.div`
    ${({ color }) => `
    width:100%;
    text-align: center;
    font-size: 80px;
    font-weight: 700;
    color: ${color ? color : typographyMainColor};
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`}
`

const StyledRightValue = styled.div`
    ${({ color }) => `
    width: 100%;
    text-align: right;
    font-size: 32px;
    font-weight: 700;
    color: ${color ? color : typographyMainColor};
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`}
`

export const MutedSpan = styled.span`
    ${({ size }) => `
    color: ${grayedOutMainColor};
    font-size: ${size ? size : '15px'};
    font-weight: 400;
    white-space: nowrap;
    // text-transform: uppercase;
`}
`

const MutedText = styled.p`
    color: ${grayedOutMainColor};
    font-size: 15px;
    font-weight: 400;
    white-space: nowrap;
`

export const LowerMessage = styled.div`
    text-align: center;
    text-wrap: none;
`

export const TitleMessage = styled.div`
    color: ${typographyMainColor};
    // text-transform: uppercase;
    font-size: 15px;
    font-weight: 700;
    white-space: nowrap;
    text-align: right;
`

export const Loading = () => (
    <Div responsive hover>
        Loading...
    </Div>
)

export const NoData = () => (
    <Div responsive hover>
        No recent events.
    </Div>
)

export const MainNumber = ({
    value,
    view,
    subtitle = null,
    bioType = 'pain'
}) => {
    const color = insights.getInsightColor(value, bioType)
    value = Number(value).toFixed(2)

    return (
        <StyledMainNumber color={color} view={view}>
            {value}
            {subtitle ? <MutedText>{subtitle}</MutedText> : null}
        </StyledMainNumber>
    )
}

const arrowIcons = {
    up: faArrowUp,
    flatup: faArrowUp,
    flat: faArrowRight,
    flatdown: faArrowDown,
    down: faArrowDown
}

export const DirectionArrow = ({ direction, bioType = 'pain', size = 52 }) => {
    const colour = insights.getInsightArrow(direction, bioType)
    const ArrowIcon = arrowIcons[direction || 'up']
    return (
        <FontAwesomeIcon
            icon={ArrowIcon}
            style={{ color: colour, height: '52px', width: '52px' }}
        />
    )
}

// We made the executive decision to consolidate arrows/colors
// Down to a total of 3 for Score-related stuff
export const TrendArrow = ({ direction, size = 52 }) => {
    let Icon, color
    switch (direction) {
        case 'up':
        case 'flatup':
            color = successStatusColor
            Icon = faArrowUp
            break
        case 'down':
        case 'flatdown':
            color = dangerStatusColor
            Icon = faArrowDown
            break
        default:
            color = warningStatusColor
            Icon = faArrowRight
            break
    }

    return (
        <FontAwesomeIcon
            icon={Icon}
            style={{ color: color, size: '52px' }}
            data-testid="TrendArrow"
        />
    )
}

export const InsightRightSide = ({
    value,
    view,
    subtitle,
    bioType = 'pain',
    direction = null
}) => {
    const color = insights.getInsightColor(value, bioType)
    value = Number(value).toFixed(2)

    return (
        <StyledRightValue color={color} view={view}>
            {value}
            <MutedText style={{ textAlign: 'right' }}>{subtitle}</MutedText>
            {direction ? (
                <DirectionArrow direction={direction} bioType={bioType} />
            ) : null}
        </StyledRightValue>
    )
}
