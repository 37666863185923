import GoalDialogs from './goalDialogs'
import Org from './org'
import Token from './token'
import User from './user'
import Medications from './medications'
import Auth0 from './auth0'
import Emails from './emails'
import Settings from './settings'

const AlertApi = {
    ...GoalDialogs,
    ...Org,
    ...Token,
    ...User,
    ...Medications,
    ...Auth0,
    ...Emails,
    ...Settings
}

export default AlertApi
