import React from 'react'
import styled from 'styled-components'
import { grayedOutMainColor } from '../../../constants'
import { Error } from '../../../utils/styledComponents'
import FormLabel, { FormSubLabel } from './FormLabel'

// ...rest pass-ins (anything a standard input can take)
const FormInput = ({
    width,
    label,
    sublabel,
    error,
    type,
    useDropdown,
    options,
    testid,
    textAlign,
    ...rest
}) => {
    return (
        <MainContainer width={width} type={type} data-testid={testid}>
            <FormLabel textAlign={textAlign}>{label}</FormLabel>
            {sublabel && <FormSubLabel>{sublabel}</FormSubLabel>}
            {useDropdown ? (
                <Dropdown type="select" as="select" error={!!error} {...rest}>
                    {options.map((option, index) => (
                        <option
                            value={option.key}
                            key={index}
                            disabled={option.key === 'disabled'}
                        >
                            {option.value}
                        </option>
                    ))}
                </Dropdown>
            ) : (
                <Input type={type} error={!!error} {...rest} />
            )}
            <Error>{error}</Error>
        </MainContainer>
    )
}

FormInput.defaultProps = {
    width: undefined,
    label: 'Default Label',
    sublabel: undefined,
    touched: false,
    testid: 'form-input',
    useDropdown: false,
    options: [
        { key: 'you', value: 'you' },
        { key: 'forgot', value: 'forgot' },
        { key: 'options', value: 'options' }
    ]
}

export default FormInput

export const MainContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin: 5px 0;

    @media (min-width: 900px) {
        ${({ width }) => width && `width: ${width}`};
        margin: 0;
    }
`

export const Input = styled.input`
    width: 100%;
    border: 1px solid ${grayedOutMainColor};
    ${({ error }) => error && 'border-color: red'};
    border-style: solid !important;
    border-radius: 5px;

    @media (min-width: 900px) {
        font-weight: normal;
    }
`
const Dropdown = styled(Input)`
    height: 36px;
    text-align: left !important;
    text-align-last: left !important;
`
