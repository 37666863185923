import { BASE_ORG_ID } from '../../constants'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const removeOrgMember = variables => {
    const query = `
        mutation removeOrgMember(
            $id: String! 
            $organizationId: String! = "${BASE_ORG_ID}"
        ) {
            removeOrgMember(
                id: $id
                organizationId: $organizationId
            )
        }
    `

    const body = {
        query,
        variables
    }

    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email

    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'removeOrgMember')
}

export default removeOrgMember
