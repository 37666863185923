import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import header_logo from '../../../images/AlertFullLogo.png'

const LogoLink = ({ disabled, width, ...rest }) => {
    return (
        <MainContainer {...rest}>
            {disabled ? (
                <LogoImage src={header_logo} width={width} />
            ) : (
                <Link to={'/dashboard'} data-testid="logolink-link">
                    <LogoImage src={header_logo} width={width} />
                </Link>
            )}
        </MainContainer>
    )
}

export default LogoLink

LogoLink.defaultProps = {
    disabled: false,
    width: '200px'
}

const MainContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0;
`

const LogoImage = styled.img`
    width: ${({ width }) => width};
`
