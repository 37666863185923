import gql from 'graphql-tag'
import { print } from 'graphql'
import { filterStaff } from '../../utils/helperFunctions'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const listOrgs = () => {
    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email
    const body = {
        query: print(gql`
            query {
                listOrganizations {
                    id
                    name
                    address {
                        line1
                        line2
                        city
                        state
                        zip
                    }
                    users {
                        id
                        first
                        last
                        email
                        role
                        cognitoId
                        acceptanceDate
                        agreementInfo
                        currentStatus
                        currentStatusDate
                        createdAt
                        updatedAt
                        textMessageStatus
                        contactType
                        phone
                        invitation
                        invitationDate
                        goalDefinitions {
                            id
                            name
                            goalType
                            direction
                            goalScale
                            deliveryMethod
                            deliveryTime
                            targetDate
                            targetScore
                            createdAt
                            updatedAt
                        }
                        status {
                            id
                            userId
                            condition
                            reason
                            date
                            valid
                            createdAt
                            updatedAt
                        }
                    }
                }
            }
        `)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'listOrganizations')
        .then(result => {
            const allOrgs = []
            result.forEach(org => {
                allOrgs.push({
                    ...org,
                    patients: org.users.filter(user =>
                        user.role.includes('patient')
                    ),
                    users: filterStaff(org.users)
                })
            })
            return allOrgs
        })
        .catch(error => {
            throw error
        })
}

export default listOrgs
