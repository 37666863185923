const getStatus = status => {
    switch (status) {
        case 'firstLogin':
            return 'Creating Account'
        case 'pendingInvite':
            return 'System Invitation Unanswered'
        case 'active':
            return 'Active'
        case 'pendingActiveInvite':
            return 'Invitation Unanswered'
        case 'pendingOrgAcceptance':
            return 'Awaiting Response'
        case 'patientInvitationAccepted':
            return 'Organization Request Approved'
        case 'patientInviationDenied':
            return 'Organization Request Rejected'
        default:
            return status[0].toUpperCase() + status.substring(1)
    }
}

export default getStatus
