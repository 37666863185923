import React from 'react'
import { isValidPhoneNumber } from 'react-phone-number-input'
import { useAppContext } from '../../context/AppContext'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import PrimaryButton from '../../components/atoms/buttons/PrimaryButton'
import moment from 'moment'

const CreateGoalPopup = ({ noData, startDate, isViewingAsProvider }) => {
    const {
        state: {
            auth: { user }
        }
    } = useAppContext()
    const history = useHistory()

    const setAGoal = () => {
        if (!user?.phone || !isValidPhoneNumber(user?.phone))
            history.push('/goals/add_phone_number')
        else history.push('/goals/create')
    }

    const calculateDaysRemaining = () => {
        const end = moment(startDate).add(7, 'days')
        // adding 1 includes start date
        const daysRemaining = end.diff(moment(), 'days') + 1

        // In case something weird happens or date is beyond
        // the end date, return 0
        if (daysRemaining < 1 || isNaN(daysRemaining)) return 0

        return daysRemaining
    }

    return (
        <Container id="CreateGoalPopup">
            <TextDiv>
                {isViewingAsProvider ? (
                    <>
                        <Title>No Insights Available</Title>
                        <MainText>
                            The patient either does not have a goal set up or
                            does not have enough data to create insights. Please
                            check again later.
                        </MainText>
                    </>
                ) : noData ? (
                    <>
                        <Title>You've set a goal! Now what?</Title>
                        <MainText>
                            Once you've been tracking your goal for 7 days,
                            we'll have enough to establish a baseline of
                            information, and you'll see your goal progress here.
                        </MainText>
                        <MainText>
                            Check back in {calculateDaysRemaining()} days to see
                            your score.
                        </MainText>
                    </>
                ) : (
                    <>
                        <Title>Looks like you haven't set a goal.</Title>
                        <MainText>
                            Once you set a goal and start tracking your progress
                            toward it, we'll show you how you're doing here with
                            a goal score. You can change your goal in your
                            profile.
                        </MainText>
                        <PrimaryButton
                            text="Set a Goal"
                            width="fit-content"
                            onClick={setAGoal}
                            disabled={isViewingAsProvider}
                        />
                    </>
                )}
            </TextDiv>
        </Container>
    )
}

const Container = styled.div`
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5 !important;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
`

const TextDiv = styled.div`
    margin-top: 225px;
    height: 50%;
    background-color: white;
    border-radius: 5px;
    text-align: center;
    width: 50%;
    padding: 20px;
`

const Title = styled.p`
    font-size: 24px;
    font-weight: bolder;
`

const MainText = styled.p`
    font-size: 18px;
`

export default CreateGoalPopup
