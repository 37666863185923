import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getInsights = email => {
    const body = {
        query: print(gql`
        query {
            getUser(email: "${email}") {
            id
            currentStatus
            role
            goalDefinitions {
                id
                name
                goalType
                direction
                goalScale
                deliveryMethod
                deliveryTime
                targetDate
                targetScore
                createdAt
                updatedAt
                timeRemaining
              }
            goalsJournals {
                id
                date
                unit
                goalType
                goalId
                device
                value
                userId
                createdAt
                updatedAt
              }
              insightsSnapshot {
                id
                userId
                bioType
                currentValue
                average
                message
                insightType
                scoreData
                updatedAt
                createdAt
              }
            insightsTrend {
                id
                userId
                bioType
                direction
                currentValue
                previousValue
                message
                insightType
                updatedAt
                createdAt
              }
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(email)
    }

    return axiosCall(body, options, 'getUser')
}

export default getInsights
