import CreateOrg from './createOrg'
import GetOrg from './getOrg'
import ListOrgs from './listOrgs'
import ListOrgsBasic from './listOrgsBasic'
import removeOrgMember from './removeOrgMember'

const Org = {
    createOrg: CreateOrg,
    getOrg: GetOrg,
    listOrgs: ListOrgs,
    listOrgsBasic: ListOrgsBasic,
    removeOrgMember
}

export default Org
