import CreateToken from './createToken'
import DeleteToken from './deleteToken'
import UpdateToken from './updateToken'

const Token = {
    createToken: CreateToken,
    deleteToken: DeleteToken,
    updateToken: UpdateToken
}

export default Token
