import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FlexRowSB } from '../../utils/styledComponents'
import GoalProgressGraph from '../Graphs/GoalProgressGraph'
import SemiCircleGraph from '../Graphs/SemiCircleGraph'
import { determineGoalValue } from '../../logic/scoreLogic'
import MiniBarGraph from '../Graphs/MiniBarGraph'
import { ENV, modalTransBackground } from '../../constants'

const DashScoreSummary = ({
    goalDefinitions,
    insightsSnapshot,
    textMessageStatus
}) => {
    const goalName =
        goalDefinitions?.length > 0 ? goalDefinitions[0].name : undefined
    const score =
        insightsSnapshot?.length > 0
            ? Math.round(insightsSnapshot[0]?.currentValue)
            : undefined
    const goal =
        goalDefinitions?.length > 0
            ? determineGoalValue(insightsSnapshot)
            : undefined

    const barData =
        insightsSnapshot?.length > 0 && insightsSnapshot[0]?.scoreData
            ? JSON.parse(insightsSnapshot[0].scoreData.replace('\\', ''))
            : { consistency: 0, direction: { direction: 0 } }

    const responseValues =
        insightsSnapshot?.length > 0
            ? methods.getResponseValues(goalDefinitions, insightsSnapshot)
            : { label: '0%', value: 0 }

    const [showTextingAlert, setShowTextingAlert] = useState(false)

    // Set showTextingAlert according to the user's goal and textMessageStatus
    useEffect(() => {
        // If texting is disabled but the goal requires texting, show the alert
        if (
            goalDefinitions?.length > 0 &&
            goalDefinitions[0].deliveryMethod === 'text' &&
            textMessageStatus !== 'Enabled'
        ) {
            setShowTextingAlert(true)
        }

        // Else, don't show the alert
        else setShowTextingAlert(false)
    }, [textMessageStatus, goalDefinitions])

    return (
        <MainContainer id="DashScoreSummary" data-testid="DashScoreSummary">
            {showTextingAlert && (
                <TextingModal data-testid="TextingAlert">
                    <TextingCard>
                        <h2
                            style={{
                                fontSize: '30px',
                                fontWeight: 'bolder',
                                textAlign: 'center',
                                marginBottom: '20px'
                            }}
                        >
                            Please Enable Texting
                        </h2>
                        <p
                            style={{
                                fontSize: '18px',
                                textAlign: 'center'
                            }}
                        >
                            You have indicated that you do not want to receive
                            text messages from us, but your {goalName} goal is
                            set up to send text messages. Please update your
                            notification preference by texting the word START to{' '}
                            {ENV === 'prod'
                                ? '(513) 463-5363'
                                : '(513) 463-5970'}
                            .
                        </p>
                    </TextingCard>
                </TextingModal>
            )}
            <Row centerVertically mobileColumn>
                <SemiCircleGraph
                    goalName={goalName}
                    score={score}
                    goal={goal}
                />
                <MiniBarContainer>
                    <MiniBarGraph
                        type="consistency"
                        title="Response Consistency"
                        titleRight={methods.getResponseConsistencyText(
                            Math.abs(barData.consistency * 100)
                        )}
                        point={Math.min(
                            99.999,
                            Math.abs(barData.consistency * 100)
                        )}
                        max={100}
                    />
                    <MiniBarGraph
                        type="direction"
                        title="Trending Direction"
                        titleRight={methods.getTrendingDirectionText(
                            Math.abs(barData.direction.direction * 100) + 50
                        )}
                        point={Math.min(
                            99.999,
                            Math.abs(barData.direction.direction * 100) + 50
                        )}
                        max={100}
                    />
                    <MiniBarGraph
                        type="survey"
                        title="Response Values"
                        titleRight={responseValues.label}
                        point={responseValues.value}
                        max={100}
                    />
                </MiniBarContainer>
            </Row>
            <Row>
                <GoalProgressGraph
                    goal={goal}
                    scoreData={insightsSnapshot || undefined}
                />
            </Row>
        </MainContainer>
    )
}

export default DashScoreSummary

const MainContainer = styled.div`
    margin: 0 2%;
    @media (min-width: 900px) {
        margin: 0;
        margin-bottom: 5%;
    }
    position: relative;
`

const Row = styled(FlexRowSB)`
    @media (min-width: 900px) {
        height: 35vh;
    }
`
const MiniBarContainer = styled.div`
    margin: 0 20px 20px 2%;
    width: 50%;
    display: block;
    @media (max-width: 900px) {
        width: 100%;
        margin: 0 2%;
    }
`

const TextingCard = styled.div`
    padding: 15px;
    border-radius: 15px;
    background-color: white;
    @media (min-width: 900px) {
        width: 50%;
        min-width: 630px;
        padding: 75px 50px;
    }
`
const TextingModal = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1000;
    background-color: ${modalTransBackground};
`

export const methods = {
    getResponseValues: (goal, insights) => {
        const score = insights[0].average
        const x = (score / 5) * 100
        let value = x
        if (goal.direction === 'down') {
            value = 100 - x
        }
        const label = x.toFixed(1) + '%'
        return { label: label, value: value }
    },
    getTrendingDirectionText: score => {
        switch (true) {
            case score <= 40:
                return 'Be Alert'
            case score >= 70:
                return 'Ideal'
            case isNaN(score):
            default:
                return 'Average'
        }
    },
    getResponseConsistencyText: score => {
        switch (true) {
            case score <= 40:
                return 'Be Alert'
            case score >= 70:
                return 'Ideal'
            case isNaN(score):
            default:
                return 'Average'
        }
    }
}
