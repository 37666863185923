import {
    MSFT_SSO_CLIENT_ID,
    MSFT_SSO_TENANT_ID,
    MSFT_SSO_REDIRECT
} from './constants'
// import { LogLevel } from '@azure/msal-browser'

// MSAL configuration
export const configuration = {
    auth: {
        clientId: MSFT_SSO_CLIENT_ID,
        authority: `https://login.microsoftonline.com/${MSFT_SSO_TENANT_ID}`,
        redirect_uri: MSFT_SSO_REDIRECT
    },
    cache: {
        cacheLocation: 'sessionStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
    }
    // You can enable debug logging for MSFT SSO below
    // system: {
    //     loggerOptions: {
    //         loggerCallback: (level, message, containsPii) => {
    //             if (containsPii) {
    //                 return
    //             }
    //             switch (level) {
    //                 case LogLevel.Error:
    //                     console.error(message)
    //                     return
    //                 case LogLevel.Info:
    //                     console.info(message)
    //                     return
    //                 case LogLevel.Verbose:
    //                     console.debug(message)
    //                     return
    //                 case LogLevel.Warning:
    //                     console.warn(message)
    //                     return
    //             }
    //         }
    //     }
    // }
}

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = { scopes: ['User.Read', 'GroupMember.Read.All'] }

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me'
}
