import React, { useEffect } from 'react'
import moment from 'moment'
require('moment-timezone')
import DashboardHeadProfile from './DashboardHeadProfile'
import AlertApi from '../../apiCalls'
import * as utils from './headerUtils'
import { useAppContext } from '../../context/AppContext'
import {
    fitbitOauthAuthenticationUrl,
    ouraOauthAuthenticationUrl
} from '../../constants'
import LogoLink from '../atoms/links/LogoLink'
import PrimaryButton from '../atoms/buttons/PrimaryButton'
import TextLink from '../atoms/links/TextLink'

const url = {
    fitbit: fitbitOauthAuthenticationUrl,
    oura: ouraOauthAuthenticationUrl
}

/**
 * Currently this is only used in this component, but could be
 * potentially reused should the need arise.
 */
export const infoWithAction = ({
    message,
    action,
    buttonText,
    linkText,
    linkTo = '#'
}) => {
    return (
        <div>
            <p>{message}</p>
            <TextLink to={linkTo}>{linkText}</TextLink>
            <PrimaryButton onClick={action}>{buttonText}</PrimaryButton>
        </div>
    )
}

const DashboardHeader = ({ navOpen, setNavOpen }) => {
    const { state, dispatch } = useAppContext()
    const { user } = state.auth

    useEffect(() => {
        if (
            user.id !== null &&
            (user.timeZone?.name === null || !user.timeZone)
        )
            methods.setGuessedTimeZone(user, dispatch)
    }, [state.auth.user.id])

    return (
        user?.role &&
        user?.email && (
            <utils.Header>
                <LogoLink width="150px" data-testid="mainLogo" />
                <DashboardHeadProfile />
            </utils.Header>
        )
    )
}

export default DashboardHeader

export const methods = {
    // This function needs to move somewhere else since it's more of a dashboard
    // check and not part of the login process.
    oauthRedirect: (input, dispatch) => {
        dispatch({ type: 'SET_LOADING', value: true })

        return AlertApi.deleteToken(input.id)
            .then(() => {
                window.location.href = url[input.vendor]
                dispatch({ type: 'SET_LOADING', value: false })
            })
            .catch(error => {
                dispatch({ type: 'SET_LOADING', value: false })
            })
    },
    // Sets the user's time zone if they do not already have one saved
    setGuessedTimeZone: (user, dispatch) => {
        const guessed = moment.tz.zone(moment.tz.guess())

        // The timeZone object to send to the api call
        const timeZoneEntry = {
            name: guessed.name,
            abbreviations: [guessed.abbrs[0], guessed.abbrs[1]],
            offsets: [guessed.offsets[0] / 60, guessed.offsets[1] / 60]
        }

        // Update the user with the guessed time zone
        AlertApi.updateUser(
            { id: user.id },
            user.role,
            user.email,
            timeZoneEntry
        )
            .then(result => {
                dispatch({ type: 'UPDATE_USER', value: { ...result } })
            })
            .catch(err => {
                console.error('Unable to set time zone! ' + err.message)
            })
    }
}
