import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import * as comp from '../../utils/styledComponents'
import { LoginFooter } from '../../components/Layout/LoginFooter'
import PrimaryButton from '../../components/atoms/buttons/PrimaryButton'
import SubHeading from '../../components/atoms/headings/SubHeading'
import LogoLink from '../../components/atoms/links/LogoLink'

const Login = ({ location }) => {
    const { loginWithRedirect } = useAuth0()

    const LoginForm = () => (
        <comp.FullPageCard autoMargin>
            <comp.StyledBlurDiv>
                <LogoLink disabled={true} />
                <comp.StyledLoginWrapper>
                    <SubHeading text="Welcome to Alerts" />
                    <PrimaryButton
                        text="Start"
                        onClick={() =>
                            loginWithRedirect({
                                appState: {
                                    returnTo:
                                        location?.state?.from || '/dashboard'
                                }
                            })
                        }
                        id="loginButton"
                    />
                </comp.StyledLoginWrapper>
                <LoginFooter />
            </comp.StyledBlurDiv>
        </comp.FullPageCard>
    )
    return <LoginForm />
}

export default Login
