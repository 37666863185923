import moment from 'moment'

const averageValues = ({ avg, n }, d) => {
    if (!d.currentValue) {
        return { avg, n }
    }
    return {
        avg: (d.currentValue + n * avg) / (n + 1),
        n: n + 1
    }
}

const determineGoalValue = (data = []) => {
    if (!data || data.length === 0) return 0
    const init = { avg: 0, n: 0 }
    const average = data.reduce(averageValues, init).avg

    return Math.round(average)
}

const formatPearsonEntries = (graphDays, entries) => {
    /**
     * Prepares journal entries for correlating
     * Filters out old entries by graphDays, similar to biomarkers
     * Then formats for x, y graph value objects
     */
    const oldestDate = moment().subtract(graphDays, 'day').format('M/DD/YYYY')

    const filteredEntries = entries.filter(entry =>
        moment(entry.date).isAfter(oldestDate, 'day')
    )

    return filteredEntries.map(entry => ({
        x: moment(entry.date).format('M/DD'),
        y: entry.value
    }))
}

const findRelevantDatasets = (a, b) => {
    /**
     * Both a and b are arrays of {x: date, y: value}.
     *
     * Filters out and returns matching length arrays with their rounded int values only
     */
    const aValues = []
    const bValues = []

    // Loop array a
    a.map(item => {
        // Look for matching date "x" in array b
        const match = b.find(thing => thing.x === item.x)
        // If match is found, push the a.y and b.y values into respective return arrays
        if (match) {
            aValues.push(item.y)
            bValues.push(match.y)
        }
    })

    return { aValues, bValues }
}

const pearsonCorrelation = (x, y) => {
    /**
     * Both x and y are arrays of numbers.
     *
     * Calculate Pearson correlation coefficent of arrays of equal length.
     * Numerator is sum of the multiplication of (x - x_avg) and (y - y_avg).
     * Denominator is the squart root of the product between the sum of
     * (x - x_avg)^2 and the sum of (y - y_avg)^2.
     */
    if (x.length < 3 || y.length < 3) {
        return { value: 'N/A', text: 'Not enough data' }
    }
    const n = x.length
    const idx = Array.from({ length: n }, (z, i) => i)

    // Averages
    const avgX = x.reduce((a, b) => a + b) / n
    const avgY = y.reduce((a, b) => a + b) / n

    const numMult = idx.map(i => (x[i] - avgX) * (y[i] - avgY))
    const numerator = numMult.reduce((a, b) => a + b)

    const denomX = idx
        .map(i => Math.pow(x[i] - avgX, 2))
        .reduce((a, b) => a + b)
    const denomY = idx
        .map(i => Math.pow(y[i] - avgY, 2))
        .reduce((a, b) => a + b)
    const denominator = Math.sqrt(denomX * denomY)

    const correlation = numerator / denominator

    const txt = getPearsonCorrelationText(correlation)

    return { value: correlation.toFixed(2), text: txt }
}

const getPearsonCorrelationText = correlation => {
    let text = ''
    if (correlation >= -0.1 && correlation <= 0.1) {
        text = 'None'
    } else if (correlation >= -0.45 && correlation <= 0.45) {
        text = 'Weak'
    } else if (correlation >= -0.85 && correlation <= 0.85) {
        text = 'Moderate'
    } else {
        text = 'Strong'
    }

    if (correlation < 0 && text !== 'None') {
        text += ' negative'
    } else if (correlation > 0 && text !== 'None') {
        text += ' positive'
    }

    return text
}

export {
    averageValues,
    determineGoalValue,
    formatPearsonEntries,
    findRelevantDatasets,
    pearsonCorrelation,
    getPearsonCorrelationText
}
