import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getSetting = value => {
    const userEmail = 'internalsystem@adyptation.com'
    const body = {
        query: print(gql`
        query {
            getSetting(id: "${value}") {
                id
                value
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'getSetting')
}

export default getSetting
