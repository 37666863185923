import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const createOrg = (values, validated, email) => {
    const userEmail =
        email || JSON.parse(localStorage.getItem('state')).auth.user.email
    let newValues = Object.entries(values)
        .map(val => `${val[0]}: "${val[1]}"`)
        .join(', ')
    newValues += `, validated: ${validated}`
    const body = {
        query: print(gql`
        mutation {
            createOrganization(${newValues}){
            id
            name
            entity
            address {
                line1
                line2
                city
                state
                zip
                validated
            }
            createdAt
            updatedAt
            }
        }`)
    }

    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'createOrganization')
}

export default createOrg
