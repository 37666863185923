export const navRoutes = [
    {
        name: 'Dashboard',
        url: '/dashboard',
        activePageRoute: '/dashboard',
        roles: ['patient', 'provider', 'superuser'],
        alphaOnly: false
    },
    // {
    //     name: 'Trends',
    //     url: '/trends',
    //     activePageRoute: '/trends',
    //     roles: ['patient', 'superuser'],
    //     alphaOnly: false,
    // },
    {
        name: 'Patients',
        url: '/patients',
        activePageRoute: '/patients',
        roles: ['provider', 'superuser'],
        alphaOnly: false
    },
    {
        name: 'Invitations',
        url: '/invitations',
        activePageRoute: '/invitations',
        roles: ['provider', 'superuser'],
        alphaOnly: false
    },
    {
        name: 'Staff',
        url: '/staff',
        activePageRoute: '/staff',
        roles: ['provider', 'superuser'],
        alphaOnly: false
    }
]
