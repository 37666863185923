import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import * as utils from './headerUtils'
import { useAppContext } from '../../context/AppContext'
import { Card, ListGroup, OverlayTrigger } from 'react-bootstrap'
import Loading from '../Loading'
import { useHistory } from 'react-router-dom'
import { FFGate } from '@adyptation/feature-flags'

const DashboardHeadProfile = () => {
    const { state } = useAppContext()
    const { user, userType } = state.auth
    const [showMenu, setShowMenu] = useState(false)
    const [loggingOut, setLoggingOut] = useState(false)
    const [initials, setInitials] = useState('')
    const history = useHistory()
    const { logout } = useAuth0()

    const toggleMenu = e => {
        if (e.target.dataset.testid === 'initialsCircle') setShowMenu(!showMenu)
        else setShowMenu(false)
    }

    useEffect(() => {
        window.addEventListener('click', toggleMenu)
        return () => {
            window.removeEventListener('click', toggleMenu)
        }
    }, [])

    const logoutNow = () => {
        setLoggingOut(true)
        localStorage.clear()
        logout({
            returnTo: window.location.origin
        })
    }

    const personalMenuOverlay = () => {
        const changePage = route => {
            setShowMenu(false)
            history.push(route)
        }

        return (
            <utils.MenuHolder data-testid="personalMenuOverlay">
                <Card>
                    <ListGroup variant="flush">
                        {user.currentStatus !== 'firstLogin' &&
                            user.currentStatus !== 'pendingInvite' && (
                                <>
                                    <utils.CardBody>
                                        <utils.CardText
                                            onClick={() =>
                                                userType === 'patient'
                                                    ? changePage(
                                                          '/patient_profile_info/overview'
                                                      )
                                                    : changePage(
                                                          '/provider_profile_info/overview'
                                                      )
                                            }
                                        >
                                            Profile
                                        </utils.CardText>
                                    </utils.CardBody>
                                    <FFGate featureName="settings">
                                        <utils.CardBody data-testid="settingsLink">
                                            <utils.CardText
                                                onClick={() =>
                                                    changePage('/settings')
                                                }
                                            >
                                                Settings
                                            </utils.CardText>
                                        </utils.CardBody>
                                    </FFGate>

                                    {user?.role?.includes('superuser') && (
                                        <utils.CardBody
                                            key="superuser"
                                            data-testid="superuserLink"
                                        >
                                            <utils.CardText
                                                onClick={() =>
                                                    changePage('/superuser')
                                                }
                                            >
                                                Super User
                                            </utils.CardText>
                                        </utils.CardBody>
                                    )}
                                </>
                            )}
                        <utils.CardBody>
                            <utils.CardText onClick={logoutNow}>
                                Log Out
                            </utils.CardText>
                        </utils.CardBody>
                    </ListGroup>
                </Card>
            </utils.MenuHolder>
        )
    }

    useEffect(() => {
        // Our initials may not be available immediately on login so we have to
        // be patient and wait for the state to update and fill in the initials
        // when we can. We default to a blank, no initials, circle while loading up.
        const { first, last } = user?.id ? user : { first: '', last: '' }
        setInitials(
            first?.charAt(0).toUpperCase() +
                (last?.charAt(0).toUpperCase() || '')
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, userType])

    return (
        <div data-testid="dashboardHeadProfile">
            <OverlayTrigger
                trigger="click"
                placement="bottom"
                show={showMenu}
                overlay={personalMenuOverlay()}
                rootClose
                transition
            >
                <utils.InitialsCircle
                    className="initials_circle"
                    // onClick={() => setShowMenu(!showMenu)}
                    data-testid="initialsCircle"
                >
                    {initials}
                </utils.InitialsCircle>
            </OverlayTrigger>
            {loggingOut && <Loading displayText="Logging out..." />}
        </div>
    )
}

export default DashboardHeadProfile
