import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { primaryMainColor, buttonBackgroundHover } from '../../../constants'

const TextLink = ({ text, to, children, ...rest }) => (
    <MainLink to={to} {...rest}>
        {children || text}
    </MainLink>
)

TextLink.defaultProps = {
    text: 'Log in',
    to: '/'
}
export default TextLink

const MainLink = styled(Link)`
    color: ${primaryMainColor};

    :hover {
        text-decoration: none;
        color: ${buttonBackgroundHover} !important;
    }
`
