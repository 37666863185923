import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const listOrgsBasic = () => {
    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email
    const body = {
        query: print(gql`
            query {
                listOrganizations {
                    id
                    name
                    address {
                        line1
                        line2
                        city
                        state
                        zip
                    }
                }
            }
        `)
    }

    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'listOrganizations')
}

export default listOrgsBasic
