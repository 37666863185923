import styled from 'styled-components'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
    buttonBackgroundColor,
    primaryMainColor,
    grayedOutMainColor
} from '../../constants'

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 72px;
    padding: 0 10px;
    border-bottom: 1px solid ${grayedOutMainColor};

    @media (min-width: 900px) {
        padding: 0 20px;
    }
`

export const CardLink = styled(Link)`
    padding: 0.75rem 1.25rem;
    text-decoration: none !important;
    color: ${primaryMainColor} !important;
`

export const CardText = styled.p`
    margin: 0;
    color: ${primaryMainColor} !important;
    cursor: pointer;
`

export const CardBody = styled.div`
    padding: 0.5rem 1.25rem;
`

export const InitialsCircle = styled(Button)`
    border-radius: 50%;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 34px;
    width: 34px;
    background-color: ${buttonBackgroundColor};
    border-color: ${buttonBackgroundColor};
    color: white;
    font-weight: 700;
    font-size: 13px;
`

export const MenuHolder = styled.div`
    text-align: center;
    padding-top: 10px;
    z-index: 1001;
`

export const HamburgerMenu = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    width: 34px;
    height: 34px;
    font-weight: 700;
    background-color: ${buttonBackgroundColor};
    border-color: ${buttonBackgroundColor};
    border-radius: 2px;
    color: white;
    cursor: pointer;

    @media (min-width: 900px) {
        display: none;
    }
`
