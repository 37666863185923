import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getOrg = (type, value, email) => {
    const userEmail =
        email || JSON.parse(localStorage.getItem('state')).auth.user.email
    const body = {
        query: print(gql`
        query {
            getOrganization(${type}: "${value}") {
                id
                name
                users {
                    id
                    first
                    last
                    email
                    role
                    phone
                    contactType
                    invitation
                    invitationDate
                    currentStatus
                    status {
                        id
                        userId
                        condition
                        reason
                        date
                        valid
                        createdAt
                        updatedAt
                    }
                }
                address {
                    line1
                    line2
                    city
                    state
                    zip
                }
                audits {
                    id
                    date
                    message
                }
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'getOrganization')
}

export default getOrg
