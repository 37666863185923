import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const changeEmail = (newEmail, email) => {
    const newValues = `newEmail: "${newEmail}", email: "${email}"`
    const body = {
        query: print(gql`
        mutation {
            changeEmail(${newValues}) {
                id
                email
                first
                pendingEmail
                previousEmail
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(email)
    }

    return axiosCall(body, options, 'changeEmail')
}

export default changeEmail
