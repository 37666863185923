import React from 'react'
import * as comp from '../../utils/styledComponents'

const PatientsForm = ({ searchName, unfilter, handleChange }) => {
    return (
        <comp.FlexRow centerVertically mobileColumn>
            <comp.NameInput
                name="searchName"
                onChange={handleChange}
                value={searchName}
                placeholder="&#x1F50E; Search for patients"
            />
            <comp.AlertButton
                variant="link"
                onClick={unfilter}
                style={{ fontSize: '16px' }}
            >
                Clear Filter
            </comp.AlertButton>
        </comp.FlexRow>
    )
}

export default PatientsForm
