import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getDevices = (email, patientEmail) => {
    const searchEmail = patientEmail || email

    const body = {
        query: print(gql`
        query {
            getUser(email: "${searchEmail}") {
                id
                oauthTokens {
                accessToken
                createdAt
                expiresAt
                id
                scope
                updatedAt
                valid
                vendor
              }
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(email)
    }

    return axiosCall(body, options, 'getUser')
        .then(result => result.oauthTokens)
        .catch(error => {
            throw error
        })
}

export default getDevices
