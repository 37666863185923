import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
    VictoryAxis,
    VictoryScatter,
    VictoryChart,
    VictoryLine,
    VictoryArea,
    VictoryTooltip
} from 'victory'
import FlyOut from './flyOut'
import HeaderText from '../atoms/text/HeaderText'
import {
    primaryMainColor,
    graphFill,
    fontFamily,
    graphLabels,
    alertsPrimary
} from '../../constants'
import {
    findRelevantDatasets,
    pearsonCorrelation
} from '../../logic/scoreLogic'

const axisStyles = {
    axis: { stroke: graphFill },
    tickLabels: {
        fill: graphLabels,
        fontSize: '7px',
        fontFamily
    },
    grid: {
        stroke: graphFill,
        opacity: 0.5,
        strokeWidth: 1
    }
}

// Graph used on main dashboard to show steps and sleep with time parameter
const DeviceDayGraph = ({
    type,
    title,
    markers,
    max,
    insights,
    showCorrelation
}) => {
    const tabletSize = 200
    const desktopSize = 100
    const [graphSize, setGraphSize] = useState(desktopSize)
    // sets line chart to a smoother version
    const interpolation = 'catmullRom'
    // adds symbol to array of markers for use with scatter plot
    const scatterData = markers.map(mark => ({ ...mark, symbol: 'circle' }))

    // Pearson related calculations
    const pearsonDataSets = findRelevantDatasets(markers, insights)
    const pearsonData = pearsonCorrelation(
        pearsonDataSets.aValues,
        pearsonDataSets.bValues
    )

    const resizeGraph = () => {
        const width = window.innerWidth
        if (width < 900) {
            setGraphSize(tabletSize)
        }
    }

    useEffect(() => {
        resizeGraph()
    }, [])

    return (
        <GraphContainer
            id={`${type}DeviceDayGraph`}
            data-testid={`${type}DeviceDayGraph`}
        >
            <HeaderText>{title}</HeaderText>
            <VictoryChart
                padding={{ top: 10, bottom: 50, left: 50, right: 50 }}
                height={graphSize}
            >
                <VictoryAxis fixLabelOverlap style={axisStyles} />
                <VictoryAxis dependentAxis fixLabelOverlap style={axisStyles} />
                <VictoryLine
                    style={{
                        data: { stroke: primaryMainColor, strokeWidth: 1 }
                    }}
                    interpolation={interpolation}
                    data={markers}
                    domain={{ y: [0, max * 1.1] }}
                />
                <VictoryArea
                    style={{
                        data: { fill: primaryMainColor, opacity: 0.3 }
                    }}
                    interpolation={interpolation}
                    data={markers}
                    domain={{ y: [0, max * 1.1] }}
                />
                <VictoryScatter
                    style={{
                        data: {
                            fill: primaryMainColor,
                            stroke: 'white',
                            strokeWidth: 1
                        }
                    }}
                    data={scatterData}
                    size={1.6}
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip
                            flyoutComponent={<FlyOut type={type} />}
                        />
                    }
                />
            </VictoryChart>
            {showCorrelation && (
                <CorrelationText>
                    Correlation: {pearsonData.value} {pearsonData.text}
                </CorrelationText>
            )}
        </GraphContainer>
    )
}

DeviceDayGraph.defaultProps = {
    insights: [],
    markers: []
}

export default React.memo(DeviceDayGraph)

const GraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;

    @media (min-width: 900px) {
        margin: 30px 0;
    }
`

const CorrelationText = styled.p`
    color: ${alertsPrimary};
`
