import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const createMed = values => {
    const email = JSON.parse(localStorage.getItem('state')).auth.user.email
    const newValues = Object.entries(values)
        .map(val => `${val[0]}: "${val[1]}"`)
        .join(', ')
    const body = {
        query: print(gql`
            mutation {
                addMedication(${newValues}) {
                    id
                    first
                    last
                    email
                    medications {
                        id
                        nickname
                        brandName
                        genericName
                        medicationId
                        dosage
                        frequency
                        startDate
                        stopDate
                        createdAt
                        updatedAt
                    }
                }
            }
        `)
    }
    const options = {
        headers: getDefaultHeaders(email)
    }

    return axiosCall(body, options, 'addMedication')
}

export default createMed
