import React, { useEffect } from 'react'
import moment from 'moment'
import PatientTable from '../../components/Patients/PatientTable'
import * as comp from '../../utils/styledComponents'
import * as utils from '../../components/Dashboard/insightsUtils'
import InsightsBox from '../../components/Dashboard/InsightsBox'
import ProviderFirstLogin from '../Registration/ProviderFirstLogin'
import { BASE_ORG_ID, grayedOutMainColor } from '../../constants'
import { useAppContext } from '../../context/AppContext'
import Loading from '../../components/Loading'

const OriginalOverviewContainer = ({
    user,
    view,
    organization,
    isAuthenticated
}) => {
    return (
        <>
            <comp.StyledDiv
                data-testid="DashboardProviderContainer"
                style={{
                    padding: view === 'mobile' ? '0' : '30px 50px',
                    border: 'none',
                    flex: '80%'
                }}
            >
                <comp.StyledCol>
                    {user.currentStatus !== 'firstLogin' && (
                        <comp.StyledHeader>
                            {user.first && `Welcome back, ${user.first}.`}
                        </comp.StyledHeader>
                    )}
                    {organization.id === BASE_ORG_ID ? (
                        <ProviderFirstLogin />
                    ) : (
                        organization && <PatientTable />
                    )}
                </comp.StyledCol>
            </comp.StyledDiv>
            {!organization && isAuthenticated !== undefined && (
                <Loading
                    displayText={
                        isAuthenticated
                            ? 'Retrieving organization info...'
                            : 'Retrieving profile...'
                    }
                />
            )}
        </>
    )
}

const DashboardPainLeft = props => {
    // This static data is for testing only and should be replaced with live data. -seh
    const data = {
        current: 2.81,
        bioType: 'pain'
    }

    data.message = '12 of 15 patients reporting.'
    data.mainsubtitle = 'Average Reported'
    data.title = 'Overall Average (Pain)'
    data.rightsubtitle = '3-day average'
    data.singlewide = true

    props = { ...props, ...data }
    return <InsightsBox {...props} />
}

const DashboardPainMiddle = props => {
    // This static data is for testing only and should be replaced with live data. -seh
    const data = {
        current: 3.87,
        average: 2.04,
        direction: 'up',
        message: '',
        bioType: 'pain'
    }

    data.message = '13 of 15 patients reporting.'
    data.mainsubtitle = '7-day average'
    data.title = 'Overall Trends (Pain)'
    data.rightsubtitle = 'Last Week'
    data.singlewide = false

    props = { ...props, ...data }
    return <InsightsBox {...props} />
}

const DashboardPainRight = props => {
    const data = { title: 'Patient Trends (Pain)' }

    props = { ...props, ...data }
    const iconSize = 24
    const mutedFontSize = '16px'
    // This needs some serious work
    return (
        <utils.Div data-id="insight" {...props}>
            <utils.StyledInsightsWrapper view={props.view}>
                {props.title ? (
                    <utils.TitleMessage>{props.title}</utils.TitleMessage>
                ) : null}
                <utils.Div
                    style={{
                        flexDirection: 'column',
                        display: 'flex',
                        fontSize: '24px'
                    }}
                >
                    <utils.Div>
                        3{' '}
                        <utils.DirectionArrow direction="up" size={iconSize} />{' '}
                        <utils.MutedSpan size={mutedFontSize}>
                            Up
                        </utils.MutedSpan>
                    </utils.Div>
                    <utils.Div>
                        2{' '}
                        <utils.DirectionArrow
                            direction="flatup"
                            size={iconSize}
                        />{' '}
                        <utils.MutedSpan size={mutedFontSize}>
                            Slightly up
                        </utils.MutedSpan>
                    </utils.Div>
                    <utils.Div
                        style={{ whiteSpace: 'nowrap', flexDirection: 'row' }}
                    >
                        5{' '}
                        <utils.DirectionArrow
                            direction="flat"
                            size={iconSize}
                        />{' '}
                        <utils.MutedSpan size={mutedFontSize}>
                            No change
                        </utils.MutedSpan>
                    </utils.Div>
                    <utils.Div>
                        1{' '}
                        <utils.DirectionArrow
                            direction="flatdown"
                            size={iconSize}
                        />{' '}
                        <utils.MutedSpan size={mutedFontSize}>
                            Slightly down
                        </utils.MutedSpan>
                    </utils.Div>
                    <utils.Div>
                        4{' '}
                        <utils.DirectionArrow
                            direction="down"
                            size={iconSize}
                        />{' '}
                        <utils.MutedSpan size={mutedFontSize}>
                            Down
                        </utils.MutedSpan>
                    </utils.Div>
                </utils.Div>
            </utils.StyledInsightsWrapper>
        </utils.Div>
    )
}

const EventRow = props => {
    var date = moment(props.data.createdAt).format('MM/DD/YYYY hh:mm a')

    return (
        <utils.Div
            style={{
                display: 'flex',
                flexDirection: 'column',
                padding: '8px',
                borderBottom: '1px solid ' + grayedOutMainColor
            }}
        >
            <utils.Div style={{ width: '100%' }}>{date}</utils.Div>
            <utils.Div
                style={{
                    width: '100%',
                    flexDirection: 'row',
                    display: 'flex'
                }}
            >
                <utils.Div style={{ width: '25%' }}>
                    {props.data.name}
                </utils.Div>
                <utils.Div style={{ width: '75%' }}>
                    {props.data.message}
                </utils.Div>
            </utils.Div>
        </utils.Div>
    )
}

const EventTable = props => {
    var data = props.data

    var inside_data = []
    if (data === 'undefined' || data.length === 0) {
        inside_data = <utils.Div>No recent insights.</utils.Div>
    } else {
        inside_data = []
        data.map(item =>
            inside_data.push(<EventRow key={item.id} data={item} />)
        )
    }

    return (
        <utils.Div responsive hover>
            {inside_data}
        </utils.Div>
    )
}

const TeamInsights = props => {
    const data = [
        {
            id: 123,
            name: 'Andy Bernard',
            message: 'Pain has decreased by 24% in the last 7 days.',
            createdAt: '2020-11-11T12:34:43'
        },
        {
            id: 234,
            name: 'Angela Martin',
            message: 'No pain reported for 14 days.',
            createdAt: '2020-11-11T01:23:24'
        }
    ]

    return (
        <utils.Div style={{ width: '100%' }}>
            {props.title ? (
                <comp.StyledHeader>{props.title}</comp.StyledHeader>
            ) : null}
            <EventTable data={data} data-testid="TeamInsights" />
        </utils.Div>
    )
}

const BetaOverviewContainer = ({ view, release, user }) => (
    <comp.StyledDiv
        data-testid="DashboardProviderContainer"
        style={{
            padding: view === 'mobile' ? '0' : '30px 50px',
            border: release === 'alpha' ? '3px solid orange' : 'none',
            flex: '80%'
        }}
    >
        {user.currentStatus === 'firstLogin' ? (
            <>
                {/* <comp.StyledHeader>
                    {user.first ? `Welcome back, ${user.first}.` : 'Loading...'}
                </comp.StyledHeader> */}
                <ProviderFirstLogin />
            </>
        ) : (
            <>
                <comp.InsideDiv
                    style={{
                        borderBottom: '1px solid lightgray',
                        display: 'flex',
                        flexDirection: view === 'mobile' ? 'column' : 'row'
                    }}
                    data-id="insightrow"
                >
                    <DashboardPainLeft
                        view={view}
                        style={{ marginRight: '20px', width: '30%' }}
                    />
                    <DashboardPainMiddle view={view} style={{ width: '30%' }} />
                    <DashboardPainRight
                        view={view}
                        style={{ marginLeft: '20px', width: '30%' }}
                    />
                </comp.InsideDiv>
                <comp.InsideDiv
                    view={view}
                    style={{
                        borderBottom: '1px solid lightgray',
                        display: 'flex',
                        flexDirection: view === 'mobile' ? 'column' : 'row'
                    }}
                    data-id="eventsrow"
                >
                    <TeamInsights
                        view={view}
                        data-testid="TeamInsights"
                        title="Insights"
                    />
                </comp.InsideDiv>
            </>
        )}
    </comp.StyledDiv>
)

const DashboardProviderContainer = () => {
    const { state } = useAppContext()
    const { organization, user, isAuthenticated } = state.auth

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    // Sanity check to make sure we aren't leaking things being tested.
    if (state.release !== 'release') {
        return (
            <BetaOverviewContainer
                view={state.viewType}
                release={state.release}
                user={user}
            />
        )
    }

    return (
        <OriginalOverviewContainer
            user={user}
            view={state.viewType}
            organization={organization}
            isAuthenticated={isAuthenticated}
        />
    )
}

export default DashboardProviderContainer
