import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { StyledTr, StyledTd } from '../../utils/styledComponents'
import { errorColor, agreementBorder } from '../../constants'
import getStatus from '../../utils/getStatus'

export const StyledLink = styled(Link)`
    color: black;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`

export const NoPatientList = ({ totalUsers }) => (
    <>
        <br />
        <p>
            {totalUsers === 0
                ? 'Your organization currently has no patients.'
                : 'No patient with that name exists.'}
        </p>
    </>
)

export const TableBody = ({
    data,
    impersonation,
    org,
    setDeletePatient,
    disabled
}) => {
    if (data === false || data.length === 0) {
        return <tr></tr>
    } else {
        return data.map(info => (
            <TableData
                key={info.id}
                data={info}
                impersonation={impersonation}
                org={org}
                setDeletePatient={setDeletePatient}
                disabled={disabled}
            />
        ))
    }
}

export const TableData = ({
    data,
    impersonation,
    org,
    setDeletePatient,
    disabled
}) => {
    const pathname = impersonation
        ? `${org.id}/profile/${data.id}`
        : `/profile/${data.id}`

    const indivLink = displayText => {
        return (
            <StyledLink
                onClick={() =>
                    localStorage.setItem('patient', JSON.stringify(data))
                }
                to={{
                    pathname,
                    props: { data },
                    state: { patient_id: data.id }
                }}
            >
                {displayText}
            </StyledLink>
        )
    }

    return (
        <StyledTr key={data.id}>
            <StyledTd>{indivLink(`${data.last}, ${data.first}`)}</StyledTd>
            <StyledTd>{getStatus(data.currentStatus)}</StyledTd>
            <StyledTd>Patient</StyledTd>
            {setDeletePatient && (
                <StyledTd style={{ paddingLeft: '0', paddingRight: '0' }} left>
                    <button
                        style={{
                            border: 'none',
                            backgroundColor: 'white',
                            color: disabled ? agreementBorder : errorColor,
                            cursor: disabled ? 'not-allowed' : 'pointer'
                        }}
                        onClick={
                            disabled ? null : () => setDeletePatient({ data })
                        }
                    >
                        Delete
                    </button>
                </StyledTd>
            )}
        </StyledTr>
    )
}

export const formatName = name => name.replace(/\s/g, '').trim().toLowerCase()

export const filterByName = (allItems, name) =>
    allItems.filter(item =>
        `${item.last.toLowerCase()}, ${item.first.toLowerCase()}`.includes(name)
    )
