import React, { createContext, useContext, useReducer } from 'react'

// We want to limit this state to the minimum possible items
// to keep our memory footprint small and force us to think
// through _why_ the particular item needs to be global in context.
export const regState = {
    name: '',
    entity: '',
    agreeterms: false,
    address: {
        line1: '',
        line2: '',
        city: '',
        state: '',
        zip: ''
    }
}

const reducer = (current, action) => {
    const { type, value } = action
    let newState

    switch (type) {
        case 'UPDATE_ORG':
            newState = {
                ...current,
                ...value
            }
            break
        case 'UPDATE_ADDRESS':
            newState = {
                ...current,
                address: {
                    ...current.address,
                    ...value
                }
            }
            break
        default:
            newState = current
    }

    localStorage.setItem('orgReg', JSON.stringify(newState))
    return newState
}

// If at all possible gather our state from local storage in the browser.
// If there is nothing there, we go with the context defaults
const existingState = JSON.parse(localStorage.getItem('orgReg'))
const currentState = { ...regState, ...existingState }
const OrgRegistrationContext = createContext(currentState)

export const OrgRegistrationProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, currentState)

    return (
        <OrgRegistrationContext.Provider value={{ state, dispatch }}>
            {children}
        </OrgRegistrationContext.Provider>
    )
}

export const useOrgRegistrationContext = () =>
    useContext(OrgRegistrationContext)
