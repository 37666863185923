import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const updateUser = (values, role, email, timeZone = undefined) => {
    const userEmail =
        email || JSON.parse(localStorage.getItem('state')).auth.user.email
    const roles = role.map(r => `"${r}"`).join(', ')
    let newValues =
        Object.entries(values)
            .map(val => `${val[0]}: "${val[1]}"`)
            .join(', ') + `, role: [${roles}]`
    if (timeZone)
        newValues =
            newValues +
            `, timeZoneName: "${timeZone.name}", timeZoneAbbreviations: ["${timeZone.abbreviations[0]}", "${timeZone.abbreviations[1]}"], timeZoneOffsets: [${timeZone.offsets[0]}, ${timeZone.offsets[1]}]`
    const body = {
        query: print(gql`
        mutation {
            updateUser(${newValues}) {
                id
                first
                last
                email
                role
                cognitoId
                acceptanceDate
                agreementInfo
                currentStatus
                currentStatusDate
                createdAt
                updatedAt
                textMessageStatus
                contactType
                phone
                invitation
                invitationDate
                timeZone {
                    name
                    abbreviations
                    offsets
                }
                goalDefinitions {
                    id
                    name
                    goalType
                    direction
                    goalScale
                    deliveryMethod
                    deliveryTime
                    targetDate
                    targetScore
                    createdAt
                    updatedAt
                }
                medicationTracking
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'updateUser')
}

export default updateUser
