import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import './greyout.css'

// ...rest pass-ins, anything checkbox input would use
const GreyOutDiv = ({ children }) => (
    <MainContainer data-testid="GreyOutDiv">
        <ShimmerWrapper>
            <GreyedOutDiv className="shimmer" />
        </ShimmerWrapper>
        {children}
    </MainContainer>
)

export default GreyOutDiv

const MainContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 100%;
    height: 100%;
    text-align: center;
    @media (min-width: 900px) {
        margin: 0;
    }
`
const keyframesFullView = keyframes`
  100% {
    width: 100%;
  }
`

const keyframesShimmer = keyframes`
  0% {
    background-position: -80vw 0;
  }
  100% {
    background-position: 80vw 0;
  }
`

const shimmerAnimation = css`
    background: linear-gradient(
        to right,
        #636363 0%,
        #ebebeb 12.5%,
        #636363 25%
    );
    background-size: 80vw 100%;
    animation: ${keyframesShimmer} 3.5s infinite linear;
`

const ShimmerWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    height: 40px;
    width: 0px;
    margin-bottom: 10px;
    color: #f52c2c;
    animation: ${keyframesFullView} 0.5s forwards
        cubic-bezier(0.25, 0.46, 0.45, 0.94);
`

const GreyedOutDiv = styled.div`
    background-color: grey;
    z-index: 2 !important;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    ${shimmerAnimation}
`
