import CreateUser from './createUser'
import DeleteUser from './deleteUser'
import DeleteAuth0User from './deleteAuth0User'
import GetUser from './getUser'
import UpdateUser from './updateUser'
import CreateGoal from './createGoal'
import UpdateGoal from './updateGoal'
import DeleteGoal from './deleteGoal'
import SendInvitationEmail from './sendInvitationEmail'
import GetAllData from './getAllData'
import UpdateMessage from './updateMessage'
import GetMarkers from './getMarkers'
import GetInsights from './getInsights'
import GetProfileInfo from './getProfileInfo'
import GetMedications from './getMedications'
import GetGoals from './getGoals'
import GetDevices from './getDevices'
import ChangeEmail from './changeEmail'
import ConfirmEmail from './confirmEmail'
import SendConfirmEmail from './sendConfirmEmail'
import GetInvitation from './getInvitation'
import DeleteInvitation from './deleteInvitation'
import CreateInvitation from './createInvitation'

const Users = {
    createUser: CreateUser,
    deleteUser: DeleteUser,
    deleteAuth0User: DeleteAuth0User,
    getUser: GetUser,
    updateUser: UpdateUser,
    createGoal: CreateGoal,
    updateGoal: UpdateGoal,
    deleteGoal: DeleteGoal,
    sendInvitationEmail: SendInvitationEmail,
    getAllData: GetAllData,
    updateMessage: UpdateMessage,
    getMarkers: GetMarkers,
    getInsights: GetInsights,
    getProfileInfo: GetProfileInfo,
    getMedications: GetMedications,
    getGoals: GetGoals,
    getDevices: GetDevices,
    changeEmail: ChangeEmail,
    confirmEmail: ConfirmEmail,
    sendConfirmEmail: SendConfirmEmail,
    getInvitation: GetInvitation,
    deleteInvitation: DeleteInvitation,
    createInvitation: CreateInvitation
}

export default Users
