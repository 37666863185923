import normalizeString from './normalizeString'
import {
    successStatusColor,
    warningStatusColor,
    dangerStatusColor,
    grayedOutMainColor
} from '../constants'

export const makeUser = (newUser, values) => {
    return {
        input: {
            id: newUser.id || newUser.cognitoId,
            first: normalizeString(newUser.first),
            last: normalizeString(newUser.last),
            email: newUser.email,
            acceptanceDate: values.submittedAt,
            agreementInfo: `Accepted By: ${normalizeString(
                values.fullName
            )}, Accepted On: ${window.navigator.userAgent}`,
            cognitoId: newUser.cognitoId,
            currentStatus: 'active',
            role: 'provider'
        }
    }
}

export const makeOrg = orgValues => {
    return {
        input: {
            name: normalizeString(orgValues.name),
            address: {
                line1: orgValues.line1,
                line2: orgValues.line2,
                city: normalizeString(orgValues.city),
                state: orgValues.state,
                zip: orgValues.zip
            }
        }
    }
}

export const determineUserType = userData => {
    let userType = 'patient'

    if (Array.isArray(userData?.role)) {
        if (userData.role.includes('provider')) {
            userType = 'provider'
        } else if (userData.role.includes('orgadmin')) {
            userType = 'orgadmin'
        }
    } else {
        userType = userData?.role
    }

    return userType
}

export const filterStaff = users => {
    const staff = []
    users.forEach(user => {
        if (Array.isArray(user.role)) {
            if (
                user.role.includes('provider') ||
                user.role.includes('orgadmin') ||
                user.role.includes('superuser')
            )
                staff.push(user)
        } else {
            if (
                user.role === 'provider' ||
                user.role === 'orgadmin' ||
                user.role === 'superuser'
            )
                staff.push(user)
        }
    })
    return staff
}

export const hasPermission = (validRole, user, role, ac) =>
    validRole.length > 0 && user.email.endsWith('@adyptation.com')
        ? ac.can(role).read('allData')
        : false

// Returns green, red, yellow color based on 1-100 score
export const getScoreColor = (score, goal, isArrow = false) => {
    const v = parseFloat(score)
    const dangerBreakpoint = goal * 0.33
    const successBreakpoint = goal * 0.66

    switch (true) {
        case v <= dangerBreakpoint:
            return dangerStatusColor
        case v >= successBreakpoint:
            return successStatusColor
        case isNaN(v):
        default:
            return isArrow ? grayedOutMainColor : warningStatusColor
    }
}

// Returns green, red, yellow color based on 1-5 scale
export const getSeverityColor = (num, direction) => {
    if (direction === 'up') {
        num = 5 - num // We're assuming this is a Likert 5-point scale
    }

    const f = parseFloat(num)
    switch (true) {
        case f <= 2:
            return successStatusColor
        case f >= 4:
            return dangerStatusColor
        case isNaN(f):
        default:
            return warningStatusColor
    }
}
// works on string arrays only, returns new array
// with no duplicate strings
export const filterUniques = arr => [...new Set(arr)]
