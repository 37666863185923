import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const deleteUser = (value, deleteAccount) => {
    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email
    const body = {
        query: print(gql`
        mutation {
            deleteUser(id: "${value}", provider: ${deleteAccount || false})
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'deleteUser')
}

export default deleteUser
