// From the Alerts brand guideline
export const alertsPrimary = '#8A4EE9'
export const alertsDarkPurple = '#252743'
export const alertsLightPurple = '#B0B5D7'
export const alertsTypographyLight = '#9095b7'
export const alertsButtonHover = '#6A2EC9'
export const dangerButtonHover = '#d62026'
export const alertsPaleBackground = '#F7F8FC'

export const buttonTextColor = '#fff'
export const buttonBackgroundHover = alertsButtonHover
export const buttonBackgroundColor = alertsPrimary
export const buttonBackgroundDisabled = alertsDarkPurple // Should be at 50% opacity
export const buttonBorderColor = alertsPrimary

export const disabledButtonBackgroundColor = '#3E275980'
export const errorColor = '#FF595E'
export const agreementBackground = '#e8e8e8'
export const agreementBorder = '#828282'
export const tableRowEven = alertsPaleBackground
export const tableRowOdd = 'white'
export const errorBackground = '#ff595e'
export const successBackground = '#2dc8a8'
export const modalTransBackground = 'rgba(0, 0, 0, 0.75)'
export const transTypography = '#4E4E4E50'

export const graphBackgroundColor = '#0074E0'
export const graphBorderColor = '#0074E0'
export const graphAverageBackgroundColor = '#ffa600'
export const graphAverageBorderColor = '#ffa600'
export const graphFill = alertsLightPurple // '#B0B0B0'
export const graphLabels = alertsTypographyLight
export const fontFamily = 'Open Sans'

// The following are from the Web Guidelines as of September 2020
export const successStatusColor = '#2DC8A8'
export const warningStatusColor = '#F2B359'
export const dangerStatusColor = '#FF595E'
export const primaryMainColor = alertsPrimary // '#0074E0'
export const typographyMainColor = '#4E4E4E'
export const grayedOutMainColor = alertsTypographyLight //
export const backgroundColor1 = '#FFFFFF'
export const backgroundColor2 = '#76A3DE'
export const backgroundColor3 = '#288ED12'
export const backgroundColor4 = '#566B89'
export const backgroundGray = alertsPaleBackground

export const BASE_URL = getBaseURL()
function getBaseURL() {
    switch (process.env.REACT_APP_AWS_ENV) {
        case 'local':
        case 'dev':
            return 'https://api-dev.adypta.com'
        default:
            return 'https://api.adypta.com'
    }
}

export const APP_URL = getAppURL()
function getAppURL() {
    switch (process.env.REACT_APP_AWS_ENV) {
        case 'local':
            return 'http://localhost:3000/'
        case 'dev':
            return 'https://qa.adypta.com/'
        case 'staging':
            return 'https://staging.app.adyptation.com'
        default:
            return 'https://app.adyptation.com/'
    }
}

export const FITBIT_REDIRECT = `${APP_URL}oauth/fitbit/return`
export const OURA_REDIRECT = encodeURIComponent(`${APP_URL}oauth/oura/return`)

export const MSFT_SSO_REDIRECT = `${APP_URL}login`
export const MSFT_SSO_TENANT_ID = '5fad41cd-8d59-45d3-833d-848aa4df7acf'
export const MSFT_SSO_CLIENT_ID = '5505e0ec-5e4c-44f2-b723-c2dfca712690'
export const MSFT_WHITELISTED_GROUP_ID = 'fe6728ee-fc04-4776-ac40-4388d4a8c098'

export const BASE_ORG_ID =
    process.env.REACT_APP_AWS_ENV === 'live'
        ? 'b1f2d8d8-5f1f-4913-a96c-375a656e0ec2'
        : 'a570def8-9ba8-4e6c-b117-cebc3e57e28f'
export const fCli = '239YJL'
export const fSec = '8274c56b26dc711b3e3fe1491263de71'
export const fitbitOauthAuthenticationUrl = `https://www.fitbit.com/oauth2/authorize?response_type=code&client_id=${fCli}&redirect_uri=${FITBIT_REDIRECT}&scope=activity%20heartrate%20location%20nutrition%20profile%20settings%20sleep%20social%20weight&expires_in=31536000`

export const oCli = 'RQBM3VUVYQG3CV7D'
export const oSec = 'VIJUKDDTYT7MSKD2VWBWJ3L6J2LP2D26'
export const ouraOauthAuthenticationUrl = `https://cloud.ouraring.com/oauth/authorize?redirect_uri=${OURA_REDIRECT}&client_id=${oCli}&state=XXX&&response_type=code`

export const times = [
    { key: '0000', value: '12:00 AM' },
    { key: '0100', value: '1:00 AM' },
    { key: '0200', value: '2:00 AM' },
    { key: '0300', value: '3:00 AM' },
    { key: '0400', value: '4:00 AM' },
    { key: '0500', value: '5:00 AM' },
    { key: '0600', value: '6:00 AM' },
    { key: '0700', value: '7:00 AM' },
    { key: '0800', value: '8:00 AM' },
    { key: '0900', value: '9:00 AM' },
    { key: '1000', value: '10:00 AM' },
    { key: '1100', value: '11:00 AM' },
    { key: '1200', value: '12:00 PM' },
    { key: '1300', value: '1:00 PM' },
    { key: '1400', value: '2:00 PM' },
    { key: '1500', value: '3:00 PM' },
    { key: '1600', value: '4:00 PM' },
    { key: '1700', value: '5:00 PM' },
    { key: '1800', value: '6:00 PM' },
    { key: '1900', value: '7:00 PM' },
    { key: '2000', value: '8:00 PM' },
    { key: '2100', value: '9:00 PM' },
    { key: '2200', value: '10:00 PM' },
    { key: '2300', value: '11:00 PM' }
]

export const ALERT_API_URL =
    process.env.REACT_APP_AWS_ENV === 'live' ||
    process.env.REACT_APP_AWS_ENV === 'staging'
        ? 'https://alerts.api.adyptation.com/graphql'
        : 'https://dev-alerts.api.adyptation.com/graphql'

export const SYSTEM_EMAIL = 'internalsystem@adyptation.com'

export const ENV =
    process.env.REACT_APP_AWS_ENV === 'local' ||
    process.env.REACT_APP_AWS_ENV === 'dev'
        ? 'dev'
        : 'prod'

export const FakeProfileData = {
    first: 'Frodo',
    last: 'Baggins',
    email: 'frodo@theshire.com',
    phone: '+18008625309',
    goalDefinitions: [
        {
            id: '1',
            name: 'Pain',
            targetScore: 5,
            targetDate: '03/04/2021',
            deliveryTime: ['0900', '1800']
        }
    ],
    devices: [],
    medications: [
        {
            id: '1234',
            nickname: 'The Good Stuff',
            brandName: 'Motrin',
            dosage: '800mg',
            frequency: 'Daily',
            startDate: 1621372240
        }
    ]
}

export const FakeOrganizationData = {
    name: 'The Baggins Group'
}

export const Auth0URL =
    ENV === 'prod' ? 'auth.adyptation.com' : 'adypta-dev.us.auth0.com'

export const Auth0ClientId =
    ENV === 'prod'
        ? 'Die8zKLtuXmwJzaUf1lQFaqyubkcBA0L'
        : 'Cj3z5oGBkd6c6B6BuHJqIBqbifDZm5kL'
