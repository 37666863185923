import AlertApi from '../apiCalls'
import { determineUserType } from '../utils/helperFunctions'

const getUserInfo = async email => {
    if (email) {
        return AlertApi.getUser('email', email, email)
            .then(res => {
                const type = determineUserType(res)
                if (type === 'patient') {
                    localStorage.setItem('patientId', res.id)
                    res.patientId = res.id
                    return res
                } else {
                    return AlertApi.getOrg('id', res.organization.id, email)
                        .then(result => {
                            res.organization = result
                            return res
                        })
                        .catch(err => console.warn({ err }))
                }
            })
            .catch(_ => {
                return {
                    error: 'Unable to retrieve your information. Please try again.'
                }
            })
    }
}

export default getUserInfo
