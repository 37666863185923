import { SYSTEM_EMAIL } from '../../constants'
import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getInvitation = (type, value) => {
    const body = {
        query: print(gql`
        query {
            getInvitation(${type}: "${value}") {
                id
                first
                last
                email
                organizationId
                organizationName
                roleType
                emailSent
                lastEmailSent
                updatedAt
                createdAt
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(SYSTEM_EMAIL)
    }
    const whitelist = 'unable to find an invitation'

    return axiosCall(body, options, 'getInvitation', whitelist)
}

export default getInvitation
