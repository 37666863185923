import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getMedications = email => {
    const body = {
        query: print(gql`
        query {
            getUser(email: "${email}") {
            medications {
                id
                nickname
                brandName
                genericName
                medicationId
                dosage
                frequency
                startDate
                stopDate
                createdAt
                updatedAt
              }
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(email)
    }

    return axiosCall(body, options, 'getUser')
        .then(result => result.medications)
        .catch(error => {
            throw error
        })
}

export default getMedications
