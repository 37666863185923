import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getProfileInfo = email => {
    const body = {
        query: print(gql`
        query {
            getUser(email: "${email}") {
            id
            first
            last
            email
            role
            cognitoId
            acceptanceDate
            agreementInfo
            currentStatus
            currentStatusDate
            createdAt
            updatedAt
            textMessageStatus
            contactType
            phone
            invitation
            invitationDate
            organizationId
            pendingEmail
            previousEmail
            timeZone {
                name
                offsets
                abbreviations
            }
            organization {
              id
              name
              address {
                    city
                    state
                }
              }
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(email)
    }

    return axiosCall(body, options, 'getUser')
}

export default getProfileInfo
