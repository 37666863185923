import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { toast } from 'react-toastify'

const AuthRoute = ({ component: Component, ...rest }) => {
    const { isAuthenticated, user } = useAuth0()

    const renderChildren = routeProps => {
        if (!isAuthenticated || !user?.email_verified) {
            if (user?.email_verified === false) {
                toast.error(
                    'Please check your email to verify before logging in.',
                    { autoClose: false }
                )
            }

            return (
                <Redirect
                    to={{
                        pathname: '/',
                        state: { from: rest.location }
                    }}
                />
            )
        }

        return rest.render ? (
            rest.render()
        ) : (
            <Component {...routeProps} {...rest} />
        )
    }

    return <Route {...rest} render={routeProps => renderChildren(routeProps)} />
}

export default AuthRoute
