import axios from 'axios'
import { Auth0ClientId, Auth0URL } from '../../constants'

const changePassword = async email => {
    const options = {
        method: 'POST',
        url: `${Auth0URL}/dbconnections/change_password`,
        headers: { 'content-type': 'application/json' },
        data: {
            client_id: Auth0ClientId,
            email,
            connection: 'Username-Password-Authentication'
        }
    }

    return axios.request(options)
}

export default changePassword
