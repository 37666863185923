import React from 'react'
import {
    typographyMainColor,
    backgroundGray,
    graphFill,
    fontFamily
} from '../../constants'

const getStyles = (type, x, y) => {
    switch (type) {
        case 'STEPS':
            return {
                xOffset: x - 21,
                textOffset: x - 19,
                width: '42'
            }
        case 'HOURS':
            return {
                xOffset: x - 24,
                textOffset: x - 22,
                width: '46'
            }
        default:
            return {
                xOffset: x - 12,
                textOffset: x - 10,
                width: '23'
            }
    }
}
const FlyOut = ({ type, x, y, datum, dx, dy }) => {
    const { xOffset, textOffset, width } = getStyles(type, x, y)
    const baseFontSize = '6px'
    return (
        <g>
            <rect
                x={xOffset}
                y={y - 35}
                width={width}
                dx={dx}
                dy={dy}
                height="30"
                rx="3"
                ry="3"
                fill={backgroundGray}
                data-testid="flyOut"
            />
            <text
                x={textOffset}
                y={y - 25}
                fontSize={baseFontSize}
                fontWeight="bold"
                fontFamily={fontFamily}
                fill={graphFill}
                data-testid="flyOutTotal"
            >
                TOTAL {type}
            </text>
            <text
                x={textOffset}
                y={y - 16}
                fontSize={baseFontSize}
                fontWeight="bold"
                fill={typographyMainColor}
                data-testid="flyOutValue"
            >
                {datum.y}
            </text>
            <text
                x={textOffset}
                y={y - 8}
                fontSize={baseFontSize}
                fontWeight="bold"
                fill={graphFill}
                data-testid="flyOutDate"
            >
                {datum.x}
            </text>
        </g>
    )
}
export default FlyOut
