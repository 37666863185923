import gql from 'graphql-tag'
import { print } from 'graphql'
import { axiosCall, getDefaultHeaders } from '../helpers/defaults'

const getUser = (type, value, userEmail) => {
    const body = {
        query: print(gql`
        query {
            getUser(${type}: "${value}") {
            id
            first
            last
            email
            role
            cognitoId
            acceptanceDate
            agreementInfo
            currentStatus
            currentStatusDate
            createdAt
            updatedAt
            textMessageStatus
            contactType
            phone
            invitation
            invitationDate
            pendingEmail
            previousEmail
            organizationId
            organization {
              id
              name
              address {
                    city
                    state
                }
              }
            timeZone {
                name
                offsets
                abbreviations
            }
            messages {
              id
              displayMessage
              message
              linkUrl
              messageType
            }
            medicationTracking
            }
        }`)
    }

    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    const whitelist = 'No user exists.'

    return axiosCall(body, options, 'getUser', whitelist)
}

export default getUser
