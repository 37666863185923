import styled from 'styled-components'
import { primaryMainColor, backgroundGray } from './../../constants'

export const Container = styled.div`
    background-color: ${backgroundGray};
    ${({ open }) => !open && 'display: none;'};

    @media (min-width: 900px) {
        position: sticky;
        top: 0;
        display: block;
        height: 100vh;
        width: 180px;
    }
`

export const StyledDiv = styled.div`
    display: flex;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    margin: 5px 0;
    color: ${({ activePage }) => (activePage ? primaryMainColor : 'black')};
    text-decoration: ${({ activePage }) => (activePage ? 'underline' : 'none')};

    @media (min-width: 900px) {
        justify-content: flex-start;
        border-left: ${({ activePage }) =>
            activePage ? `${primaryMainColor} solid 5px` : 'none'};
        text-decoration: none;
    }
`

export const StyledH6 = styled.h6`
    font-size: 18px;
    font-weight: 700;

    @media (min-width: 900px) {
        font-size: 16px;
    }
`
