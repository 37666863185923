import React from 'react'
import styled from 'styled-components'
import { FlexRowSB } from '../../utils/styledComponents'
import GoalProgressGraph from '../Graphs/GoalProgressGraph'
import SemiCircleGraph from '../Graphs/SemiCircleGraph'
import MiniBarGraph from '../Graphs/MiniBarGraph'
import CreateGoalPopup from './CreateGoalPopup'
import { fakeGoalProgressGraphData } from '../../data/fakeGraphData'
import GreyOutDiv from '../atoms/GreyOutDiv'

const FakeScoreSummary = ({
    noData,
    startDate,
    isViewingAsProvider,
    isPlaceholder
}) => {
    return (
        <MainContainer
            id="FakeScoreSummary"
            data-testid="FakeScoreSummary"
            isPlaceholder={isPlaceholder}
        >
            <GreyOutDiv>
                {!isPlaceholder && (
                    <CreateGoalPopup
                        noData={noData}
                        startDate={startDate}
                        isViewingAsProvider={isViewingAsProvider}
                    />
                )}
                <Row centerVertically mobileColumn blur={!isViewingAsProvider}>
                    <SemiCircleGraph goalName="Pain" score={56} goal={80} />
                    <MiniBarContainer>
                        <MiniBarGraph
                            type="consistency"
                            title="Response Consistency"
                            titleRight="Be Alert"
                            point={38}
                            max={100}
                        />
                        <MiniBarGraph
                            type="direction"
                            title="Trending Direction"
                            titleRight="Average"
                            point={65}
                            max={100}
                        />
                        <MiniBarGraph
                            type="survey"
                            title="Response Values"
                            titleRight="77%"
                            point={77}
                            max={100}
                        />
                    </MiniBarContainer>
                </Row>
                <Row blur={!isViewingAsProvider}>
                    <GoalProgressGraph
                        scoreData={fakeGoalProgressGraphData}
                        fake={true}
                        goal={80}
                    />
                </Row>
            </GreyOutDiv>
        </MainContainer>
    )
}

export default FakeScoreSummary

const MainContainer = styled.div`
    margin: 0 2%;
    position: relative;
    ${({ isPlaceholder }) => isPlaceholder && 'filter: blur(5px);'}
    @media (min-width: 900px) {
        margin: 0;
        margin-bottom: 5%;
    }
`

const Row = styled(FlexRowSB)`
    ${({ blur }) => blur && 'filter: blur(5px);'}
    @media (min-width: 900px) {
        height: 35vh;
    }
`

const MiniBarContainer = styled.div`
    margin: 0 20px 20px 2%;
    width: 50%;
    display: block;
    @media (max-width: 900px) {
        width: 100%;
        margin: 0 2%;
    }
`
