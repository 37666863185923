import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import {
    buttonBackgroundColor,
    buttonBackgroundHover,
    dangerStatusColor,
    dangerButtonHover,
    buttonBackgroundDisabled
} from '../../../constants'

const ButtonLink = ({ text, children, to, ...rest }) => {
    return (
        <Container>
            <MainLink to={to} {...rest}>
                {children || text}
            </MainLink>
        </Container>
    )
}

ButtonLink.defaultProps = {
    text: 'Log in',
    to: '/'
}
export default ButtonLink

const Container = styled.div`
    text-align: center;
`

const MainLink = styled(Link)`
    margin: ${({ margin }) => (margin ? margin : '10px')};
    padding: 12px 24px;
    width: ${({ width }) => (width ? width : '50%')};
    border: none;
    border-radius: 5px;
    background-color: ${({ danger }) =>
        danger === 'true' ? dangerStatusColor : buttonBackgroundColor};
    color: white;
    font-weight: 500;
    font-size: 16px;
    ${({ nounderline }) =>
        nounderline && nounderline === 'true' && 'text-decoration: none;'}
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    border-color: ${({ danger }) =>
        danger === 'true' ? dangerStatusColor : buttonBackgroundColor};
    :hover,
    :focus,
    :active {
        text-decoration: none;
        color: white;
        background-color: ${({ danger }) =>
            danger === 'true'
                ? dangerButtonHover
                : buttonBackgroundHover} !important;
        border-color: ${({ danger }) =>
            danger === 'true'
                ? dangerButtonHover
                : buttonBackgroundHover} !important;
        box-shadow: none !important;
    }
    :disabled {
        background-color: ${({ danger }) =>
            danger === 'true'
                ? dangerStatusColor
                : buttonBackgroundDisabled} !important;
        border-color: ${({ danger }) =>
            danger === 'true'
                ? dangerStatusColor
                : buttonBackgroundDisabled} !important;
        box-shadow: none !important;
        opacity: 50%;
    }
    @media (min-width: 900px) {
        font-size: 14px;
    }
`
