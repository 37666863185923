import React, { useState, useEffect } from 'react'
import PatientsForm from './PatientsForm.js'
import { useAppContext } from '../../context/AppContext'
import {
    formatName,
    filterByName,
    NoPatientList
} from '../Dashboard/dashboardUtils'
import { InsideDiv } from '../../utils/styledComponents'
import { PatientList } from '../Patient/PatientList.js'

const PatientTable = ({ impersonation, org, setDeletePatient }) => {
    const { state } = useAppContext()
    const { organization } = state.auth
    const thisOrg = org || organization
    const [data, setData] = useState(undefined)
    const [all, setAll] = useState(undefined)
    const [searchName, setSearchName] = useState('')

    useEffect(() => {
        if (thisOrg && thisOrg.patients) {
            const sortedItems = thisOrg.patients.sort((a, b) =>
                a.last + a.first > b.last + b.first ? 1 : -1
            )
            const items = sortedItems.filter(
                item => item.currentStatus === 'active'
            )
            setData(items)
            setAll(items)
        }
    }, [organization])

    const handleInputChange = event => {
        const { value, name } = event.target
        const newName = formatName(name === 'searchName' ? value : searchName)
        const nameSort = newName !== '' ? filterByName(all, newName) : all
        setSearchName(newName)
        setData(nameSort)
    }

    const unfilter = () => {
        setSearchName('')
        setData(all)
    }

    if (!data) return null

    return (
        <InsideDiv>
            <PatientsForm
                searchName={searchName}
                unfilter={unfilter}
                handleChange={handleInputChange}
            />
            {data.length > 0 ? (
                <PatientList
                    data={data}
                    impersonation={impersonation}
                    org={thisOrg}
                    setDeletePatient={setDeletePatient}
                />
            ) : (
                <NoPatientList totalUsers={all?.length} />
            )}
        </InsideDiv>
    )
}

export default PatientTable
