import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import {
    VictoryAxis,
    VictoryScatter,
    VictoryChart,
    VictoryLine,
    VictoryArea
} from 'victory'
import HeaderText from '../atoms/text/HeaderText'
import {
    primaryMainColor,
    graphFill,
    fontFamily,
    graphLabels
} from '../../constants'
import GreyOutDiv from '../atoms/GreyOutDiv'

const axisStyles = {
    axis: { stroke: graphFill },
    tickLabels: {
        fill: graphLabels,
        fontSize: '7px',
        fontFamily
    },
    grid: {
        stroke: graphFill,
        opacity: 0.5,
        strokeWidth: 1
    }
}

// Graph used on main dashboard to show steps and sleep with time parameter
const FakeDeviceGraph = ({ type }) => {
    const stepMarkers = [
        { x: '5/01', y: 5400 },
        { x: '5/04', y: 7500 },
        { x: '5/08', y: 8000 },
        { x: '5/12', y: 6000 },
        { x: '5/16', y: 8200 },
        { x: '5/20', y: 9500 },
        { x: '5/24', y: 12500 },
        { x: '5/27', y: 15000 },
        { x: '5/30', y: 11000 }
    ]

    const sleepMarkers = [
        { x: '5/01', y: 6.4 },
        { x: '5/04', y: 5.4 },
        { x: '5/08', y: 7.1 },
        { x: '5/12', y: 3.5 },
        { x: '5/16', y: 8.2 },
        { x: '5/20', y: 6.6 },
        { x: '5/24', y: 6.4 },
        { x: '5/27', y: 6.3 },
        { x: '5/30', y: 7.2 }
    ]

    const markers = type === 'sleep' ? sleepMarkers : stepMarkers
    const max = type === 'sleep' ? 8.2 : 15000
    const title = type === 'sleep' ? 'Hours of Sleep' : 'Steps per Day'

    const tabletSize = 200
    const desktopSize = 100
    const [graphSize, setGraphSize] = useState(desktopSize)
    // sets line chart to a smoother version
    const interpolation = 'catmullRom'
    // adds symbol to array of markers for use with scatter plot
    const scatterData = markers.map(mark => ({ ...mark, symbol: 'circle' }))

    const resizeGraph = () => {
        const width = window.innerWidth
        if (width < 900) {
            setGraphSize(tabletSize)
        }
    }

    useEffect(() => {
        resizeGraph()
    }, [])

    return (
        <GraphContainer
            id={`Fake${type}Graph`}
            data-testid={`Fake${type}Graph`}
        >
            <GreyOutDiv>
                <HeaderText>{title}</HeaderText>
                <VictoryChart
                    padding={{ top: 10, bottom: 50, left: 50, right: 50 }}
                    height={graphSize}
                >
                    <VictoryAxis fixLabelOverlap style={axisStyles} />
                    <VictoryAxis
                        dependentAxis
                        fixLabelOverlap
                        style={axisStyles}
                    />
                    <VictoryLine
                        style={{
                            data: { stroke: primaryMainColor, strokeWidth: 1 }
                        }}
                        interpolation={interpolation}
                        data={markers}
                        domain={{ y: [0, max * 1.1] }}
                    />
                    <VictoryArea
                        style={{
                            data: { fill: primaryMainColor, opacity: 0.3 }
                        }}
                        interpolation={interpolation}
                        data={markers}
                        domain={{ y: [0, max * 1.1] }}
                    />
                    <VictoryScatter
                        style={{
                            data: {
                                fill: primaryMainColor,
                                stroke: 'white',
                                strokeWidth: 1
                            }
                        }}
                        data={scatterData}
                        size={1.6}
                        labels={() => ''}
                    />
                </VictoryChart>
            </GreyOutDiv>
        </GraphContainer>
    )
}

export default FakeDeviceGraph

const GraphContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    width: 100%;
    text-align: center;
    filter: blur(5px);
    @media (min-width: 900px) {
        margin: 0;
        height: 40vh;
    }
`
