import moment from 'moment'
// finds steps biomarkers
// returns new array of step biomarkers
export const getStepsFromBioMarkers = (bioMarkers = []) => {
    return bioMarkers.filter(marker => marker.bioType === 'steps')
}

// finds sleep biomarkers
// converts minutes value to hours
// returns array of single point float values (ex 4.5)
export const getSleepFromBioMarkers = (bioMarkers = []) => {
    return bioMarkers
        .filter(marker => marker.bioType === 'totalminutesasleep')
        .map(data => ({
            ...data,
            value: parseFloat((data.value / 60).toFixed(1))
        }))
}

// Prepares biomarkers for graphing
// Filters out old markers by graphDays
// Then formats for x, y graph value objects
export const formatBioMarkers = (graphDays, markers) => {
    const oldestDate = moment().subtract(graphDays, 'day').format('M/DD/YYYY')
    const filteredMarkers = markers.filter(
        marker =>
            moment(marker.date).isAfter(oldestDate, 'day') && marker.value !== 0
    )
    return filteredMarkers.map(marker => ({
        x: moment(marker.date).format('M/DD'),
        y: marker.value
    }))
}
