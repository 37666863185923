import AlertApi from '../apiCalls'

const getBioMarkers = (dispatch, email) => {
    AlertApi.getMarkers(email)
        .then(result => {
            dispatch({
                type: 'UPDATE_USER',
                value: { bioMarkers: result.bioMarkers, role: result.role }
            })
        })
        .catch(err => console.warn('getBioMarkers error: ', { err }))
}

const getDevices = (dispatch, email) => {
    AlertApi.getDevices(email)
        .then(devices => {
            dispatch({ type: 'UPDATE_USER', value: { devices } })
        })
        .catch(err => console.warn('getDevices error: ', { err }))
}

const getMedications = (dispatch, email) => {
    AlertApi.getMedications(email)
        .then(medications => {
            dispatch({ type: 'UPDATE_USER', value: { medications } })
        })
        .catch(err => console.warn('getMedications error: ', { err }))
}

const getGoals = (dispatch, email) => {
    AlertApi.getGoals(email)
        .then(results => {
            const { goalDefinitions, goalsJournals } = results
            dispatch({
                type: 'UPDATE_USER',
                value: { goalDefinitions, goalsJournals }
            })
        })
        .catch(err => console.warn('getGoals error: ', { err }))
}

const getInsights = (dispatch, email) => {
    AlertApi.getInsights(email)
        .then(result => {
            const { goalDefinitions, insightsSnapshot } = result
            dispatch({
                type: 'UPDATE_USER',
                value: {
                    goalDefinitions,
                    insightsSnapshot,
                    role: result.role
                }
            })
        })
        .catch(err => console.warn('getInsights error: ', { err }))
}

const getOrganization = (dispatch, id) => {
    AlertApi.getOrg('id', id)
        .then(organization => {
            dispatch({ type: 'UPDATE_USER_ORG', value: { organization } })
        })
        .catch(err => console.warn('getOrganization error: ', { err }))
}

const dashboardCalls = (dispatch, user) => {
    getBioMarkers(dispatch, user.email)
    getDevices(dispatch, user.email)
    getMedications(dispatch, user.email)
    getGoals(dispatch, user.email)
    getInsights(dispatch, user.email)
    if (user.role && user.role.includes('provider'))
        getOrganization(dispatch, user.organizationId)
}

const PassiveCalls = {
    getBioMarkers,
    getDevices,
    getMedications,
    getGoals,
    getInsights,
    dashboardCalls
}

export default PassiveCalls
