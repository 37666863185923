import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const deleteToken = value => {
    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email
    const body = {
        query: print(gql`
        mutation {
            deleteOAuthToken(id: "${value}") 
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'deleteOAuthToken')
}

export default deleteToken
