import moment from 'moment'
import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const createGoal = (values, user) => {
    const futureDate = moment(new Date()).add(3, 'years').format('MM/DD/YYYY')
    const userEmail =
        user.email || JSON.parse(localStorage.getItem('state')).auth.user.email
    const newGoal = {
        name: values.name.toLowerCase(),
        goalType: values.goalType,
        direction: values.direction,
        deliveryMethod: values.deliveryMethod,
        targetDate: futureDate,
        userId: user.id
    }
    const deliveryTime = values.deliveryTime.map(time => `"${time}"`).join(', ')
    const goalScale = values.goalScale
        .map(goal => `"${goal.toLowerCase()}"`)
        .join(', ')
    const basicEntries = Object.entries(newGoal)
        .map(val => `${val[0]}: "${val[1]}"`)
        .join(', ')
    const newValues =
        basicEntries +
        `, targetScore: ${values.targetScore}` +
        `, goalScale: [${goalScale}]` +
        `, deliveryTime: [${deliveryTime}]`
    const body = {
        query: print(gql`
        mutation {
            createGoal(${newValues}) {
            first
            last
            contactType
            email
            phone
            role
            currentStatus
            currentStatusDate
            createdAt
            updatedAt
            textMessageStatus
            goalDefinitions {
                id
                name
                goalType
                direction
                goalScale
                deliveryMethod
                deliveryTime
                targetDate
                targetScore
                createdAt
                updatedAt
            }
            contactType
            organizationId
            invitation
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'createGoal')
}

export default createGoal
