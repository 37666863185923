import React from 'react'
import styled from 'styled-components'
import { grayedOutMainColor, typographyMainColor } from '../../../constants'

// For use with column-based form inputs
const FormLabel = ({ textAlign, children }) => {
    return <Label textAlign={textAlign}>{children}</Label>
}

export default FormLabel

export const FormSubLabel = ({ children }) => <SubLabel>{children}</SubLabel>

const Label = styled.label`
    color: ${grayedOutMainColor};
    width: 100%;
    text-align: center;
    font-weight: 700;

    font-weight: bolder;
    margin: 0;
    text-transform: uppercase;
    border-top: none;
    font-size: 15px;

    @media (min-width: 900px) {
        text-align: ${({ textAlign }) => textAlign || 'left'};
    }
`

const SubLabel = styled.p`
    font-style: italic;
    font-size: 14px;
    color: ${typographyMainColor};
`
