import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getMarkers = email => {
    const body = {
        query: print(gql`
        query {
            getUser(email: "${email}") {
            id
            currentStatus
            role
            bioMarkers {
                id
                date
                unit
                bioType
                device
                value
              }
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(email)
    }

    return axiosCall(body, options, 'getUser')
}

export default getMarkers
