import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getAllData = value => {
    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email
    const body = {
        query: print(gql`
        query {
            getAllData(id: "${value}") {
                bioMarkers {
                    id
                    bioType
                    date
                    unit
                    device
                    value
                    recordedDate
                    userId
                    updatedAt
                    createdAt
                }
                goalsJournal {
                    id
                    date
                    unit
                    goalType
                    goalId
                    device
                    value
                    userId
                    updatedAt
                    createdAt
                }
                dataStore {
                    id
                    userId
                    dataType
                    vendor
                    vendorUserId
                    collection
                    date
                    rawData
                    updatedAt
                    createdAt
                }
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'getAllData')
        .then(result => {
            const { bioMarkers, dataStore, goalsJournal } = result
            return { bioMarkers, dataStore, goalsJournal }
        })
        .catch(error => {
            throw error
        })
}

export default getAllData
