import React from 'react'
import styled from 'styled-components'
import {
    VictoryChart,
    VictoryPie,
    VictoryPolarAxis,
    VictoryLabel
} from 'victory'
import { getScoreColor } from '../../utils/helperFunctions'
import {
    alertsPrimary,
    typographyMainColor,
    graphFill,
    fontFamily
} from '../../constants'
import HeaderText from '../atoms/text/HeaderText'
import normalizeString from '../../utils/normalizeString'

const SemiCircleGraph = ({ score, goal, goalName }) => {
    const scoreData = [
        { x: 1, y: score },
        { x: 2, y: 100 - score }
    ]
    const goalData = [
        { x: 1, y: goal - 1 },
        { x: 2, y: 1 },
        { x: 3, y: 100 - goal }
    ]
    const scoreColor = getScoreColor(score, 100)
    const labelStyles = {
        fontSize: 22,
        fontFamily,
        fill: typographyMainColor
    }

    return (
        <GraphContainer data-testid="SemiCircleGraph">
            <HeaderText>{normalizeString(goalName)} Score</HeaderText>
            <BackgroundScore score={score} scoreColor={scoreColor} />
            {/* Have to take away padding from the bottom to account for the
            huge whitespace left behind from cutting the bottom half
            of the circle off */}
            <VictoryChart
                polar
                startAngle={0}
                endAngle={180}
                // The right padding prevents label cutoff from the 100
                padding={{ top: 0, bottom: -50, right: 0, left: 0 }}
            >
                {/* <VictoryLabel
                    text={`${normalizeString(goalName)} Score`}
                    textAnchor="middle"
                    x={30}
                    y={10}
                /> */}
                <VictoryPolarAxis
                    tickValues={[0, 100]}
                    labelPlacement="vertical"
                    invertAxis={true}
                    style={{
                        axis: { stroke: graphFill },
                        tickLabels: labelStyles
                    }}
                />
                {/* This is the colored score graph */}
                <VictoryPie
                    standalone={false}
                    animate={{ duration: 1000 }}
                    startAngle={-90}
                    endAngle={90}
                    data={scoreData}
                    innerRadius={170}
                    labels={() => null}
                    style={{
                        data: {
                            fill: ({ datum }) =>
                                datum.x === 1 ? scoreColor : 'transparent'
                        }
                    }}
                />
                {/* This is the thin black rail graph */}
                <VictoryPie
                    standalone={false}
                    data={goalData}
                    innerRadius={165}
                    startAngle={-90}
                    endAngle={90}
                    radius={({ datum }) => (datum.x === 2 ? 185 : 170)}
                    labels={({ datum }) =>
                        datum.x === 2 ? ['Avg: ', goal] : null
                    }
                    labelComponent={
                        <VictoryLabel
                            inline
                            style={[
                                {
                                    ...labelStyles,
                                    fill: alertsPrimary,
                                    fontWeight: 400
                                }
                            ]}
                        />
                    }
                    style={{
                        data: {
                            fill: ({ datum }) =>
                                datum.x === 2 ? alertsPrimary : graphFill
                        }
                        // tickLabels: labelStyles
                    }}
                />
            </VictoryChart>
        </GraphContainer>
    )
}

SemiCircleGraph.defaultProps = {
    score: 90,
    goal: 80,
    goalName: 'Pain'
}

export default React.memo(SemiCircleGraph)

const BackgroundScore = ({ score, scoreColor }) => {
    return (
        <BackgroundContainer scoreColor={scoreColor}>
            <p>{score}</p>
        </BackgroundContainer>
    )
}

const GraphContainer = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
    flex-direction: column;
    text-align: center;

    @media (min-width: 900px) {
        width: 50%;
    }
`

const BackgroundContainer = styled.div`
    position: absolute;
    top: 40%;
    width: 100%;
    text-align: center;
    p {
        font-size: 80px;
        font-family: 'Roboto Mono';
        color: ${({ scoreColor }) => scoreColor};
    }

    @media (min-width: 450px) {
        top: 35%;

        p {
            font-size: 128px;
        }
    }
`
