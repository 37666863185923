import React, { useEffect, memo } from 'react'
import { useAppContext } from '../../context/AppContext'
import { useAuth0 } from '@auth0/auth0-react'
import { useHistory } from 'react-router-dom'
import Loading from '../../components/Loading'
import DashboardProviderContainer from './DashboardProviderContainer'
import OverviewContainer from './OverviewContainer'
import PassiveCalls from '../../logic/passiveApiCalls'

const DashboardContainer = () => {
    const history = useHistory()
    const { state, dispatch } = useAppContext()
    const { user } = useAuth0()
    const { userType } = state.auth
    const stateUserType = JSON.parse(localStorage.getItem('state'))?.auth
        ?.userType

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (!state.auth.user?.first && state.auth.user?.id) {
            history.push('/sign_up/info')
        } else if (state.auth.user?.email) {
            const { acceptanceDate } = state.auth.user
            PassiveCalls.dashboardCalls(dispatch, state.auth.user)
            if (!acceptanceDate || acceptanceDate === 'incomplete') {
                history.push('/terms_and_conditions')
            }
        }
    }, [])

    useEffect(() => {
        if (user?.email) {
            if (state.auth.user?.email) {
                const { acceptanceDate } = state.auth.user
                if (!acceptanceDate || acceptanceDate === 'incomplete') {
                    history.push('/terms_and_conditions')
                }
            }
        }
    }, [user, state.auth.user])

    return !state?.auth?.user?.first ? (
        <Loading displayText="Loading app..." />
    ) : stateUserType === 'patient' || userType === 'patient' ? (
        <OverviewContainer user={state.auth.user} />
    ) : (
        <DashboardProviderContainer />
    )
}

export default memo(DashboardContainer)
