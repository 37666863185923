import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const getGoalDialogs = () => {
    const userEmail = JSON.parse(localStorage.getItem('state')).auth.user.email
    const body = {
        query: print(gql`
            query {
                listGoalDialogs {
                    id
                    goalType
                    questions
                    createdAt
                    updatedAt
                }
            }
        `)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'listGoalDialogs')
}

export default getGoalDialogs
