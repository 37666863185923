import React from 'react'
import { AppProvider } from '../context/AppContext'
import Layout from '../components/Layout/Layout'
import Routes from '../components/Layout/Routes'

const App = () => (
    <AppProvider>
        <Layout>
            <Routes />
        </Layout>
    </AppProvider>
)

export default App
