import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const sendJoinOrgInvitation = (id, email) => {
    const userEmail =
        email || JSON.parse(localStorage.getItem('state')).auth.user.email
    const newValues = `id: "${id}"`
    const body = {
        query: print(gql`
        mutation {
            sendJoinOrgInvitation(${newValues}){
                id
                first
                last
                email
                organizationId
                organizationName
                roleType
                emailSent
                lastEmailSent
                updatedAt
                createdAt
            }
        }`)
    }

    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'sendJoinOrgInvitation')
}

export default sendJoinOrgInvitation
