import React from 'react'
import styled from 'styled-components'
import {
    buttonBackgroundColor,
    dangerStatusColor,
    alertsButtonHover
} from '../../../constants'

const CancelButton = ({ disabled, text, width, display, danger, ...rest }) => {
    return (
        <MainContainer
            width={width}
            data-testid="cancelBtnContainer"
            display={display}
        >
            <TextElement danger={danger} {...rest} disabled={disabled}>
                {text}
            </TextElement>
        </MainContainer>
    )
}

CancelButton.defaultProps = {
    disabled: false,
    text: 'Button Text',
    danger: 0
}

export default CancelButton

const MainContainer = styled.div`
    width: ${({ width }) => (width ? width : '50%')};
    display: ${({ display }) => (display ? display : 'flex')};
`

const TextElement = styled.p`
    color: ${({ danger }) =>
        danger ? dangerStatusColor : buttonBackgroundColor};
    font-weight: 700;
    font-size: 16px !important;
    cursor: pointer;
    padding: 0;
    width: fit-content;
    margin: auto;
    vertical-align: center;
    :hover {
        text-decoration: underline;
        color: ${alertsButtonHover};
    }
    @media (min-width: 900px) {
        font-size: 14px;
    }
`
