import React from 'react'
import styled from 'styled-components'
import { typographyMainColor } from '../../../constants'
import { Error } from '../../../utils/styledComponents'

// ...rest pass-ins, anything checkbox input would use
const FormCheckbox = ({ width, label, error, testid, ...rest }) => {
    return (
        <MainContainer width={width} data-testid={testid}>
            <Input id="checkbox" type="checkbox" {...rest} />
            <Column>
                <Label htmlFor={'checkbox'}>{label}</Label>
                {error && <Error>{error}</Error>}
            </Column>
        </MainContainer>
    )
}

// label prop can be jsx
// use jsx for links or different styled label
// for example
// label={<span>dynamic elements far out man</span>},

FormCheckbox.defaultProps = {
    label: 'lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum',
    testid: 'form-checkbox'
}
export default FormCheckbox

const MainContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 500px;
    margin: 2% 0;
    @media (min-width: 900px) {
        ${({ width }) => width && `width: ${width}`}
    }
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 80%;
`
const Input = styled.input`
    margin: 0 20px;
`
const Label = styled.label`
    color: ${typographyMainColor};
    font-size: 18px;
    font-weight: 700;
    margin: 0;
    text-align: left;
`
