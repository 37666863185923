import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {
    FormGroup,
    FormLabel,
    Button,
    FormControl,
    Col,
    Table
} from 'react-bootstrap'
import {
    errorColor,
    errorBackground,
    buttonBackgroundColor,
    buttonBackgroundHover,
    backgroundColor1,
    primaryMainColor,
    dangerStatusColor,
    grayedOutMainColor,
    typographyMainColor,
    buttonBackgroundDisabled,
    alertsPaleBackground,
    transTypography
} from '../constants'

export const BasicDiv = styled.div``

// centerContentHorizontally always centers content
// centerContentMobile centers content on mobile only
// centerSelfMobile centers this container within it's parent on mobile
export const FlexColumn = styled.div`
    display: flex;
    width: ${({ width }) => (width ? width : '100%')};
    height: ${({ height }) => (height ? height : 'auto')};
    flex-direction: column;
    ${({ centerContentHorizontally }) =>
        centerContentHorizontally && 'align-items: center;'};
    ${({ centerContentMobile }) =>
        centerContentMobile && 'align-items: center;'};
    ${({ centerSelfMobile }) => centerSelfMobile && 'align-self: center;'};
    // border: 1px solid blue;

    @media (min-width: 900px) {
        align-self: start;
        ${({ centerContentHorizontally }) =>
            centerContentHorizontally
                ? 'align-items: center;'
                : 'align-items: flex-start'};
    }
`
export const FlexColumnSE = styled(FlexColumn)`
    justify-content: space-evenly;
`
export const FlexColumnSA = styled(FlexColumn)`
    justify-content: space-around;
`
export const FlexColumnSB = styled(FlexColumn)`
    justify-content: space-between;
`
export const FlexColumnC = styled(FlexColumn)`
    justify-content: center;
`
// Pass in mobileColumn to stack contents on mobile,
// centerVertically to center the row vertically
// centerMobile to center the row horizontally for mobile/tablet
// rowReverse to flip the direction on laptop/desktop
export const FlexRow = styled.div`
    display: flex;
    width: ${({ width }) => (width ? width : '100%')};
    ${({ centerVertically }) => centerVertically && 'align-items: center;'};
    ${({ mobileColumn }) => mobileColumn && 'flex-direction: column;'};
    ${({ centerMobile }) => centerMobile && 'justify-content: center;'};
    // border: 1px solid green;

    @media (min-width: 900px) {
        flex-direction: ${({ rowReverse }) =>
            rowReverse ? 'row-reverse' : 'row'};
        justify-content: flex-start;
    }
`
export const FlexRowSE = styled(FlexRow)`
    justify-content: ${({ centerMobile }) =>
        centerMobile ? 'center' : 'space-evenly'};
    @media (min-width: 900px) {
        justify-content: space-evenly;
    }
`
export const FlexRowSA = styled(FlexRow)`
    justify-content: ${({ centerMobile }) =>
        centerMobile ? 'center' : 'space-around'};
    @media (min-width: 900px) {
        justify-content: space-around;
    }
`
export const FlexRowSB = styled(FlexRow)`
    justify-content: ${({ centerMobile }) =>
        centerMobile ? 'center' : 'space-between'};
    @media (min-width: 900px) {
        justify-content: space-between;
    }
`
export const FlexRowC = styled(FlexRow)`
    justify-content: center;
`
export const FlexRowE = styled(FlexRow)`
    justify-content: ${({ centerMobile }) =>
        centerMobile ? 'center' : 'flex-end'};
    @media (min-width: 900px) {
        justify-content: flex-end;
    }
`
// <hr /> has a bug when nesting in a flex container
// Use this instead
export const Separator = styled.hr`
    width: 100%;
`

const StyledBulletPointDiv = styled.div`
    padding: 0 8px;
    color: ${primaryMainColor};
`
export const StyledBulletPoint = () => (
    <StyledBulletPointDiv>&#8226;</StyledBulletPointDiv>
)

export const StyledGroup = styled(FormGroup)`
    display: flex;
    flex-direction: column;
    ${({ width }) => width && `width: ${width};`};
    margin: 0 auto 7px auto;
    padding: 0 10px;
`

export const NameInput = styled(FormControl)`
    border-radius: 5px !important;
    border-style: solid !important;
    background-color: ${backgroundColor1} !important;
    margin: 0 5px;
    width: 80%;
    height: 35px;
    border: 1px solid ${grayedOutMainColor};

    @media (min-width: 900px) {
        width: 27%;
    }
`

export const StyledSearch = styled(FormControl)`
    width: 80%;
    margin-top: 10px;

    @media (min-width: 900px) {
        margin: 0 5px;
        width: 27%;
        text-align: left !important;
        text-align-last: left !important;
        -moz-text-align-last: left !important;
        height: 35px;
        border-radius: 5px;
        border: 1px solid ${grayedOutMainColor};
    }
`

export const StyledRow = styled.div`
    display: flex;
    flex-direction: column;

    @media (min-width: 900px) {
        flex-direction: row;
        margin: 0;
        padding: 0;
    }
    ${({ evenly }) => evenly && 'justify-content: flex-start;'};
`

export const Error = styled.p`
    padding: 0;
    padding-top: 5px;
    color: ${errorColor};
    font-style: italic;
    line-height: 1;
    text-align: center;
    margin: auto;
    font-size: 18px;
    font-weight: 500;
    ${({ width }) => width && `width: ${width};`};

    @media (min-width: 900px) {
        margin: 0;
        height: 16px;
        font-size: 14px;
        text-align: left;
        font-weight: 500;
    }
`

export const StyledCard = styled.div`
    margin: 0 10px 10px 10px;
    border-bottom: 1px solid lightgrey;
    padding: 5px;
    ${({ width }) => width && 'width: 100%;'};
    ${({ minHeight }) => minHeight && `min-height: ${minHeight};`};
    ${({ borderRadius }) => borderRadius && 'border-radius: 5px;'};
    ${({ spaced }) =>
        spaced &&
        `
      padding: 20px 15px;
      border: none;
    `};
`

export const StyledCol = styled(Col)`
    margin: 0;
    padding: 20px;

    @media (min-width: 900px) {
        padding: 0;
    }
`

// TODO: Remove once unusedFiles is purged
// export const TableTitle = styled.h6`
//     font-weight: 600;
//     ${({ view }) =>
//         view !== 'mobile'
//             ? 'font-size: 18px;'
//             : `
//     margin: 5px;
//     padding-top: 10px;
//     font-size: 16px;
//     `}
// `

export const StyledHeader = styled.h4`
    margin: 5px;
    font-size: 28px;
    font-weight: bold;

    @media (min-width: 900px) {
        margin: 20px 0 0 0;
    }
`

export const VisibleSubHeader = styled.h6`
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
`

export const SubHeader = styled.h6`
    margin-top: 20px;

    @media (min-width: 900px) {
        display: none;
    }
`

export const StyledDiv = styled.div`
    text-align: center;
    background-color: white;
    @media (min-width: 900px) {
        width: 100%;
        text-align: left;
        padding: 20px;
    }
`

export const StyledLink = styled(Link)`
    cursor: pointer;
    margin: auto;
    background-color: ${buttonBackgroundColor};
    width: fit-content;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-decoration: none;
    font-weight: 500;
    &:hover {
        color: white;
        text-decoration: none;
        background-color: ${buttonBackgroundHover};
    }
`

export const StyledLinkDisabled = styled.section`
    cursor: not-allowed;
    margin: auto;
    background-color: ${buttonBackgroundColor};
    opacity: 0.7;
    width: fit-content;
    color: white;
    padding: 10px;
    border-radius: 5px;
    text-align: center;
    font-weight: 500;
`

export const Loading = styled.h3`
    text-align: center;
    line-height: 4;
`

export const StyledDevice = styled.div`
    display: flex;
    flex-direction: column;
    width: fit-content;
    padding: 10px 20px;
`

export const StyledSensorLogo = styled.img`
    height: 50px;
    margin-bottom: 20px;
`

export const Placeholder = styled.div`
    margin: 0;
    height: 12px;
`
// TODO: Delete once unusedfiles purged
// export const InputRow = styled.div`
//     display: flex;
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//     flex-direction: column;
//     `
//             : `
//     flex-direction: row;
//     `}
// `

// TODO: Delete once UnusedFiles is purged
// export const CenteredForm = styled(Form)`
//     margin: auto;
// `
// TODO: Delete once unusedFiles is purged
// export const StyledInput = ({
//     error,
//     touched,
//     name,
//     value,
//     placeholder,
//     onChange,
//     onBlur,
//     type = 'text',
//     width,
//     disabled,
//     pattern,
//     minlength,
//     maxlength,
// }) => {
//     return (
//         <StyledGroup width={width}>
//             <Error>{error && touched && error}</Error>
//             <input
//                 type={type}
//                 name={name}
//                 placeholder={placeholder}
//                 onChange={onChange}
//                 onBlur={onBlur}
//                 value={value}
//                 disabled={disabled}
//                 pattern={pattern}
//                 minLength={minlength}
//                 maxLength={maxlength}
//             />
//         </StyledGroup>
//     )
// }

const buttonHeight = '35px'

export const StyledButton = styled(Button)`
    border-radius: 5px;
    margin: 0 5px 10px 5px;
    padding: 5px;
    opacity: 1;
    display: flex;
    justify-content: center;
    color: white;
    min-width: 92px;
    border-color: ${({ color }) =>
        color === 'danger' ? dangerStatusColor : buttonBackgroundColor};
    background-color: ${({ color }) =>
        color === 'danger' ? dangerStatusColor : buttonBackgroundColor};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    &:hover {
        border-color: ${({ color }) =>
            color === 'danger' ? dangerStatusColor : buttonBackgroundHover};
        background-color: ${({ color }) =>
            color === 'danger' ? dangerStatusColor : buttonBackgroundHover};
    }
    ${({ disabled }) =>
        disabled &&
        `background-color: ${buttonBackgroundDisabled} !important; opacity: .5; border-color: ${buttonBackgroundDisabled} !important;`}
    @media (max-width: 900px) {
        width: 50%;
        font-size: 18px;
        font-weight: 900;
    }
    @media (min-width: 900px) {
        height: ${buttonHeight};
    }
`
// TODO: Revisit this, only used in 2 places in Registration.js
export const SecondaryButton = styled(StyledButton)`
    border-color: ${({ color }) =>
        color === 'danger' ? dangerStatusColor : 'transparent'};
    background-color: ${({ color }) =>
        color === 'danger' ? dangerStatusColor : 'transparent'};
    color: ${({ color }) => (color === 'danger' ? 'white' : primaryMainColor)};
`

export const FullPageCard = styled.div`
    background-color: white;
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    ${({ autoMargin }) => autoMargin && 'margin: auto;'};

    @media (min-width: 900px) {
        width: 30%;
        min-width: 400px;
    }
`

export const StyledBlurDiv = styled.div`
    padding: 10px;
    ${({ blur }) => blur && 'filter: blur(2px);'};

    @media (min-width: 900px) {
        padding: 20px;
        background-color: white;
        margin: auto;
        text-align: center;
        position: relative;
    }
`

export const MainFormLabel = styled(FormLabel)`
    color: ${typographyMainColor};
    padding: 0;
    margin-top: 20px;
    width: 100%;
    text-align: center;
    font-size: 18px;
    font-weight: 700;

    @media (min-width: 900px) {
        text-align: left;
        margin-top: 10px;
    }
`
// TODO: consider refactor
// Only used in 1 place, profileUtils.js
export const MainFormSubLabel = styled(FormLabel)`
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 100;

    @media (min-width: 900px) {
        width: ${({ width }) => (width ? width : '80%')};
        text-align: left;
    }
`

export const MainGroup = styled(FormGroup)`
    width: 95%;

    @media (min-width: 900px) {
        width: ${({ width }) => (width ? width : '80%')};
    }
`

export const MainInput = styled.input`
    ${({ view, width, error }) =>
        view === 'mobile'
            ? `
        font-size: 18px;
        font-weight: 700;
        width: 100%;
    `
            : `
            width: ${width ? width : '100%'};
            border: 1px solid ${error ? 'red' : grayedOutMainColor};
            background-color: ${backgroundColor1};
            border-radius: 5px;
            border-style: solid !important;
    `}
`
// TODO: Delete once UnusedFiles is purged
// export const MainFormInput = props => {
//     return (
//         <MainGroup width={props.width}>
//             <MainFormLabel>{props.label}</MainFormLabel>
//             <MainInput
//                 type={props.type}
//                 name={props.name}
//                 placeholder={props.placeholder}
//                 onChange={props.onChange}
//                 onBlur={props.onBlur}
//                 value={props.value}
//                 view={props.view}
//                 min={props.min}
//                 max={props.max}
//                 autocomplete={props.autocomplete}
//                 error={props.error ? 1 : 0}
//             />
//             <Error>{props.touched && props.error}</Error>
//         </MainGroup>
//     )
// }

// TODO: Delete once FormUtils.js is purged
// export const MainFormSelect = ({
//     name,
//     value,
//     placeholder,
//     onChange,
//     onBlur,
//     options,
//     view,
//     label,
//     error,
//     width = '100%',
//     testId,
//     style,
// }) => {
//     return (
//         <MainGroup width={width}>
//             <MainFormLabel>{label}</MainFormLabel>
//             <MainInput
//                 type="select"
//                 name={name}
//                 placeholder={placeholder}
//                 onChange={onChange}
//                 onBlur={onBlur}
//                 value={value}
//                 view={view}
//                 style={style}
//                 as="select"
//                 data-testid={testId || 'mainFormSelectInput'}
//             >
//                 {options.map(option => (
//                     <option value={option.key} key={option.key}>
//                         {option.value}
//                     </option>
//                 ))}
//             </MainInput>
//             <Error>{error && error}</Error>
//         </MainGroup>
//     )
// }

// TODO: Only used in a couple places, consider making
// reusable component
export const MainLinkText = styled(Link)`
    color: ${primaryMainColor};
    text-decoration: none !important;
    font-size: 18px;
    font-weight: 400;

    @media (min-width: 900px) {
        font-size: 16px;
        font-weight: normal;
    }
`

// TODO: Delete once unusedFiles is purged
// export const MainLinkButton = styled(Link)`
//     border-radius: 5px;
//     margin: auto;
//     display: flex;
//     justify-content: center;
//     color: white;
//     min-width: 92px;
//     background-color: ${buttonBackgroundColor};
//     align-items: center;
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//         padding: 5px;
//         opacity: 1;
//         margin-bottom: 30px;
//         width: 50%;
//         font-size: 18px;
//         font-weight: 900;
//     }
//       `
//             : `
//             margin-bottom: 10px;
//             height: ${buttonHeight};
//       `}
//     &:hover {
//         text-decoration: none;
//         color: ${buttonTextColor};
//         cursor: pointer;
//     }
// `

export const errorWithAction = ({ message, action, linkText }) => {
    return (
        <div>
            <p>{message}</p>
            <StyledButton onClick={action}>{linkText}</StyledButton>
        </div>
    )
}

// TODO: Purge once UnusedFiles is purged
// export const MainError = styled.p`
//     color: ${errorColor};
//     margin: 10px 0;
//     padding: 0;
//     font-style: italic;
//     height: 16px;
//     line-height: 1;
//     font-size: 16px;
//     font-weight: 900;
//     text-align: center;
// `

// Pass in alignItems to change alignment for laptop and larger
export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: ${({ width }) => (width ? width : '80%')};
    margin: auto;

    @media (min-width: 900px) {
        align-items: ${({ alignItems }) =>
            alignItems ? alignItems : 'center'};
    }
`
// TODO: Delete once UnusedFiles is purged
// export const ButtonDiv = styled.div`
//     text-align: center;
//     display: flex;
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//     margin-top: 20px;
//       flex-direction: column;
//     `
//             : `
//             margin: auto;
//             margin-top: 20px;
//             width: 400px;
//             justify-content: space-evenly;
//     `}
// `

export const HeaderLabel = styled.p`
    width: 100%;
    margin: 0;
    padding: 0 0 20px 0;
    font-weight: bolder;
    font-size: 20px;
    text-align: center;
`
// TODO: Remove once AgreementsForm.js
// is retired
// export const SubHeaderLabel = styled.p`
//     text-align: center;
//     width: 80%;
//     margin: 8px auto;
// `

// TODO:
// Only used in like one place,
// multiple same name exports, but from other files
// Consider refactoring
export const StyledContainer = styled.div`
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    text-align: center;
`

export const InsideDiv = styled.div`
    background-color: white;
    margin: 20px 0;

    @media (min-width: 900px) {
        padding-bottom: 25px;
    }
`

// TODO:
// Remove once AtAGlance.js and
// OriginalPatientTable.js are deleted
// export const StyledAlerts = styled.div`
//     width: 100%;
//     display: flex;
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//     flex-direction: column;
//     `
//             : `
//     justify-content: flex-start;
//     flex-wrap: wrap;
//     `}
// `
// TODO:
// Remove once AtAGlance.js and
// OriginalPatientTable.js are deleted
// export const AttentionIcon = styled(FaExclamationTriangle)`
//     color: ${dangerStatusColor};
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//     height: 20px;
//     width: 20px;
// `
//             : `
//     height: 20px;
//     width: 20px;
//     padding-right: 5px;
// `}
// `
// TODO:
// Remove once AtAGlance.js and
// OriginalPatientTable.js are deleted
// export const WatchIcon = styled(FaSearch)`
//     color: ${warningStatusColor};
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//     height: 20px;
//     width: 20px;
// `
//             : `
//     height: 20px;
//     width: 20px;
//     padding-right: 5px;
// `}
// `
// TODO:
// Remove once AtAGlance.js and
// OriginalPatientTable.js are deleted
// export const TotalIcon = styled(FaUsers)`
//     color: ${primaryMainColor};
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//     height: 20px;
//     width: 20px;
// `
//             : `
//     height: 20px;
//     width: 20px;
//     padding-right: 5px;
// `}
// `

// TODO:
// Only used in one place, consider
// refactor
export const AlertButton = styled(Button)`
    width: 140px;
    padding: 0;
    font-size: 18px;
    font-weight: 600;
    margin-top: 10px;

    @media (min-width: 900px) {
        margin-top: 0;
    }
`
// TODO: Delete once
// AtAGlance.js and OriginalPatientTable.js
// Are removed from unusedfiles
// export const StyledSect = styled.section`
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//     width: 100%;
//     margin: 5px 0;
// `
//             : `
//     width: fit-content;
//     margin: 0 30px 0 0;
// `}
// `

export const TableWrapper = styled.div`
    width: 100%;
    @media (min-width: 900px) {
        margin: 15px 0px;
    }
`

export const StyledTable = styled(Table)`
    @media (min-width: 900px) {
        text-align: left;
    }
`

export const StyledThead = styled.thead`
    color: ${grayedOutMainColor};
    @media (max-width: 900px) {
        ${({ hideOnMobile }) => hideOnMobile && 'display: none'}
    }
`

export const StyledTbody = styled.tbody`
    font-weight: bolder;
    font-size: 16px;
`

export const StyledTr = styled.tr`
    margin: 0;
    border-bottom: 1px solid #4e4e4e20;
`

export const StyledTh = styled.th`
    font-weight: bolder;
    margin: 0;
    text-transform: uppercase;
    border-top: none;
    font-size: 15px;
    ${({ width }) => width && `width: ${width};`}
    ${({ left }) => left && 'text-align: left;'}
    ${({ noLeftPad }) => noLeftPad && 'padding-left: 0 !important;'}
    @media (max-width: 900px) {
        ${({ hideOnMobile }) => hideOnMobile && 'display: none'}
    }
    @media (min-width: 900px) {
        ${({ replaceText }) =>
            replaceText &&
            `
            text-indent: -9999px;
            line-height: 0; /* Collapse the original line */
            `};

        ::after {
            ${({ replaceText }) =>
                replaceText &&
                `
            content: '${replaceText}';
            text-indent: 0;
            display: block;
            line-height: initial;
            `};
        }
    }
`

export const StyledTd = styled.td`
    font-size: 16px;
    vertical-align: middle;
    ${({ left }) => left && 'text-align: left;'};
    ${({ noLeftPad }) => noLeftPad && 'padding-left: 0 !important;'};

    @media (max-width: 900px) {
        ${({ hideOnMobile }) => hideOnMobile && 'display: none;'};
    }
`

export const StyledP = styled.p`
    font-size: 16px;
    vertical-align: middle;
    ${({ left }) => left && 'text-align: left;'};
    ${({ noLeftPad }) => noLeftPad && 'padding-left: 0 !important;'};

    @media (max-width: 900px) {
        ${({ hideOnMobile }) => hideOnMobile && 'display: none;'};
    }
`

export const AddressOption = styled.div`
    text-align: center;
    color: typographyMainColor;
    border: 1px solid ${transTypography};
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
    width: 90%;
    margin: 20px 5%;

    @media (min-width: 900px) {
        margin: 10px 20px;
        padding: 20px;
    }
`

export const ModalTitle = styled.h2`
    font-size: 30px;
    font-weight: bolder;
    border-bottom: 1px solid lightgrey;
    margin-bottom: 20px;
`

export const ModalTextMain = styled.h2`
    font-weight: 600;
`

export const ModalText = styled.h3`
    margin: 0;
    padding: 0;
`
export const ModalConfirmMessage = styled.p`
    margin: 30px 0;
    font-weight: 800;
    text-align: center;
    font-size: 22px;
`
// TODO: Delete once ProfileSettings.js is deleted
// from UnusedFiles
// export const StyledBox = styled.div`
//     background-color: white;
//     border: 1px solid lightgrey;
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//     padding: 5px 0 0;
//     width: 100%;
//     margin: 0 0 30px 0;
// `
//             : `
//     padding: 25px 30px;
//     margin: 0 10px 10px 10px;
// `}
// `

export const StyledCloseAndDelete = styled(FontAwesomeIcon)`
    cursor: pointer;
    height: 35px !important;
    width: 35px !important;
    padding: 5px !important;
    color: ${errorBackground};

    @media (min-width: 900px) {
        color: grey;
        &:hover {
            color: ${errorBackground};
        }
    }
`

export const ModalCard = styled.div`
    width: 100%;
    padding: 15px;
    border-radius: 5px;
    background-color: white;
    border: 1px solid grey;
    @media (min-width: 900px) {
        width: 50%;
        min-width: 630px;
        padding: 30px;
    }
`

// TODO: Delete once AddSettingsLimit.js is deleted
// from UnusedFiles
// export const CenteredDivLabel = styled.p`
//     text-align: center;
//     ${({ view }) =>
//         view === 'mobile'
//             ? `
//         text-align: center;
//         font-size: 18px;
//         font-weight: 900;
//         margin: 5px 0 3px;
//     `
//             : `
//         font-size: 18px;
//         font-weight: 900;
//         margin: 5px 0 3px;
//     `}
// `

export const StyledLoginWrapper = styled.div`
    ${({ blur }) => blur && 'filter: blur(2px);'}
    padding: 20px;
    border: 1px solid ${grayedOutMainColor};
    border-radius: 5px;
    text-align: center;
    min-width: 360px;
    width: 95vw;
    margin: auto;
    @media (min-width: 900px) {
        width: ${({ address }) => (address ? '60vw' : '30vw')};
        ${({ address }) => address && 'min-width: 700px;'}
        ${({ width }) => width && `width: ${width};`}
    }
`

export const StyledHr = styled.hr`
    width: 80%;
    color: ${grayedOutMainColor};
    background-color: ${grayedOutMainColor};
    height: 0;
    margin: 30px auto 20px auto;
`

export const StyledToastContainer = styled(ToastContainer)`
    // width: 99vw;
    cursor: pointer;
    z-index: 100;
    // position: fixed;
    padding: 0;
    .Toastify__toast {
        font-size: 16px;
        font-weight: 400;
        border-radius: 5px;
        min-height: 0;
    }
    .Toastify__toast--error {
        // border: 2px solid ${errorBackground};
        background: ${alertsPaleBackground};
        color: ${typographyMainColor};
    }
    .Toastify__toast--warning {
        background: ${alertsPaleBackground};
        color: ${typographyMainColor};
    }
    .Toastify__toast--success {
        background: ${alertsPaleBackground};
        color: ${typographyMainColor};
    }
    .Toastify__toast--info {
        background: ${alertsPaleBackground};
        color: ${typographyMainColor};
    }
    .Toastify__toast-body {
        margin: 0 auto;
        padding: 5px;
        text-align: center;
    }
`
