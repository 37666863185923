const normalizeString = oldString => {
    let newString = oldString
        .toLowerCase()
        .split(' ')
        .map(name => name.charAt(0).toUpperCase() + name.substring(1))
        .join(' ')
    newString = newString
        .split('-')
        .map(name => name.charAt(0).toUpperCase() + name.substring(1))
        .join('-')
    return newString
}

export default normalizeString
