import React from 'react'
import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { APP_URL, Auth0URL, Auth0ClientId } from '../../constants'

const Auth0ProviderWithHistory = ({ children }) => {
    const history = useHistory()

    const onRedirectCallback = appState => {
        history.push(appState?.returnTo || window.location.pathname)
    }

    return (
        <Auth0Provider
            domain={Auth0URL}
            clientId={Auth0ClientId}
            redirectUri={APP_URL}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    )
}

export default Auth0ProviderWithHistory
