import React from 'react'
import styled from 'styled-components'

const SubHeading = ({ text, children }) => {
    return <SubHeaderText>{text || children}</SubHeaderText>
}

export default SubHeading

const SubHeaderText = styled.p`
    font-size: 20px;
    font-weight: 700;

    @media (min-width: 900px) {
        font-size: 18px;
    }
`
