import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const sendConfirmEmail = user => {
    const { pendingEmail, id, first, email } = user
    const url =
        process.env.REACT_APP_AWS_ENV === 'local'
            ? 'http://localhost:3000'
            : process.env.REACT_APP_AWS_ENV === 'dev'
            ? 'https://qa.adypta.com'
            : 'https://app.adyptation.com'
    const inviteParams = {
        id,
        email: pendingEmail,
        first,
        url,
        mailKey: process.env.REACT_APP_MAILCHIMP
    }
    const newValues = Object.entries(inviteParams)
        .map(val => `${val[0]}: "${val[1]}"`)
        .join(', ')
    const body = {
        query: print(gql`
        mutation {
            sendConfirmEmail(${newValues})
        }`)
    }
    const options = {
        headers: getDefaultHeaders(email)
    }

    return axiosCall(body, options, 'sendConfirmEmail')
}

export default sendConfirmEmail
