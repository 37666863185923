import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
    VictoryChart,
    VictoryAxis,
    VictoryLine,
    VictoryScatter,
    VictoryLabel,
    VictoryTooltip
} from 'victory'
import FlyOut from './flyOut'
import moment from 'moment'
import {
    graphFill,
    primaryMainColor,
    fontFamily,
    successStatusColor,
    warningStatusColor,
    dangerStatusColor,
    graphLabels
} from '../../constants'
import { TWENTY_EIGHT_DAY_SCORE_DATA } from '../../data/dummy-data'

// Graph settings
const axisStyles = {
    axis: { stroke: graphFill },
    tickLabels: {
        fill: graphLabels,
        fontSize: '7px',
        fontFamily
    },
    grid: {
        stroke: graphFill,
        opacity: 0.5,
        strokeWidth: 1
    }
}
export const tabletSize = 200
export const desktopSize = 100

const GoalProgressGraph = ({ scoreData, goal }) => {
    const [graphSize, setGraphSize] = useState(desktopSize)
    const sortedData = methods.filterAndSortData(scoreData)
    const graphData = methods.createGraphData(sortedData)
    const labelOffset = methods.getGoalOffset(goal, graphSize)

    // On mount only, check if browser is below "tablet" size,
    // if so increase graph height
    useEffect(() => {
        methods.resizeGraph(window.innerWidth, setGraphSize)
    }, [])

    const minValue = 0
    const maxValue = 100

    return (
        <MainContainer data-testid="GoalProgressGraph">
            <VictoryChart
                // minDomain={{ y: 0 }}
                // maxDomain={{ y: 100 }}
                padding={{ top: 10, right: 20, bottom: 25, left: 20 }}
                height={graphSize}
                domainPadding={{ x: 0, y: 5 }}
            >
                <VictoryAxis fixLabelOverlap style={axisStyles} />
                {/* The goal line graph */}
                <VictoryLine
                    style={{
                        data: { stroke: primaryMainColor, strokeWidth: 1 },
                        labels: {
                            fill: primaryMainColor,
                            fontSize: 7,
                            fontFamily
                        }
                    }}
                    labels={[goal]}
                    labelComponent={
                        <VictoryLabel
                            x={12}
                            y={labelOffset - 8} // subtract font size+1
                            renderInPortal={true}
                        />
                    }
                    y={() => goal}
                />
                {/* Gray line graph */}
                <VictoryLine
                    data={graphData}
                    interpolation={'catmullRom'}
                    style={{
                        data: {
                            stroke: graphFill,
                            strokeWidth: 1.2
                        }
                    }}
                />
                {/* Colored points graph  */}
                <VictoryScatter
                    data={graphData}
                    style={{
                        data: {
                            fill: ({ datum }) =>
                                methods.getScoreColorMinMax(
                                    datum.y,
                                    minValue,
                                    maxValue
                                ),
                            stroke: 'white',
                            strokeWidth: 1
                        }
                    }}
                    size={1.6}
                    labels={() => ''}
                    labelComponent={
                        <VictoryTooltip flyoutComponent={<FlyOut />} />
                    }
                />
            </VictoryChart>
        </MainContainer>
    )
}

GoalProgressGraph.defaultProps = {
    scoreData: TWENTY_EIGHT_DAY_SCORE_DATA,
    goal: 80
}

export default React.memo(GoalProgressGraph)

export const methods = {
    // Returns green, red, yellow color based on min-max range
    getScoreColorMinMax: (score, min, max) => {
        const v = parseFloat(score)
        const range = max - min
        const dangerBreakpoint = min + range * 0.33
        const successBreakpoint = max - range * 0.33

        switch (true) {
            case v <= dangerBreakpoint:
                return dangerStatusColor
            case v >= successBreakpoint:
                return successStatusColor
            case isNaN(v):
            default:
                return warningStatusColor
        }
    },

    // filters out 'old' data, then sorts array in chronological order
    filterAndSortData: (scoreArr, numOfDays = 28) => {
        const oldestDate = moment()
            .subtract(numOfDays, 'day')
            .format('M/DD/YYYY')
        const filteredArr = scoreArr.filter(score =>
            moment(score.createdAt).isAfter(oldestDate, 'day')
        )

        return filteredArr
            .filter(item => item.insightType === 'goalScore')
            .sort((a, b) => moment(a.createdAt).diff(moment(b.createdAt)))
    },

    // formats relevant bits for our graphs (date/score values)
    createGraphData: scoreArr => {
        const returnArr = []

        scoreArr.forEach(score =>
            returnArr.push({
                x: moment(score.createdAt).format('M/DD'),
                y: Math.round(score.currentValue)
            })
        )

        return returnArr
    },
    resizeGraph: (width, setGraphSize) => {
        if (width < 900) {
            setGraphSize(tabletSize)
        }
    },
    getGoalOffset: (goal, graphSize) => {
        const c = graphSize - goal / 2

        return c
    }
}
const MainContainer = styled.div`
    width: 100%;
    font-family: ${fontFamily};

    @media (min-width: 900px) {
        margin: 0;
    }
`
