import moment from 'moment'
import gql from 'graphql-tag'
import { print } from 'graphql'
import { SYSTEM_EMAIL } from '../../constants'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

// Takes full user object, messageType for the message that needs to be updated,
//  a bool to represent whether the message should be displayed now, and the optional messageId
// Note: If the messageId is provided, the messageType is not needed
const updateMessage = (user, messageType, displayMessage, messageId) => {
    const userEmail = messageId
        ? SYSTEM_EMAIL
        : user.email ||
          JSON.parse(localStorage.getItem('state')).auth.user.email

    // Creates new date 30 days in future to recheck for updateMedications message
    const thirtyDaysOut = moment().add(30, 'days').format('MM/DD/yyyy')

    // Creates object to send to API to update messages
    const updatedMessage = {
        userId: user.id,
        id:
            messageId ||
            user.messages.find(
                thisMessage => thisMessage.messageType === messageType
            ).id,
        nextDisplayDate: thirtyDaysOut,
        nextCheckDate: thirtyDaysOut
    }

    const newValues =
        Object.entries(updatedMessage)
            .map(val => `${val[0]}: "${val[1]}"`)
            .join(', ') + `displayMessage: ${displayMessage}`

    const body = {
        query: print(gql`
        mutation {
            updateMessage(${newValues}) {
                id
                messages {
                    id
                    displayMessage
                    message
                    linkUrl
                    messageType
                }
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'updateMessage')
        .then(result => result.messages)
        .catch(error => {
            throw error
        })
}

export default updateMessage
