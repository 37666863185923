import {
    primaryMainColor,
    successStatusColor,
    grayedOutMainColor,
    warningStatusColor,
    dangerStatusColor
} from '../constants'

// The following 3 arrays must have the same number of elements.
// Order is important to calculate colors in our 5 point Likert scale.
const colorScale = [
    primaryMainColor,
    successStatusColor,
    grayedOutMainColor,
    warningStatusColor,
    dangerStatusColor
]
const directionScale = ['up', 'flatup', 'flat', 'flatdown', 'down']

const getScales = bioType => {
    if (bioType.localeCompare('pain') === 0) {
        return {
            color: colorScale.slice(),
            direction: directionScale.slice().reverse()
        }
    }

    return {
        color: colorScale.slice().reverse(),
        direction: directionScale.slice()
    }
}

export const getInsightColor = (value, bioType) => {
    const scales = getScales(bioType)
    const x = Math.floor(value - 1)
    const color = scales.color[x]

    return color
}

const getInsightsArrowColor = (direction, bioType) => {
    const scales = getScales(bioType)
    const index = scales.direction.indexOf(direction)

    return scales.color[index]
}

export const getInsightArrow = (direction, bioType) => {
    let color = 'purple'
    if (directionScale.includes(direction)) {
        color = getInsightsArrowColor(direction, bioType)
    }

    return color
}
