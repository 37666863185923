import React, { createContext, useContext, useReducer } from 'react'
import {
    getSleepFromBioMarkers,
    getStepsFromBioMarkers
} from '../logic/bioMarkerLogic'
import { filterStaff, filterUniques } from '../utils/helperFunctions'

// We want to limit this state to the minimum possible items
// to keep our memory footprint small and force us to think
// through _why_ the particular item needs to be global in context.
export const appState = {
    release: 'release', // alpha, beta, release
    theme: 'default', // default, dark, light
    viewType: 'monitor', // monitor, tablet, mobile
    loading: false,
    salt: undefined,
    token: undefined,
    auth: {
        isAuthenticated: false,
        user: {
            role: []
        },
        userType: 'unknown',
        organization: {}
    }
}

const reducer = (current, action) => {
    const { type, value } = action
    const { auth } = current
    const { organization, user } = auth
    let newState
    switch (type) {
        case 'SET_RELEASE':
            newState = {
                ...current,
                release: value
            }
            break
        case 'SET_VIEW_TYPE':
            newState = {
                ...current,
                viewType: value
            }
            break
        case 'SET_THEME':
            newState = {
                ...current,
                theme: value
            }
            break
        case 'SET_ISAUTH':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    isAuthenticated: value
                }
            }
            break
        case 'SET_LOADING':
            newState = {
                ...current,
                loading: value
            }
            break
        case 'SET_USERTYPE':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    userType: value
                }
            }
            break
        case 'SET_USER_ORG':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    user: {
                        id: value.id || user.id || null,
                        cognitoId: value.cognitoId || user.cognitoId || null,
                        email: value.email || user.email || null,
                        first: value.first || user.first || null,
                        last: value.last || user.last || null,
                        name: value.first
                            ? `${value.first} ${value.last}`
                            : user.name || null,
                        patientId: value.id || user.id || null,
                        phone: value.phone || user.phone || null,
                        devices: value.devices || user.devices || null,
                        role:
                            value?.role && user?.role
                                ? filterUniques([...user.role, ...value.role])
                                : user?.role || [],
                        agreementInfo:
                            value.agreementInfo || user.agreementInfo || null,
                        acceptanceDate:
                            value.acceptanceDate || user.acceptanceDate || null,
                        currentStatus:
                            value.currentStatus || user.currentStatus || null,
                        invitation: value.invitation || user.invitation || null,
                        invitationDate:
                            value.invitationDate || user.invitationDate || null,
                        textMessageStatus:
                            value.textMessageStatus ||
                            user.textMessageStatus ||
                            null,
                        timeZone: value.timeZone || user.timeZone || null,
                        goalDefinitions:
                            value.goalDefinitions ||
                            user.goalDefinitions ||
                            null,
                        goalsJournals:
                            value.goalsJournals || user.goalsJournals || null,
                        insightsSnapshot:
                            value.insightsSnapshot ||
                            user.insightsSnapshot ||
                            null,
                        insightsTrend:
                            value.insightsTrend || user.insightsTrend || null,
                        steps: value.bioMarkers
                            ? getStepsFromBioMarkers(value.bioMarkers)
                            : user.steps || null,
                        sleep: value.bioMarkers
                            ? getSleepFromBioMarkers(value.bioMarkers)
                            : user.sleep || null,
                        medications:
                            value.medications || user.medications || null,
                        messages: value.messages || user.messages || null,
                        pendingEmail:
                            value.pendingEmail || user.pendingEmail || null,
                        previousEmail:
                            value.previousEmail || user.previousEmail || null,
                        medicationTracking:
                            value.medicationTracking ||
                            user.medicationTracking ||
                            'enabled'
                    },
                    organization: {
                        id: value.organization?.id
                            ? value.organization.id
                            : null,
                        name: value.organization?.name
                            ? value.organization.name
                            : null,
                        entity: value.organization?.entity
                            ? value.organization.entity
                            : null,
                        patients: value.organization?.users
                            ? value.organization.users.filter(user =>
                                  user.role.includes('patient')
                              )
                            : [],
                        users: value.organization?.users
                            ? filterStaff(value.organization.users)
                            : [],
                        invitations: value.organization?.invitations
                            ? value.organization.invitations
                            : []
                    }
                }
            }
            break
        case 'CREATE_USER':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    user: {
                        id: value.id,
                        cognitoId: value.cognitoId || null,
                        email: value.email || null,
                        first: value.first || null,
                        last: value.last || null,
                        name: `${value.first} ${value.last}` || null,
                        phone: value.phone || null,
                        devices: value.devices || null,
                        role:
                            value?.role && user?.role
                                ? filterUniques([...user.role, ...value.role])
                                : user?.role || [],
                        agreementInfo: value.agreementInfo || null,
                        acceptanceDate: value.acceptanceDate || null,
                        organizationId: value.organizationId || null,
                        currentStatus: value.currentStatus || null,
                        invitation: value.invitation || null,
                        invitationDate: value.invitationDate || null,
                        timeZone: value.timeZone || null,
                        goalDefinitions: value.goalDefinitions || null,
                        goalsJournals: value.goalsJournals || null,
                        insightsSnapshot: value.insightsSnapshot || null,
                        insightsTrend: value.insightsTrend || null,
                        steps: value.bioMarkers
                            ? getStepsFromBioMarkers(value.bioMarkers)
                            : null,
                        sleep: value.bioMarkers
                            ? getSleepFromBioMarkers(value.bioMarkers)
                            : null,
                        medications: value.medications || [],
                        messages: value.messages || [],
                        pendingEmail: value.pendingEmail || null,
                        previousEmail: value.previousEmail || null,
                        medicationTracking:
                            value.medicationTracking || 'enabled'
                    },
                    organization: {
                        ...organization,
                        ...value.organization
                    }
                }
            }
            break
        case 'UPDATE_USER':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    user: {
                        id: value.id || user.id || undefined,
                        cognitoId:
                            value.cognitoId || user.cognitoId || undefined,
                        email: value.email || user.email,
                        first: value.first || user.first,
                        last: value.last || user.last,
                        name: value.first
                            ? `${value.first} ${value.last}`
                            : user.name,
                        patientId: value.id || user.id,
                        phone: value.phone || user.phone,
                        devices: value.devices || user.devices,
                        role: value?.role ? value.role : user?.role || [],
                        agreementInfo:
                            value.agreementInfo || user.agreementInfo,
                        acceptanceDate:
                            value.acceptanceDate || user.acceptanceDate,
                        currentStatus:
                            value.currentStatus || user.currentStatus,
                        invitation: value.invitation || user.invitation,
                        invitationDate:
                            value.invitationDate || user.invitationDate,
                        textMessageStatus:
                            value.textMessageStatus || user.textMessageStatus,
                        timeZone: value.timeZone || user.timeZone,
                        goalDefinitions:
                            value.goalDefinitions || user.goalDefinitions,
                        goalsJournals:
                            value.goalsJournals || user.goalsJournals,
                        insightsSnapshot:
                            value.insightsSnapshot || user.insightsSnapshot,
                        insightsTrend:
                            value.insightsTrend || user.insightsTrend,
                        steps: value.bioMarkers
                            ? getStepsFromBioMarkers(value.bioMarkers)
                            : user.steps,
                        sleep: value.bioMarkers
                            ? getSleepFromBioMarkers(value.bioMarkers)
                            : user.sleep,
                        medications: value.medications || user.medications,
                        messages: value.messages || user.messages,
                        pendingEmail:
                            value.pendingEmail || user.pendingEmail || null,
                        previousEmail:
                            value.previousEmail || user.previousEmail || null,
                        medicationTracking:
                            value.medicationTracking ||
                            user.medicationTracking ||
                            'enabled'
                    },
                    organization: { ...organization }
                }
            }
            break
        case 'EMAIL_CHANGED':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    user: {
                        ...user,
                        email: value,
                        pendingEmail: null
                    }
                }
            }
            break
        case 'UPDATE_USERS':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    organization: {
                        ...organization,
                        users: value.users ? value.users : null
                    }
                }
            }
            break
        case 'UPDATE_PATIENTS':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    organization: {
                        ...organization,
                        patients: value.patients ? value.patients : null
                    }
                }
            }
            break
        case 'UPDATE_INVITATIONS':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    organization: {
                        ...organization,
                        invitations: value.invitations ? value.invitations : []
                    }
                }
            }
            break
        case 'UPDATE_ORGANIZATION':
            newState = {
                ...current,
                auth: {
                    ...auth,
                    user: { ...user },
                    organization: value
                }
            }
            break
        case 'SET_SUPERUSER':
            newState = {
                ...current,
                release: 'alpha',
                auth: {
                    ...auth,
                    user: {
                        ...user,
                        role: filterUniques([...user.role, 'superuser'])
                    }
                }
            }
            break
        case 'SET_SALT':
            newState = {
                ...current,
                salt: value
            }
            break
        case 'SET_TOKEN':
            newState = {
                ...current,
                token: value
            }
            break
        case 'RESET':
            newState = appState
            break
        default:
            newState = current
    }

    localStorage.setItem('state', JSON.stringify(newState))
    return newState
}

// If at all possible gather our state from local storage in the browser.
// If there is nothing there, we go with the context defaults
const existingState = JSON.parse(localStorage.getItem('state'))
const currentState = {
    ...appState,
    ...existingState,
    // added the following to reset role, make it not persist through app loads
    // for superuser security
    auth: {
        ...existingState?.auth,
        user: {
            ...existingState?.auth?.user,
            role: existingState?.auth?.user?.role || []
        }
    }
}

const AppContext = createContext(currentState)

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, currentState)

    return (
        <AppContext.Provider value={{ state, dispatch }}>
            {children}
        </AppContext.Provider>
    )
}

export const useAppContext = () => useContext(AppContext)
