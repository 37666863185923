import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const sendInvitationEmail = values => {
    const userEmail = JSON.parse(localStorage.getItem('state'))?.auth?.user
        ?.email
        ? JSON.parse(localStorage.getItem('state')).auth.user.email
        : 'internalsystem@adyptation.com'
    const newValues = Object.entries(values)
        .map(val => `${val[0]}: "${val[1]}"`)
        .join(', ')
    const body = {
        query: print(gql`
        mutation {
            sendInvitation(${newValues})
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'sendInvitation')
}

export default sendInvitationEmail
