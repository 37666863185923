import gql from 'graphql-tag'
import { print } from 'graphql'
import { getDefaultHeaders, axiosCall } from '../helpers/defaults'

const createUser = (values, role, email) => {
    const userEmail =
        email || JSON.parse(localStorage.getItem('state')).auth.user.email
    const roles = role.map(r => `"${r}"`).join(', ')
    const newValues =
        Object.entries(values)
            .map(val => `${val[0]}: "${val[1]}"`)
            .join(', ') + `, role: [${roles}]`
    const body = {
        query: print(gql`
        mutation {
            createUser(${newValues}) {
                id
                first
                last
                contactType
                email
                phone
                role
                currentStatus
                currentStatusDate
                createdAt
                updatedAt
                textMessageStatus
                goalDefinitions {
                    id
                    name
                    goalType
                    direction
                    goalScale
                    deliveryMethod
                    deliveryTime
                    targetDate
                    targetScore
                    createdAt
                    updatedAt
                }
                contactType
                organizationId
                invitation
                invitationDate
                medicationTracking
            }
        }`)
    }
    const options = {
        headers: getDefaultHeaders(userEmail)
    }

    return axiosCall(body, options, 'createUser')
}

export default createUser
